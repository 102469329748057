import type { OrderColDefType } from "../../types";
import OrderRowSelectionCol from "./OrderRowSelectionCol";

export const orderColumns: readonly OrderColDefType[] = [
  {
    index: 1,
    id: "checkbox",
    label: "",
    component: <OrderRowSelectionCol key="checkbox" />,
  },
  {
    index: 2,
    id: "actionBtn",
    label: "Actions",
  },
  {
    index: 3,
    id: "status",
    label: "Status",
  },
  {
    index: 4,
    id: "isFinanced",
    label: "Financing",
  },
  {
    index: 5,
    id: "createdAt",
    label: "Created date",
  },
  {
    index: 6,
    id: "id",
    label: "Order id",
  },
  {
    index: 7,
    id: "referenceId",
    label: "Reference id",
  },
  {
    index: 8,
    id: "buyerName",
    label: "Buyer name",
  },
  {
    index: 9,
    id: "vendorName",
    label: "Vendor name",
  },
  {
    index: 10,
    id: "total",
    label: "Total amount",
  },
];

export function isOrderCheckboxColType(state: any): state is OrderColDefType {
  return state.id === "checkbox" && state.component !== undefined;
}
