import safeFetch, { ErrorResType } from "@pd/api/utils/safeFetch";
import { endpoints } from "@pd/config/envSetup";
import { CreateTeamMemberType } from "@pd/layouts/MktplaceDashboard/pages/Settings/components/ManageTeam/types";
import { TeamMemberDbType } from "@pd/redux/types/dbTypes";

export type AllTeamMembersResType = { data: TeamMemberDbType[] } | ErrorResType;
export const fetchAllTeamMembers = async (): Promise<AllTeamMembersResType> => {
  const response = await safeFetch(
    `${endpoints.stitchBackend}/dashboard/users`,
    {
      method: "GET",
      credentials: "include",
    },
  );
  return response;
};

export type CreateTeamMemberResType =
  | { data: TeamMemberDbType; inviteSent: boolean }
  | ErrorResType;
export const fetchCreateTeamMember = async (
  teamInfo: CreateTeamMemberType,
): Promise<CreateTeamMemberResType> => {
  const response = await safeFetch(
    `${endpoints.stitchBackend}/dashboard/users`,
    {
      method: "POST",
      credentials: "include",
      body: JSON.stringify({
        first_name: teamInfo.firstName,
        last_name: teamInfo.lastName,
        email: teamInfo.email,
        role: teamInfo.role,
        phone: teamInfo.phone,
        preferred_timezone: teamInfo.timezone,
      }),
    },
  );
  return response;
};

export type EditTeamMemberResType = { data: TeamMemberDbType } | ErrorResType;
export const fetchEditTeamMember = async (
  teamInfo: CreateTeamMemberType,
  id: string,
): Promise<EditTeamMemberResType> => {
  const response = await safeFetch(
    `${endpoints.stitchBackend}/dashboard/users/${id}`,
    {
      method: "PUT",
      credentials: "include",
      body: JSON.stringify({
        first_name: teamInfo.firstName,
        last_name: teamInfo.lastName,
        email: teamInfo.email,
        role: teamInfo.role,
        phone: teamInfo.phone,
      }),
    },
  );
  return response;
};

export type DeleteTeamMemberResType =
  | { data: { id: string; active: boolean } }
  | ErrorResType;
export const fetchDeleteTeamMember = async (
  id: string,
): Promise<EditTeamMemberResType> => {
  const response = await safeFetch(
    `${endpoints.stitchBackend}/dashboard/users/${id}`,
    {
      method: "DELETE",
      credentials: "include",
    },
  );
  return response;
};
