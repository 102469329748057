import type { VendorsColDefType } from "../types";

export const vendorColumns: readonly VendorsColDefType[] = [
  {
    index: 1,
    id: "vendor",
    label: "Vendor",
  },
  {
    index: 2,
    id: "id",
    label: "id",
  },
  {
    index: 3,
    id: "phone",
    label: "Phone",
  },
  {
    index: 4,
    id: "email",
    label: "Email",
  },
  {
    index: 5,
    id: "referenceId",
    label: "Reference id",
  },
  {
    index: 6,
    id: "account",
    label: "Account",
  },
  {
    index: 7,
    id: "kycStatus",
    label: "KYC",
  },
];
