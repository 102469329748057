import { Box, ButtonBase, Stack, Typography } from "@mui/material";
import { useState } from "react";
import BlackDot from "@pd/components/BlackDot";
import {
  EyeOutlined,
  EyeInvisibleOutlined,
  CopyOutlined,
} from "@ant-design/icons";

type Props = {
  label: string;
  value: string;
  useVisibilityToggle: boolean;
  showCopyButton: boolean;
  onCopyClicked: (value: string) => void;
};
export default function LabeledKeyRow(props: Props) {
  const [valueIsVisible, setValueIsVisible] = useState(false);

  const renderHiddenText = () => (
    <Stack
      direction="row"
      gap={2}
      alignItems="center"
      style={{ width: "245px" }}
      justifyContent="space-between"
    >
      <Box>
        {Array.from(props.value).map((key, i) => (
          <BlackDot
            key={
              `${key}-${i}` /* eslint-disable-line react/no-array-index-key */
            }
          />
        ))}
      </Box>
    </Stack>
  );

  const renderShowButton = () => (
    <ButtonBase
      onClick={(e) => {
        e.stopPropagation();
        setValueIsVisible(true);
      }}
      sx={{
        padding: "5px",
        "&:hover": {
          cursor: "pointer",
          scale: "1.1",
          transition: "all 0.3s ease",
        },
      }}
    >
      <EyeOutlined style={{ fontSize: "24px" }} />
    </ButtonBase>
  );

  const renderShowText = () => (
    <Typography
      variant="subtitle1"
      sx={{
        fontSize: "15px",
        minWidth: "245px",
        fontFamily:
          "Consolas,Monaco,Lucida Console,Liberation Mono," +
          "DejaVu Sans Mono,Bitstream Vera Sans Mono,Courier New",
      }}
    >
      {props.value}
    </Typography>
  );

  const renderHideButton = () => (
    <ButtonBase
      onClick={(e) => {
        e.stopPropagation();
        setValueIsVisible(false);
      }}
      sx={{
        padding: "5px",
      }}
    >
      <EyeInvisibleOutlined style={{ fontSize: "24px" }} />
    </ButtonBase>
  );

  const renderCopyButton = () => (
    <ButtonBase
      onClick={(e) => {
        e.stopPropagation();
        props.onCopyClicked(props.value);
      }}
      sx={{
        padding: "5px",
        "&:hover": {
          cursor: "pointer",
          scale: "1.1",
          transition: "all 0.3s ease",
        },
      }}
    >
      <CopyOutlined style={{ fontSize: "24px" }} />
    </ButtonBase>
  );

  return (
    <Stack direction="row" justifyContent="space-between">
      <Typography
        lineHeight="66px"
        width="216px"
        style={{
          marginRight: "20px",
        }}
        sx={{ flexGrow: 1 }}
      >
        {props.label}
      </Typography>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          flexGrow: 2,
          outline: "1px solid #767676",
          borderRadius: "2px",
          padding: "15px",
        }}
      >
        {valueIsVisible || !props.useVisibilityToggle
          ? renderShowText()
          : renderHiddenText()}
        <Stack direction="row" alignItems="center">
          {props.useVisibilityToggle &&
            (valueIsVisible ? renderHideButton() : renderShowButton())}
          {props.showCopyButton && renderCopyButton()}
        </Stack>
      </Stack>
    </Stack>
  );
}
