import { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Box } from "@mui/material";

import DashboardHeader from "@pd/components/DashboardHeader";
import { useAppDispatch, useAppSelector } from "@pd/redux/store";
import AuthGuard from "@pd/components/AuthGuard";
import {
  selectLoggedIn,
  selectShowRefreshSession,
} from "@pd/redux/selectors/auth";
import RefreshSessionModal from "./modals/RefreshSession";
import { selectNextRoute } from "./redux/selectors/routes";
import da from "./redux/actions";

export default function MktplaceDashboard() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const isLoggedIn = useAppSelector(selectLoggedIn);
  const showRefreshSession = useAppSelector(selectShowRefreshSession);
  const nextRoute = useAppSelector(selectNextRoute);

  useEffect(() => {
    if (location.pathname === "/dashboard") {
      navigate("/dashboard/orders", { replace: true });
    }
    if (isLoggedIn) {
      // TODO: Fetch any hyration data here
    }
  }, []);

  useEffect(() => {
    if (nextRoute) {
      navigate(nextRoute, { replace: true });
      dispatch(da.routes.clearNextRoute());
    }
  }, [nextRoute]);

  return (
    <AuthGuard>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100vw",
          minHeight: "100vh",
          height: "100%",
          backgroundColor: "#FFF",
        }}
      >
        <DashboardHeader />
        <Outlet />
        {showRefreshSession ? <RefreshSessionModal /> : null}
      </Box>
    </AuthGuard>
  );
}
