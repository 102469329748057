import { createSelector } from "@reduxjs/toolkit";
import { AppStateType } from "@pd/redux/store/reducers";
import { isDashboardState } from "@pd/layouts/MktplaceDashboard/types";
import { ErrorPayload } from "@pd/api/utils/safeFetch";

export const selectCsvUploadLoading = createSelector(
  (state: AppStateType) => state,
  (state: AppStateType): boolean => {
    if (isDashboardState(state)) {
      return state.dashboard.orders.csvUpload.fetching;
    }
    return false;
  },
);

export const selectCsvUploadError = createSelector(
  (state: AppStateType) => state,
  (state: AppStateType): ErrorPayload => {
    if (isDashboardState(state)) {
      return state.dashboard.orders.csvUpload.error;
    }
    return { message: "", status: 0 };
  },
);

export const selectCsvUploadSuccess = createSelector(
  (state: AppStateType) => state,
  (state: AppStateType): boolean => {
    if (isDashboardState(state)) {
      return state.dashboard.orders.csvUpload.success;
    }
    return false;
  },
);

export const selectUploadedFilename = createSelector(
  (state: AppStateType) => state,
  (state: AppStateType) => {
    if (isDashboardState(state)) {
      return state.dashboard.orders.csvUpload.data.filename;
    }
    return [];
  },
);

export const selectUploadReport = createSelector(
  (state: AppStateType) => state,
  (state: AppStateType) => {
    if (isDashboardState(state)) {
      return state.dashboard.orders.csvUpload.data.report;
    }
    return null;
  },
);

export const selectFilename = createSelector(
  (state: AppStateType) => state,
  (state: AppStateType) => {
    if (isDashboardState(state)) {
      return state.dashboard.orders.csvUpload.data.filename;
    }
    return null;
  },
);
