/* eslint-disable */

import { createContext, useContext, useState, useEffect } from "react";
import { Snackbar, Alert, useTheme } from "@mui/material";

import { useAppSelector, useAppDispatch } from "@pd/redux/store";
import {
  selectAllOrdersError,
  selectSelectedOrders,
  selectAllOrdersFetching,
  selectAllOrdersSuccess,
} from "@pd/layouts/MktplaceDashboard/redux/selectors/orders/allOrders";
import { SelectionApiErrorType } from "@pd/layouts/MktplaceDashboard/pages/Orders/types";
import da from "@pd/layouts/MktplaceDashboard/redux/actions";

import SelectionErrors from "../components/modals/SelectionErrors";
import { MANUAL_ERROR_CODE } from "@pd/utils/constants";

type SelectionActionTypes = "delete" | "confirm" | "void" | "";

const TableSelectionContext = createContext({
  onSelectAction: (_: SelectionActionTypes) => {},
  onResetSelection: () => {},
});

type Props = {
  children: React.ReactNode;
};
export const TableSelectionProvider = ({ children }: Props) => {
  const dispatch = useAppDispatch();

  const apiFetching = useAppSelector(selectAllOrdersFetching);
  const apiSuccess = useAppSelector(selectAllOrdersSuccess);
  const apiError = useAppSelector(selectAllOrdersError);

  const [uiFetching, setUiFetching] = useState(false);
  const [uiError, setUiError] = useState<SelectionApiErrorType | null>(null);
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const selectedRows = useAppSelector(selectSelectedOrders);
  const [selectedActionType, setSelectionActionType] =
    useState<SelectionActionTypes>("");

  useEffect(() => {
    if (apiError.status) {
      setUiFetching(false);
      if (apiError.status === MANUAL_ERROR_CODE) {
        setShowErrorModal(true);
        setUiError(JSON.parse(apiError.message));
        dispatch(da.orders.all.apiError({ message: "", status: 0 }));
      }
    }
    if (apiSuccess && uiFetching) {
      setUiFetching(false);
      setShowSuccessMsg(true);
      if (!uiError) {
        setSelectionActionType("");
      }
    }
  }, [apiError, apiFetching, uiFetching, apiSuccess]);

  const getSuccessMsg = (action: string, selectedNum: number) => {
    let msg = "";
    switch (action) {
      case "delete":
        msg = `${selectedNum} Orders deleted successfully!`;
        break;
      case "confirm":
        msg = `${selectedNum} Orders confirmed successfully!`;
        break;
      case "void":
        msg = `${selectedNum} Orders voided successfully!`;
        break;
      default:
        msg = "Orders processed successfully!";
    }
    return msg;
  };

  const handleReset = () => {
    setSelectionActionType("");
    dispatch(da.orders.all.setSelectedOrders([]));
    setUiError(null);
    setShowErrorModal(false);
    setShowSuccessMsg(false);
    setUiFetching(false);
  };

  const handleActionSelection = (action: SelectionActionTypes) => {
    setUiFetching(true);
    setSelectionActionType(action);
    setSuccessMsg(getSuccessMsg(action, selectedRows.length));
  };

  const handleCloseAlert = () => {
    setShowSuccessMsg(false);
    dispatch(da.orders.all.apiSuccess(false));
  };

  const handleCloseErrorModal = () => {
    handleCloseAlert();
    handleReset();
  };

  return (
    <TableSelectionContext.Provider
      value={{
        onSelectAction: handleActionSelection,
        onResetSelection: () => handleReset(),
      }}
    >
      {children}
      <SelectionErrors
        showModal={showErrorModal}
        onClose={handleCloseErrorModal}
        selectionType={selectedActionType}
        selectionError={uiError}
      />
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={showSuccessMsg}
        autoHideDuration={5000}
        onClose={handleCloseAlert}
        sx={{ mb: 5 }}
      >
        <Alert
          onClose={handleCloseAlert}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {successMsg}
        </Alert>
      </Snackbar>
    </TableSelectionContext.Provider>
  );
};

export const useTableSelection = () => {
  const context = useContext(TableSelectionContext);
  if (!context) {
    throw new Error(
      "useTableSelection must be used within a TableSelectionProvider",
    );
  }
  return context;
};
