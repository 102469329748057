import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers";
import { CalendarOutlined } from "@ant-design/icons";
import { useTheme } from "@mui/material";

const MIN_DATE = new Date("2024-01-01");

type Props = {
  value: Date | null;
  label?: string | null;
  minDate?: Date | null;
  maxDate?: Date;
  disabled?: boolean;
  onChange: (date: Date | null) => void;
  onClear?: () => void;
  style?: object;
};
export default function StitchDatePicker({
  minDate = MIN_DATE,
  maxDate = dayjs().add(90, "day").toDate(),
  disabled = false,
  ...props
}: Props) {
  const theme = useTheme();
  const commonDatePickerStyles = {
    layout: {
      sx: {
        ".MuiPickersCalendarHeader-root": {
          borderBottom: `1px solid ${theme.palette.secondary.lighter}`,
          paddingBottom: "8px",
          "& .MuiPickersCalendarHeader-label": {
            fontFamily: "CircularBold",
          },
        },
        ".MuiDayCalendar-header": {
          borderBottom: `1px solid ${theme.palette.secondary.lighter}`,
          "& .MuiDayCalendar-weekDayLabel": {
            fontSize: "1rem",
            color: theme.palette.secondary.dark,
          },
        },
        ".MuiYearCalendar-root": {
          overflowX: "auto",
          overflowY: "auto",
          "::-webkit-scrollbar": {
            width: "6px",
            height: "7px",
          },
          "::-webkit-scrollbar-track": {
            background: "#F1F1F1",
          },
          "::-webkit-scrollbar-thumb": {
            background: "#333",
          },
          "::-webkit-scrollbar-thumb:hover": {
            background: "#333",
          },
        },
        ".MuiPickersMonth-monthButton.Mui-selected": {
          borderRadius: "5px",
        },
        ".MuiPickersMonth-monthButton.Mui-selected:focus": {
          outline: "none",
        },
        ".MuiPickersYear-yearButton.Mui-selected": {
          borderRadius: "5px",
        },
        ".MuiPickersYear-yearButton.Mui-selected:focus": {
          outline: "none",
        },
        ".MuiPickersDay-root.MuiPickersDay-dayWithMargin": {
          borderRadius: "5px",
        },
        ".MuiPickersDay-root.MuiPickersDay-today": {
          backgroundColor: theme.palette.secondary.light,
          border: "none",
        },
        ".MuiPickersDay-root.Mui-selected": {
          backgroundColor: "black",
          borderRadius: "5px",
        },
      },
    },
    ...(props.onClear
      ? {
          field: {
            clearable: true,
            onClear: () => (props.onClear ? props.onClear() : null),
          },
        }
      : {}),
  };

  return (
    <DatePicker
      label={props.label}
      format="MM/dd/yyyy"
      value={props.value}
      views={["day"]}
      minDate={minDate || MIN_DATE}
      maxDate={maxDate}
      onChange={props.onChange}
      sx={{ ...props.style }}
      slots={{
        openPickerIcon: CalendarOutlined,
      }}
      slotProps={commonDatePickerStyles}
      disabled={disabled}
    />
  );
}
