import type { VendorPayoutHistoryColDefType } from "../../types";

export const vendorPayoutHistoryColumns: readonly VendorPayoutHistoryColDefType[] =
  [
    {
      index: 1,
      id: "date",
      label: "Date",
    },
    {
      index: 2,
      id: "id",
      label: "Payout ID",
    },
    {
      index: 3,
      id: "status",
      label: "Status",
    },
    {
      index: 4,
      id: "method",
      label: "Method",
    },
    {
      index: 5,
      id: "estimatedClearingDate",
      label: "Settlement",
    },
    {
      index: 6,
      id: "payoutSubtotal",
      label: "Payout Subtotal",
    },
    {
      index: 7,
      id: "fees",
      label: "Applied Fees",
    },
    {
      index: 8,
      id: "payoutTotal",
      label: "Payout Total",
    },
  ];
