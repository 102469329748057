import {
  all,
  call,
  put,
  takeLeading,
  takeLatest,
  delay,
  select,
  debounce,
} from "redux-saga/effects";

import type {
  OrderCreateResType,
  OrderDetailResType,
  DeleteOrderResType,
  VoidOrderResType,
} from "@pd/api/dashboard/orders";
import {
  fetchCreateOrder,
  fetchOrderById,
  fetchPreviewOrder,
  fetchUpdateOrderById,
  fetchOrderDeleteById,
  fetchVoidOrderById,
} from "@pd/api/dashboard/orders";
import { CreateOrderFormTypes } from "@pd/layouts/MktplaceDashboard/pages/Orders/components/CreateEditOrder/types";
import { MANUAL_ERROR_CODE } from "@pd/utils/constants";
import {
  MerchantProfileDbType,
  OrderCreatedDbType,
  OrderDbType,
} from "@pd/redux/types/dbTypes";
import { selectProfile } from "@pd/redux/selectors/auth";
import formatDateToTzIso from "@pd/utils/formatDateToTzIso";
import {
  selectApiOrder,
  selectUiOrder,
} from "../../selectors/orders/crudOrder";
import da from "../../actions";
import { selectAllOrders } from "../../selectors/orders/allOrders";

export default function* crudOrderSagas() {
  yield all([
    takeLatest(da.orders.crud.setOrderUiData.toString(), onUpdateOrderTotals),
    debounce(
      500,
      da.orders.crud.updateOrderTotals.toString(),
      onUpdateOrderTotals,
    ),
    takeLeading(da.orders.crud.createOrder.toString(), onCreateOrder),
    takeLatest(da.orders.crud.getApiOrder.toString(), onGetApiOrder),
    takeLatest(da.orders.crud.getPreviewOrderTotals.toString(), onPreviewOrder),
    takeLatest(da.orders.crud.editOrder.toString(), onEditOrder),
    takeLeading(da.orders.crud.deleteOrder.toString(), onConfirmOrderDelete),
    takeLeading(da.orders.crud.getOrderById.toString(), onGetOrderById),
    takeLeading(da.orders.crud.voidOrder.toString(), onVoidOrderById),
  ]);
}

function* onCreateOrder(action: ReturnType<typeof da.orders.crud.createOrder>) {
  try {
    yield all([
      put(da.orders.crud.apiFetching(true)),
      put(da.orders.crud.apiSuccess(false)),
      put(da.orders.crud.apiError({ message: "", status: 0 })),
    ]);
    const profile: MerchantProfileDbType = yield select(selectProfile);
    const billDateISO = formatDatesForOrder(
      action.payload.order.billDate,
      profile.preferredTimezone,
    );
    const serviceDateISO = formatDatesForOrder(
      action.payload.order.serviceDate,
      profile.preferredTimezone,
    );
    const res: OrderCreateResType = yield call(
      fetchCreateOrder,
      {
        ...action.payload.order,
        billDate: billDateISO,
        serviceDate: serviceDateISO,
      },
      action.payload.confirm,
    );
    yield delay(400);
    if ("error" in res) {
      yield all([
        put(da.orders.crud.apiError(res.error)),
        put(da.orders.crud.apiSuccess(false)),
        put(da.orders.crud.apiFetching(false)),
      ]);
    } else {
      yield all([
        put(da.orders.crud.setOrderApiData(res.data)),
        put(da.orders.crud.apiFetching(false)),
        put(da.orders.crud.apiSuccess(true)),
      ]);
    }
  } catch (error: unknown) {
    const errMsg =
      "An error occurred while creating your order. Please try again later.";
    if (error instanceof Error) {
      console.error(error.message);
    }
    yield all([
      put(
        da.orders.crud.apiError({ message: errMsg, status: MANUAL_ERROR_CODE }),
      ),
      put(da.orders.crud.apiFetching(false)),
      put(da.orders.crud.apiSuccess(false)),
    ]);
  }
}

function* onUpdateOrderTotals() {
  const apiOrder: OrderCreatedDbType | null = yield select(selectApiOrder);
  const uiOrder: CreateOrderFormTypes | null = yield select(selectUiOrder);
  const requestUpdate = [
    uiOrder?.billDate,
    uiOrder?.buyerId,
    uiOrder?.vendorId,
    uiOrder?.referenceId,
    uiOrder?.status !== "confirmed",
    uiOrder?.lineItems.length,
    uiOrder?.lineItems.every((item) => item.description && item.amount),
  ].every(Boolean);
  if (requestUpdate && uiOrder) {
    yield put(
      da.orders.crud.getPreviewOrderTotals(uiOrder, apiOrder?.id || ""),
    );
  }
}

function* onGetApiOrder(action: ReturnType<typeof da.orders.crud.getApiOrder>) {
  try {
    yield all([
      put(da.orders.crud.apiFetching(true)),
      put(da.orders.crud.apiSuccess(false)),
      put(da.orders.crud.apiError({ message: "", status: 0 })),
    ]);
    const res: OrderDetailResType = yield call(
      fetchOrderById,
      action.payload.id,
    );
    yield delay(400);
    if ("error" in res) {
      yield all([
        put(da.orders.crud.apiError(res.error)),
        put(da.orders.crud.apiSuccess(false)),
      ]);
    } else {
      yield all([
        put(da.orders.crud.setOrderApiData(res.data)),
        put(da.orders.crud.apiFetching(false)),
        put(da.orders.crud.apiSuccess(true)),
      ]);
    }
  } catch (error: unknown) {
    const errMsg =
      "An error occurred while fetching your order. Please try again later.";
    if (error instanceof Error) {
      console.error(error.message);
    }
    yield all([
      put(
        da.orders.crud.apiError({
          message: errMsg,
          status: MANUAL_ERROR_CODE,
        }),
      ),
      put(da.orders.crud.apiFetching(false)),
      put(da.orders.crud.apiSuccess(false)),
    ]);
  }
}

function* onEditOrder(action: ReturnType<typeof da.orders.crud.editOrder>) {
  try {
    yield all([
      put(da.orders.crud.apiFetching(true)),
      put(da.orders.crud.apiSuccess(false)),
      put(da.orders.crud.apiError({ message: "", status: 0 })),
    ]);
    const profile: MerchantProfileDbType = yield select(selectProfile);
    const billDateISO = formatDatesForOrder(
      action.payload.order.billDate,
      profile.preferredTimezone,
    );
    const serviceDateISO = formatDatesForOrder(
      action.payload.order.serviceDate,
      profile.preferredTimezone,
    );
    const res: OrderCreateResType = yield call(
      fetchUpdateOrderById,
      action.payload.id,
      {
        ...action.payload.order,
        billDate: billDateISO,
        serviceDate: serviceDateISO,
      },
      action.payload.confirm,
    );
    yield delay(400);
    if ("error" in res) {
      yield all([
        put(da.orders.crud.apiError(res.error)),
        put(da.orders.crud.apiFetching(false)),
        put(da.orders.crud.apiSuccess(false)),
      ]);
    } else {
      yield all([
        put(da.orders.crud.setOrderApiData(res.data)),
        put(da.orders.crud.apiFetching(false)),
        put(da.orders.crud.apiSuccess(true)),
      ]);
    }
  } catch (error: unknown) {
    const errMsg = "An error occurred while editing your order.";
    if (error instanceof Error) {
      console.error(error.message);
    }
    yield all([
      put(
        da.orders.crud.apiError({
          message: errMsg,
          status: MANUAL_ERROR_CODE,
        }),
      ),
      put(da.orders.crud.apiFetching(false)),
      put(da.orders.crud.apiSuccess(false)),
    ]);
  }
}

function* onPreviewOrder(
  action: ReturnType<typeof da.orders.crud.getPreviewOrderTotals>,
) {
  try {
    yield all([
      put(da.orders.crud.apiSuccess(false)),
      put(da.orders.crud.apiError({ message: "", status: 0 })),
    ]);
    const profile: MerchantProfileDbType = yield select(selectProfile);
    const billDateISO = formatDatesForOrder(
      action.payload.order.billDate,
      profile.preferredTimezone,
    );
    const serviceDateISO = formatDatesForOrder(
      action.payload.order.serviceDate,
      profile.preferredTimezone,
    );
    const res: OrderCreateResType = yield call(
      fetchPreviewOrder,
      {
        ...action.payload.order,
        billDate: billDateISO,
        serviceDate: serviceDateISO,
      },
      action.payload.orderId,
    );
    if ("error" in res) {
      yield all([
        put(da.orders.crud.apiError(res.error)),
        put(da.orders.crud.apiFetching(false)),
        put(da.orders.crud.apiSuccess(false)),
      ]);
    } else {
      yield all([
        put(da.orders.crud.setOrderApiData(res.data)),
        put(da.orders.crud.apiFetching(false)),
        put(da.orders.crud.apiSuccess(true)),
      ]);
    }
  } catch (error: unknown) {
    const errMsg =
      "An error occurred while editing your order. Please try again later.";
    if (error instanceof Error) {
      console.error(error.message);
    }
    yield all([
      put(
        da.orders.crud.apiError({
          message: errMsg,
          status: MANUAL_ERROR_CODE,
        }),
      ),
      put(da.orders.crud.apiSuccess(false)),
      put(da.orders.crud.apiFetching(false)),
    ]);
  }
}

function* onConfirmOrderDelete(
  action: ReturnType<typeof da.orders.crud.deleteOrder>,
) {
  try {
    yield all([
      put(da.orders.crud.apiSuccess(false)),
      put(da.orders.crud.apiFetching(true)),
      put(da.orders.crud.apiError({ message: "", status: 0 })),
    ]);
    const res: DeleteOrderResType = yield call(
      fetchOrderDeleteById,
      action.payload.id,
    );
    yield delay(400);
    if ("error" in res) {
      yield all([
        put(da.orders.crud.apiFetching(false)),
        put(da.orders.crud.apiError(res.error)),
        put(da.orders.crud.apiSuccess(false)),
      ]);
    } else {
      yield all([
        put(da.orders.crud.setDeletedOrder(action.payload.id)),
        put(da.orders.crud.apiSuccess(true)),
        put(da.orders.crud.apiFetching(false)),
      ]);
    }
  } catch (error: unknown) {
    const errMsg =
      "An error occurred while deleting your order. Please try again later.";
    if (error instanceof Error) {
      console.error(error.message);
    }
    yield all([
      put(
        da.orders.crud.apiError({
          message: errMsg,
          status: MANUAL_ERROR_CODE,
        }),
      ),
      put(da.orders.crud.apiFetching(false)),
      put(da.orders.crud.apiSuccess(false)),
    ]);
  }
}

function* onGetOrderById(
  action: ReturnType<typeof da.orders.crud.getOrderById>,
) {
  try {
    yield all([
      put(da.orders.crud.apiSuccess(false)),
      put(da.orders.crud.apiFetching(true)),
      put(da.orders.crud.apiError({ message: "", status: 0 })),
    ]);
    const res: OrderDetailResType = yield call(
      fetchOrderById,
      action.payload.id,
    );
    yield delay(400);
    if ("error" in res) {
      yield all([
        put(da.orders.crud.apiFetching(false)),
        put(da.orders.crud.apiError(res.error)),
        put(da.orders.crud.apiSuccess(false)),
      ]);
    } else {
      yield all([
        put(da.orders.crud.setOrderById(res.data)),
        put(da.orders.crud.apiFetching(false)),
        put(da.orders.crud.apiSuccess(true)),
      ]);
    }
  } catch (error: unknown) {
    const errMsg =
      "An error occurred while deleting your order. Please try again later.";
    if (error instanceof Error) {
      console.error(error.message);
    }
    yield all([
      put(
        da.orders.crud.apiError({
          message: errMsg,
          status: MANUAL_ERROR_CODE,
        }),
      ),
      put(da.orders.crud.apiFetching(false)),
      put(da.orders.crud.apiSuccess(false)),
    ]);
  }
}

function* onVoidOrderById(action: ReturnType<typeof da.orders.crud.voidOrder>) {
  try {
    yield all([
      put(da.orders.crud.apiSuccess(false)),
      put(da.orders.crud.apiFetching(true)),
      put(da.orders.crud.apiError({ message: "", status: 0 })),
    ]);
    const res: VoidOrderResType = yield call(
      fetchVoidOrderById,
      action.payload.id,
    );
    yield delay(400);
    if ("error" in res) {
      yield all([
        put(da.orders.crud.apiFetching(false)),
        put(da.orders.crud.apiError(res.error)),
        put(da.orders.crud.apiSuccess(false)),
      ]);
    } else {
      const allOrders: OrderDbType[] = yield select(selectAllOrders);
      const newOrders = allOrders.map((order) => {
        if (order.id === action.payload.id) {
          return { ...order, status: res.data.status };
        }
        return order;
      });
      yield all([
        put(da.orders.all.setAllOrders(newOrders)),
        put(da.orders.crud.apiFetching(false)),
        put(da.orders.crud.apiSuccess(true)),
      ]);
    }
  } catch (error: unknown) {
    const errMsg =
      "An error occurred while voiding your order. Please try again later.";
    if (error instanceof Error) {
      console.error(error.message);
    }
    yield all([
      put(
        da.orders.crud.apiError({
          message: errMsg,
          status: MANUAL_ERROR_CODE,
        }),
      ),
      put(da.orders.crud.apiFetching(false)),
      put(da.orders.crud.apiSuccess(false)),
    ]);
  }
}

function formatDatesForOrder(rawDate: string, preferredTz: string) {
  let dateISO = new Date(rawDate).toISOString();
  if (preferredTz) {
    dateISO = formatDateToTzIso(rawDate, preferredTz);
  }
  return dateISO;
}
