import safeFetch, { ErrorResType } from "@pd/api/utils/safeFetch";
import { endpoints } from "@pd/config/envSetup";

export type PingResType = { data: string } | ErrorResType;
export const fetchPingCheck = async (): Promise<PingResType> => {
  const response = await safeFetch(`${endpoints.stitchBackend}/ping`, {
    method: "GET",
  });
  if (typeof response === "string") {
    return { data: response };
  }
  return response;
};
