import { useState, useEffect } from "react";
import {
  useTheme,
  Stack,
  ButtonBase,
  Typography,
  Snackbar,
  Alert,
  Box,
} from "@mui/material";
import { styled } from "@mui/system";
import { CopyOutlined, CheckOutlined } from "@ant-design/icons";
import { fadeInAnimation } from "./FadeComponents";

const FadeInCheckOutlined = styled(CheckOutlined)`
  animation: 1s ${fadeInAnimation};
`;
const FadeInCopyOutlined = styled(CopyOutlined)`
  animation: 1s ${fadeInAnimation};
`;

type Props = {
  id: string;
  msg?: string;
  iconStyle?: { [key: string]: string | { [key: string]: string } };
  idStyle?: { [key: string]: string | { [key: string]: string } };
};
export default function CopyIdButton({
  msg = "Copied to clipboard",
  iconStyle = { fontSize: "14px" },
  idStyle = { fontSize: "14px" },
  ...props
}: Props) {
  const theme = useTheme();
  const [showCopyMsg, setShowCopyMsg] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  useEffect(() => {
    if (showSuccess) {
      setTimeout(() => {
        setShowSuccess(false);
      }, 3000);
    }
  }, [showSuccess]);

  return (
    <>
      <Stack direction="row" alignItems="center">
        <Stack
          direction="row"
          gap={1}
          alignItems="center"
          justifyContent="flex-start"
          sx={{
            width: "inherit",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
          }}
        >
          <ButtonBase
            onClick={(e) => {
              e.stopPropagation();
              setShowSuccess(true);
              try {
                Promise.resolve().then(async () => {
                  navigator.clipboard.writeText(props.id);
                  setShowCopyMsg(true);
                });
              } catch (err) {
                console.error("Failed to copy id: ", err);
              }
            }}
            sx={{
              padding: "5px",
              "&:hover": {
                fontSize: "24px",
              },
            }}
          >
            {showSuccess ? (
              <Box
                sx={{
                  ...(typeof iconStyle === "object"
                    ? iconStyle
                    : { fontSize: "16px" }),
                }}
              >
                <FadeInCheckOutlined
                  style={{
                    fontSize: "inherit",
                    color: theme.palette.success.darker,
                  }}
                />
              </Box>
            ) : (
              <Box
                sx={{
                  ...(typeof iconStyle === "object"
                    ? iconStyle
                    : { fontSize: "16px" }),
                }}
              >
                <FadeInCopyOutlined
                  style={{
                    fontSize: "inherit",
                    color: theme.palette.primary.main,
                  }}
                />
              </Box>
            )}
          </ButtonBase>
          <Typography
            variant="subtitle2"
            sx={{
              ...idStyle,
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}
          >
            {props.id}
          </Typography>
        </Stack>
      </Stack>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={showCopyMsg}
        autoHideDuration={1500}
        onClose={() => setShowCopyMsg(false)}
        sx={{ mb: 5 }}
      >
        <Alert
          onClose={() => setShowCopyMsg(false)}
          severity="info"
          variant="filled"
          sx={{ width: "100%", backgroundColor: theme.palette.primary.main }}
        >
          {msg}
        </Alert>
      </Snackbar>
    </>
  );
}
