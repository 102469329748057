import { Table, TableBody, TableContainer } from "@mui/material";
import { TableDataSrcType } from "@pd/layouts/MktplaceDashboard/types/tables";
import StitchLoading from "@pd/components/StitchLoading";
import { FadeInBox, FadeInStack } from "@pd/components/FadeComponents";

import StitchPagination from "./StitchPagination";
import DynamicTableHeader from "./DynamicTableHeader";
import { ColumnDefTypes, HeaderSortKeys } from "./types";

type Props = {
  columnDefs: ColumnDefTypes;
  children: React.ReactNode;
  order: "asc" | "desc";
  orderBy: HeaderSortKeys;
  tableSrc: TableDataSrcType;
  showLoading: boolean;
  autoHidePagination: boolean;
  style?: React.CSSProperties;
  onHeaderSort: (
    event: React.MouseEvent<unknown>,
    property: HeaderSortKeys,
  ) => void;
};

export default function DynamicTableHOC({ style = {}, ...props }: Props) {
  // default values
  const { autoHidePagination = false, showLoading = false } = props;
  return (
    <FadeInStack alignItems="flex-end" sx={{ position: "relative" }}>
      <TableContainer
        sx={{
          height: document.documentElement.clientHeight - 350,
          overflowX: "auto",
          overflowY: "auto",
          "::-webkit-scrollbar": {
            width: "12px",
            height: "7px",
          },
          "::-webkit-scrollbar-track": {
            background: "#F1F1F1",
          },
          "::-webkit-scrollbar-thumb": {
            background: "#ddd",
          },
          "::-webkit-scrollbar-thumb:hover": {
            background: "#333",
          },
          ...style,
        }}
      >
        <Table sx={{ filter: showLoading ? "blur(1px)" : "none" }}>
          <DynamicTableHeader
            columns={props.columnDefs}
            onRequestSort={props.onHeaderSort}
            order={props.order}
            orderBy={props.orderBy}
          />
          <TableBody sx={{}}>{props.children}</TableBody>
        </Table>
      </TableContainer>
      <StitchPagination
        order={props.order}
        orderBy={props.orderBy}
        tableSrc={props.tableSrc}
        autoHide={autoHidePagination}
      />
      <FadeInBox
        sx={{
          position: "absolute",
          display: showLoading ? "flex" : "none",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: document.documentElement.clientHeight - 350,
          width: "100%",
          flexGrow: 1,
          backgroundColor: "rgba(0, 0, 0, 0.03)",
          boxShadow: "0 0 5px 5px rgba(0, 0, 0, 0.03)",
        }}
      >
        <StitchLoading size="medium" />
      </FadeInBox>
    </FadeInStack>
  );
}
