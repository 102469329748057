import { getTimeZones } from "@vvo/tzdb";

type UsTimeZone = { label: string; name: string };
type TimeZoneMemo = { [key: string]: UsTimeZone };

export function getTimeZonesByCode(code: string): UsTimeZone[] {
  return getTimeZones()
    .filter((tz) => tz.countryCode === code)
    .map((tz) => ({
      label: tz.alternativeName,
      name: tz.name,
    }));
}

export default function getUsTimeZones(): UsTimeZone[] {
  const allUsTimezones = getTimeZonesByCode("US");
  const timezoneMemo = allUsTimezones.reduce((memo, curr) => {
    if (!memo[curr.label]) {
      memo[curr.label] = curr; // eslint-disable-line no-param-reassign
    }
    return memo;
  }, {} as TimeZoneMemo);

  const usTimezones = Object.keys(timezoneMemo)
    .map((key) => timezoneMemo[key])
    .sort((a, b) => a.label.localeCompare(b.label));

  return usTimezones;
}

export function findTimezoneLabel(timezone: string): string {
  const usTimeZones = getTimeZonesByCode("US");
  const result = usTimeZones.find((tz) => tz.name === timezone)?.label;
  return result || "";
}
