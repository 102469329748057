import { Stack, Typography } from "@mui/material";
import { MerchantProfileDbType } from "@pd/redux/types/dbTypes";
import formatTimestamp from "@pd/utils/formatTimes";

export default function ProfileDetails(props: {
  profile: MerchantProfileDbType | null;
}) {
  const {
    id,
    createdAt,
    firstName,
    lastName,
    email,
    loggedInOn,
    loginIpAddress,
    role,
  } = props.profile || {};
  return (
    <Stack style={{ width: "200px" }}>
      <Stack
        direction="row"
        gap={1}
        sx={{ width: "100%" }}
        justifyContent="space-between"
      >
        <Typography variant="subtitle2" style={{ fontFamily: "CircularBold" }}>
          Name:
        </Typography>
        <Typography variant="subtitle2" textAlign="right">
          {firstName}&nbsp;{lastName}
        </Typography>
      </Stack>
      <Stack
        direction="row"
        gap={1}
        sx={{ width: "100%" }}
        justifyContent="space-between"
      >
        <Typography variant="subtitle2" style={{ fontFamily: "CircularBold" }}>
          Email:
        </Typography>
        <Typography variant="subtitle2" textAlign="right">
          {email}
        </Typography>
      </Stack>
      <Stack
        direction="row"
        gap={1}
        sx={{ width: "100%" }}
        justifyContent="space-between"
      >
        <Typography variant="subtitle2" style={{ fontFamily: "CircularBold" }}>
          Role:
        </Typography>
        <Typography
          variant="subtitle2"
          textAlign="right"
          textTransform="capitalize"
        >
          {role}
        </Typography>
      </Stack>
      <Stack
        direction="row"
        gap={1}
        sx={{ width: "100%" }}
        justifyContent="space-between"
      >
        <Typography variant="subtitle2" style={{ fontFamily: "CircularBold" }}>
          Last login:
        </Typography>
        <Typography variant="subtitle2" textAlign="right">
          {formatTimestamp(loggedInOn || "")}
        </Typography>
      </Stack>
      <Stack
        direction="row"
        gap={1}
        sx={{ width: "100%" }}
        justifyContent="space-between"
      >
        <Typography variant="subtitle2" style={{ fontFamily: "CircularBold" }}>
          Joined:
        </Typography>
        <Typography variant="subtitle2" textAlign="right">
          {formatTimestamp(createdAt || "")}
        </Typography>
      </Stack>
      <Stack
        direction="row"
        gap={1}
        sx={{ width: "100%" }}
        justifyContent="space-between"
      >
        <Typography variant="subtitle2" style={{ fontFamily: "CircularBold" }}>
          IP address:
        </Typography>
        <Typography variant="subtitle2" textAlign="right">
          {loginIpAddress}
        </Typography>
      </Stack>
      <Stack
        direction="row"
        gap={1}
        sx={{ width: "100%" }}
        justifyContent="space-between"
      >
        <Typography variant="subtitle2" style={{ fontFamily: "CircularBold" }}>
          id (last 10):
        </Typography>
        <Typography variant="subtitle2" textAlign="right">
          {id?.slice(-10)}
        </Typography>
      </Stack>
    </Stack>
  );
}
