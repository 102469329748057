import { OutlinedInput } from "@mui/material";
import { SearchOutlined } from "@ant-design/icons";

type Props = {
  value: string;
  onChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
};
export default function TableFilterSearch(props: Props) {
  return (
    <OutlinedInput
      sx={{
        width: "100%",
        alignItems: "center",
        "& .MuiOutlinedInput-root": {
          bgcolor: "transparent",
          "&.Mui-focused": {
            boxShadow: "none",
            outline: "none",
          },
        },
      }}
      value={props.value}
      placeholder="Search by any column value"
      startAdornment={<SearchOutlined />}
      onChange={(e) => props.onChange(e)}
    />
  );
}
