import { Stack, Typography, InputLabel, Switch } from "@mui/material";

type Props = {
  disableOnConfirmed: boolean;
  isFinanced: boolean;
  onChange: (name: string, value: boolean) => void;
};
export default function FinancingToggle(props: Props) {
  return (
    <Stack sx={{ mt: "25px", pl: "65px" }}>
      <InputLabel htmlFor="isFinanced">
        <Stack direction="row" alignItems="center">
          <Typography>Finance this order</Typography>
        </Stack>
      </InputLabel>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ height: "20px" }}
      >
        <Typography color="secondary" variant="body1">
          {props.isFinanced ? "On" : "Off"}
        </Typography>
        <Switch
          disabled={props.disableOnConfirmed}
          checked={props.isFinanced}
          onChange={() => props.onChange("isFinanced", !props.isFinanced)}
        />
      </Stack>
    </Stack>
  );
}
