import { createSelector } from "@reduxjs/toolkit";
import { AppStateType } from "@pd/redux/store/reducers";
import {
  BuyerCreatedDbType,
  // BuyerDetailDbType
} from "@pd/redux/types/dbTypes";
import { CreateBuyerFormTypes } from "@pd/layouts/MktplaceDashboard/pages/Buyers/components/CreateEditBuyer/types";
import { ErrorPayload } from "@pd/api/utils/safeFetch";
import { isDashboardState } from "../../../types";

export const selectCrudBuyerFetching = createSelector(
  (state: AppStateType) => state,
  (state: AppStateType): boolean => {
    if (isDashboardState(state)) {
      return state.dashboard.buyers.crudBuyer.fetching;
    }
    return false;
  },
);

export const selectCrudBuyerError = createSelector(
  (state: AppStateType) => state,
  (state: AppStateType): ErrorPayload => {
    if (isDashboardState(state)) {
      return state.dashboard.buyers.crudBuyer.error;
    }
    return {
      message: "",
      status: 0,
    };
  },
);

export const selectCrudBuyerSuccess = createSelector(
  (state: AppStateType) => state,
  (state: AppStateType): boolean => {
    if (isDashboardState(state)) {
      return state.dashboard.buyers.crudBuyer.success;
    }
    return false;
  },
);

export const selectUiBuyer = createSelector(
  (state: AppStateType) => state,
  (state: AppStateType): CreateBuyerFormTypes | null => {
    if (isDashboardState(state)) {
      return state.dashboard.buyers.crudBuyer.data.ui;
    }
    return null;
  },
);

export const selectApiBuyer = createSelector(
  (state: AppStateType) => state,
  (state: AppStateType): BuyerCreatedDbType | null => {
    if (isDashboardState(state)) {
      return state.dashboard.buyers.crudBuyer.data.api;
    }
    return null;
  },
);

// export const selectBuyerById = createSelector(
//   (state: AppStateType) => state,
//   (state: AppStateType): BuyerDetailDbType | null => {
//     if (isDashboardState(state)) {
//       return state.dashboard.buyers.crudBuyer.data.buyerById;
//     }
//     return null;
//   },
// );
