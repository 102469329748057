import { Stack, Typography, Button, useTheme } from "@mui/material";
import { WarningOutlined } from "@ant-design/icons";
import { ErrorPayload } from "@pd/api/utils/safeFetch";

type Props = {
  apiError: ErrorPayload;
  message?: string;
  onRetry: () => void;
};
export default function DashboardErrorBanner({
  message = "",
  ...props
}: Props) {
  const theme = useTheme();
  const errorMsg =
    message ||
    props.apiError?.message ||
    "Sorry, there was an issue. Please try again.";

  if (!props.apiError.message || !props.apiError.status) {
    return null;
  }
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{
        display: props.apiError?.message ? "flex" : "none",
        height: "70px",
        width: "100%",
        p: 2,
        backgroundColor: theme.palette.warning.lighter,
        border: `1px solid ${theme.palette.warning.main}`,
        borderRadius: "5px",
      }}
      gap={2}
    >
      <WarningOutlined
        style={{ fontSize: "24px", color: theme.palette.warning.main }}
      />
      <Typography
        variant="body1"
        sx={{
          color: theme.palette.warning.darker,
        }}
      >
        {errorMsg}
      </Typography>
      <Button
        variant="text"
        onClick={() => props.onRetry()}
        sx={{
          color: theme.palette.warning.dark,
          "&:hover": {
            color: theme.palette.warning.dark,
            backgroundColor: theme.palette.warning.light,
          },
        }}
      >
        Retry
      </Button>
    </Stack>
  );
}
