import { useState, useEffect } from "react";
import {
  Stack,
  Typography,
  FormControl,
  InputLabel,
  Autocomplete,
  TextField,
} from "@mui/material";
import StitchLoading from "@pd/components/StitchLoading";
import { useAppSelector, useAppDispatch } from "@pd/redux/store";
import { selectAllBuyersFetching } from "@pd/layouts/MktplaceDashboard/redux/selectors/buyers/allBuyers";
import da from "@pd/layouts/MktplaceDashboard/redux/actions";

type Props = {
  buyerId: string;
  buyerName: string;
  buyers: { id: string; name: string }[];
  disableOnConfirmed: boolean;
  onSearchBuyers: (query: string) => void;
  onChange: (name: string, value: string) => void;
};
export default function BuyerSelection(props: Props) {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const [uiFetching, setUiFetching] = useState(false);
  const apiFetching = useAppSelector(selectAllBuyersFetching);
  const [selectedBuyerName, setSelectedBuyerName] = useState(props.buyerName);

  useEffect(() => {
    if (!apiFetching && uiFetching) {
      setUiFetching(false);
    }
  }, [apiFetching]);

  useEffect(
    () => () => {
      dispatch(da.buyers.all.setSearchedBuyers([]));
    },
    [],
  );

  return (
    <Stack spacing={1} sx={{ mt: "50px", pl: "65px" }}>
      <InputLabel htmlFor="buyerId">
        <Stack direction="row" alignItems="center" gap={0.5}>
          <Typography>Buyer</Typography>
          <Typography variant="subtitle2" sx={{ pt: "6px" }}>
            *
          </Typography>
        </Stack>
      </InputLabel>
      <FormControl fullWidth>
        <Autocomplete
          id="buyerId"
          open={open}
          loading={uiFetching}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          onBlur={() => {
            if (!props.buyerId) {
              dispatch(da.buyers.all.setSearchedBuyers([]));
              props.onChange("buyerId", "");
              setUiFetching(false);
            }
          }}
          onChange={(_, value, reason) => {
            if (reason === "clear") {
              props.onChange("buyerId", "");
              setSelectedBuyerName("");
            } else if (reason === "selectOption") {
              if (value && value.id) {
                props.onChange("buyerId", value.id);
              }
              if (value && value.name) {
                setSelectedBuyerName(value.name);
              }
            }
          }}
          disabled={props.disableOnConfirmed}
          onInputChange={(e, value, reason) => {
            e?.preventDefault();
            if (reason === "input") {
              props.onSearchBuyers(value);
              setSelectedBuyerName(value);
              if (value.length > 0) {
                setUiFetching(true);
              } else {
                props.onChange("buyerId", "");
                setUiFetching(false);
              }
            } else if (e && ["click"].includes(e.type) && value) {
              const targetBuyer = props.buyers.find((b) => b.name === value);
              if (targetBuyer) {
                props.onChange("buyerId", targetBuyer.id);
                setSelectedBuyerName(targetBuyer.name);
              }
            }
          }}
          value={props.buyers.find((b) => b.id === props.buyerId) || null}
          inputValue={selectedBuyerName}
          options={props.buyers}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          getOptionLabel={(option) => option.name}
          noOptionsText={
            uiFetching ? (
              <StitchLoading size="medium" />
            ) : (
              <Stack gap={1} sx={{ width: "100%" }} alignItems="center">
                <Typography variant="h6">Search Buyers</Typography>
                <Typography variant="subtitle2">
                  Type a {"buyer's"}{" "}
                  <span style={{ fontFamily: "CircularBold" }}>name</span> to
                  populate list.
                </Typography>
              </Stack>
            )
          }
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={
                props.buyers.length ? "Select a Buyer" : "Search Buyers"
              }
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {uiFetching ? (
                      <StitchLoading size="small" position={{ top: -29 }} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
          renderOption={(_props, option) => (
            <li {..._props} key={option.id}>
              {option.name}
            </li>
          )}
        />
      </FormControl>
    </Stack>
  );
}
