import {
  delay,
  all,
  call,
  put,
  takeLeading,
  takeLatest,
  select,
} from "redux-saga/effects";
import { MANUAL_ERROR_CODE } from "@pd/utils/constants";
import da from "../../actions";
import {
  handleApplyFiltersOrdersTable,
  handleGetOrders,
} from "../orders/allOrders";
import { handleGetTableTransfers } from "../transfers";
import {
  handleApplyFiltersVendorsTable,
  handleGetVendors,
} from "../vendors/allVendors";
import { selectTableFilters } from "../../selectors/table";
import {
  TableAllFiltersType,
  isTableFilterByDateType,
  isTableFilterMiscType,
  isTableFilterBySortType,
} from "../../../types/tables";
import {
  handleApplyFiltersBuyersTable,
  handleGetAllBuyers,
} from "../buyers/allBuyers";

export default function* tableSaga() {
  yield all([
    takeLeading(da.table.getTableData.toString(), onGetTableData),
    takeLatest(
      da.table.getTableDataOnFilterChange.toString(),
      onGetTableDataOnFilterChange,
    ),
  ]);
}

function* onGetTableData(action: ReturnType<typeof da.table.getTableData>) {
  try {
    yield all([
      put(da.table.apiFetching(true)),
      put(da.table.apiSuccess(false)),
      put(da.table.apiError({ message: "", status: 0 })),
    ]);
    const allFilters: TableAllFiltersType = yield select(selectTableFilters);
    let errorMsg: string = "";
    switch (action.payload.dataSrc) {
      case "orders":
        errorMsg = yield call(handleGetOrders);
        if (!errorMsg)
          errorMsg = yield call(handleApplyFiltersOrdersTable, allFilters);
        break;
      case "transfers":
        errorMsg = yield call(handleGetTableTransfers, allFilters);
        break;
      case "vendors":
        errorMsg = yield call(handleGetVendors);
        if (!errorMsg)
          errorMsg = yield call(handleApplyFiltersVendorsTable, allFilters);
        break;
      case "buyers":
        errorMsg = yield call(handleGetAllBuyers);
        if (!errorMsg)
          errorMsg = yield call(handleApplyFiltersBuyersTable, allFilters);
        break;
      default:
        break;
    }
    if (errorMsg) {
      yield all([
        put(da.table.apiError({ message: errorMsg, status: 0 })),
        put(da.table.apiSuccess(false)),
        put(da.table.apiFetching(false)),
      ]);
    } else {
      yield put(da.table.setTableDataSrc(action.payload.dataSrc));
      yield delay(400);
      yield all([
        put(da.table.apiFetching(false)),
        put(da.table.apiSuccess(true)),
      ]);
    }
  } catch (error: unknown) {
    const errMsg = "An error occurred while fetching table data";
    if (error instanceof Error) {
      console.error(error.message);
    }
    yield all([
      put(da.table.apiFetching(false)),
      put(da.table.apiSuccess(false)),
      put(da.table.apiError({ message: errMsg, status: 0 })),
    ]);
  }
}

function* onGetTableDataOnFilterChange(
  action: ReturnType<typeof da.table.getTableDataOnFilterChange>,
) {
  let allFilters: TableAllFiltersType = yield select(selectTableFilters);
  if (isTableFilterBySortType(action.payload.filter)) {
    yield put(da.table.setSortEnabled(false));
    allFilters.pagination = action.payload.filter;
  } else if (isTableFilterMiscType(action.payload.filter)) {
    if (action.payload.filter.type !== "search") {
      yield put(da.table.setTableMiscFilter(action.payload.filter));
      allFilters.miscFilter = action.payload.filter;
    } else {
      allFilters.search = action.payload.filter;
    }
  } else if (isTableFilterByDateType(action.payload.filter)) {
    yield put(da.table.setTableDateFilter(action.payload.filter));
    allFilters.dateFilter = action.payload.filter;
  }
  if (action.payload.changeSrc === "filters") {
    yield put(
      da.table.setTablePagination({
        ...allFilters.pagination,
        offset: 0,
      }),
    );
    allFilters = {
      ...allFilters,
      pagination: {
        ...allFilters.pagination,
        offset: 0,
      },
    };
  }
  try {
    yield all([
      put(da.table.apiFetching(true)),
      put(da.table.apiSuccess(false)),
      put(da.table.apiError({ message: "", status: 0 })),
    ]);
    yield delay(500);
    let errMsg: string = "";
    if (action.payload.dataSrc === "orders") {
      errMsg = yield call(handleApplyFiltersOrdersTable, allFilters);
    } else if (action.payload.dataSrc === "buyers") {
      errMsg = yield call(handleApplyFiltersBuyersTable, allFilters);
    } else if (action.payload.dataSrc === "vendors") {
      errMsg = yield call(handleApplyFiltersVendorsTable, allFilters);
    }
    if (errMsg) {
      yield all([
        put(da.table.apiError({ message: errMsg, status: MANUAL_ERROR_CODE })),
        put(da.table.apiSuccess(false)),
      ]);
    } else {
      yield all([
        put(da.table.setTableDataSrc(action.payload.dataSrc)),
        put(da.table.apiSuccess(true)),
        put(da.table.apiFetching(false)),
      ]);
      if (isTableFilterBySortType(action.payload.filter)) {
        yield all([
          put(da.table.setSortDir(action.payload.filter.sortDir)),
          put(da.table.setSortKey(action.payload.filter.sortKey)),
          put(da.table.setSortEnabled(true)),
        ]);
      }
    }
  } catch (error: unknown) {
    const errMsg = "An error occurred while fetching table data";
    if (error instanceof Error) {
      console.error(error.message);
    }
    yield all([
      put(da.table.apiFetching(false)),
      put(da.table.apiSuccess(false)),
      put(da.table.apiError({ message: errMsg, status: MANUAL_ERROR_CODE })),
    ]);
  }
}
