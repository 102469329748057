import { useState, useMemo, useEffect } from "react";
import { TableRow, TableCell } from "@mui/material";

import {
  selectVendorCrudApiFetching,
  selectVendorCrudApiError,
  selectVendorCrudPayoutsHistory,
} from "@pd/layouts/MktplaceDashboard/redux/selectors/vendors/crudVendors";
import DynamicTableHOC from "@pd/components/DynamicTable";
import da from "@pd/layouts/MktplaceDashboard/redux/actions";
import { SortDirType, stableSort, getComparator } from "@pd/utils/stableSort";
import { useAppDispatch, useAppSelector } from "@pd/redux/store";
import formatTimes from "@pd/utils/formatTimes";
import formatCurrency from "@pd/utils/formatCurrency";
import CopyIdButton from "@pd/components/CopyIdButton";

import { VendorsPayoutHistoryTableDataType } from "../../types";
import { vendorPayoutHistoryColumns } from "./tableSchemas";
import VendorStatusInfo from "./components/VendorPaymentStatus";

type Props = {
  vendorId: string | undefined;
};
export default function PayoutHistoryTab(props: Props) {
  const dispatch = useAppDispatch();
  const apiFetching = useAppSelector(selectVendorCrudApiFetching);
  const apiError = useAppSelector(selectVendorCrudApiError);
  const pendingPayouts = useAppSelector(selectVendorCrudPayoutsHistory);
  const [uiFetching, setUiFetching] = useState(true);

  const [sortOrder, setSortOrder] = useState<SortDirType>("asc");
  const [orderBy, setOrderBy] =
    useState<keyof VendorsPayoutHistoryTableDataType>("createdAt");

  function handleRequestSort(_: React.MouseEvent<unknown>, property: any) {
    const isAsc = orderBy === property && sortOrder === "asc";
    setSortOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  }

  function handleGetVendorPayoutsHistory() {
    if (props.vendorId) {
      setUiFetching(true);
      dispatch(da.vendors.crud.getPayoutsByVendorId(props.vendorId));
    }
  }

  const rows = pendingPayouts || [];
  const sortedRows = useMemo(
    () => stableSort(rows, getComparator(sortOrder, orderBy)),
    [rows, sortOrder, orderBy],
  );

  useEffect(() => {
    if (!apiError.status && uiFetching) {
      setUiFetching(false);
    } else if (!apiFetching && uiFetching) {
      setUiFetching(false);
    }
  }, [apiError, apiFetching, uiFetching]);

  useEffect(() => {
    handleGetVendorPayoutsHistory();
  }, [props.vendorId]);

  useEffect(
    () =>
      function onUnmount() {
        dispatch(da.vendors.crud.setPayouts([]));
      },
    [],
  );

  if (!props.vendorId) {
    return null;
  }

  return (
    <DynamicTableHOC
      columnDefs={vendorPayoutHistoryColumns}
      onHeaderSort={handleRequestSort}
      order={sortOrder}
      orderBy={orderBy}
      tableSrc="payoutHistory"
    >
      {sortedRows.map((row) => (
        <TableRow
          key={row.id}
          sx={{
            td: {
              fontFamily: "CircularRegular",
              fontSize: "14px",
              width: 150,
              overflowX: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            },
          }}
        >
          <TableCell>{formatTimes(row.createdAt)}</TableCell>
          <TableCell>
            <CopyIdButton id={row.id} />
          </TableCell>
          <TableCell sx={{ p: 0 }}>
            <VendorStatusInfo
              failureReason={row.failureReason}
              status={row.status}
            />
          </TableCell>
          <TableCell>{row.method}</TableCell>
          <TableCell>{formatTimes(row.estimatedClearingDate)}</TableCell>
          <TableCell>{formatCurrency(row.subtotal)}</TableCell>
          <TableCell>{formatCurrency(row.fees)}</TableCell>
          <TableCell>{formatCurrency(row.amount)}</TableCell>
        </TableRow>
      ))}
    </DynamicTableHOC>
  );
}
