import { useEffect, useCallback, useState } from "react";
import { Modal, Fade, Stack } from "@mui/material";
import ModalTitle from "@pd/components/ModalTitle";
import { useAppDispatch, useAppSelector } from "@pd/redux/store";
import pd from "@pd/layouts/MktplaceDashboard/redux/actions";
import {
  selectVendorCrudApiSuccess,
  selectVendorCrudApiFetching,
  selectVendorCrudApiError,
  selectInviteLink,
} from "@pd/layouts/MktplaceDashboard/redux/selectors/vendors/crudVendors";
import StitchLoading from "@pd/components/StitchLoading";

import { InviteSentDialogue, CreateInvite } from "./components";

const modalStyles = {
  position: "absolute" as const,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  borderRadius: "5px",
  boxShadow: 24,
  minWidth: "670px",
  maxWidth: "400px",
  minHeight: "300px",
};

type Props = {
  onClose: () => void;
};
export default function CreateInviteModal(props: Props) {
  const dispatch = useAppDispatch();

  const apiFetching = useAppSelector(selectVendorCrudApiFetching);
  const apiSuccess = useAppSelector(selectVendorCrudApiSuccess);
  const apiError = useAppSelector(selectVendorCrudApiError);
  const inviteLink = useAppSelector(selectInviteLink);

  const [referenceId, setReferenceId] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");

  const handleSendInvite = useCallback(() => {
    dispatch(
      pd.vendors.crud.sendInvite({
        referenceId,
        firstName,
        lastName,
        email,
      }),
    );
  }, [referenceId, firstName, lastName, email]);

  useEffect(() => {
    if (apiError) {
      if (apiError.message.includes("email")) {
        setEmail("");
      } else if (apiError.message.includes("name")) {
        setFirstName("");
        setLastName("");
      } else if (apiError.message.includes("reference")) {
        setReferenceId("");
      }
    }
  }, [apiError]);

  useEffect(() => {
    dispatch(pd.vendors.crud.apiError({ message: "", status: 0 }));
  }, []);

  const handleRetry = () => {
    dispatch(pd.vendors.crud.apiError({ message: "", status: 0 }));
  };

  const renderContent = () => {
    if (apiFetching) {
      return (
        <Stack
          sx={{
            width: "100%",
            height: "100%",
            minHeight: "500px",
            flexGrow: 1,
          }}
          alignItems="center"
          justifyContent="center"
        >
          <StitchLoading size="medium" />
        </Stack>
      );
    }

    if (apiSuccess && inviteLink) {
      return <InviteSentDialogue link={inviteLink} onClose={props.onClose} />;
    }

    return (
      <CreateInvite
        firstName={firstName}
        lastName={lastName}
        referenceId={referenceId}
        email={email}
        apiFetching={apiFetching}
        apiError={apiError}
        apiSuccess={apiSuccess}
        setFirstName={setFirstName}
        setLastName={setLastName}
        setReferenceId={setReferenceId}
        setEmail={setEmail}
        onSubmit={handleSendInvite}
        onClose={props.onClose}
        onRetry={handleRetry}
      />
    );
  };

  return (
    <Modal open disableEnforceFocus closeAfterTransition>
      <Fade in>
        <Stack gap={2} sx={modalStyles} justifyContent="flex-start">
          <ModalTitle title="Create vendor invite" onClose={props.onClose} />
          {renderContent()}
        </Stack>
      </Fade>
    </Modal>
  );
}
