import { Stack } from "@mui/material";
import AuthGuard from "@pd/components/AuthGuard";
import LinkedAccounts from "./components/LinkedAccounts";
import ManageTeamTable from "./components/ManageTeam";
import ApiKeysTable from "./components/ApiKeysTable";

export default function MktplaceSettings() {
  return (
    <AuthGuard>
      <Stack
        justifyContent="flex-start"
        alignItems="stretch"
        sx={{
          mt: "100px",
          px: "40px",
        }}
      >
        <LinkedAccounts />
        <ManageTeamTable />
        <ApiKeysTable />
      </Stack>
    </AuthGuard>
  );
}
