import { useState } from "react";
import { Autocomplete, TextField } from "@mui/material";
import {
  OrderStatusOptionType,
  OrderPayoutStatusOptionType,
} from "@pd/layouts/MktplaceDashboard/pages/Orders/types";
import { TransfersStatusOptionType } from "@pd/layouts/MktplaceDashboard/pages/TransferActivity/types";
import { VendorsKycStatusOptionType } from "@pd/layouts/MktplaceDashboard/pages/Vendors/types";
import StitchLoading from "../StitchLoading";

export type FilterOptionsType =
  | OrderStatusOptionType
  | OrderPayoutStatusOptionType
  | TransfersStatusOptionType
  | VendorsKycStatusOptionType;

type Props = {
  filterName: string;
  filterValue: FilterOptionsType;
  filterOptions: FilterOptionsType[];
  fetching: boolean;
  onFilterChange: (value: FilterOptionsType | null) => void;
};
export default function FilterDropdown(props: Props) {
  const [open, setOpen] = useState(false);

  return (
    <Autocomplete
      loading={props.fetching}
      open={open}
      onChange={(_, value) => props.onFilterChange(value)}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      value={props.filterValue.status ? props.filterValue : null}
      options={props.filterOptions.sort(
        (a, b) => -b.status.localeCompare(a.status),
      )}
      isOptionEqualToValue={(option, value) => option.key === value.key}
      getOptionLabel={(option) => option.label}
      renderOption={(props, option) => (
        <li {...props} key={option.status} data-option-code={option.status}>
          {option.label}
        </li>
      )}
      renderInput={(params) => (
        <TextField {...params} placeholder={props.filterName} />
      )}
      loadingText={
        <StitchLoading size="small" position={{ top: -30, left: 0 }} />
      }
    />
  );
}
