import { takeLeading, all, put, call, delay, select } from "redux-saga/effects";
import { MANUAL_ERROR_CODE } from "@pd/utils/constants";
import da from "@pd/layouts/MktplaceDashboard/redux/actions";
import {
  fetchCreateTeamMember,
  fetchEditTeamMember,
  fetchDeleteTeamMember,
  CreateTeamMemberResType,
  EditTeamMemberResType,
  DeleteTeamMemberResType,
} from "@pd/api/dashboard/teams";
import { TeamMemberDbType } from "@pd/redux/types/dbTypes";
import { selectAllTeamMembers } from "../../selectors/teams";

export default function* crudTeamSaga() {
  yield all([
    takeLeading(da.teams.crud.createTeamMember.toString(), onCreateTeam),
    takeLeading(da.teams.crud.editTeamMember.toString(), onEditTeamMember),
    takeLeading(da.teams.crud.deleteTeamMember.toString(), onDeleteTeam),
  ]);
}

function* onCreateTeam(
  action: ReturnType<typeof da.teams.crud.createTeamMember>,
): Generator<any, void, any> {
  try {
    yield all([
      put(da.teams.crud.apiFetching(true)),
      put(da.teams.crud.apiSuccess(false)),
      put(da.teams.crud.apiError({ message: "", status: 0 })),
    ]);
    const res: CreateTeamMemberResType = yield call(
      fetchCreateTeamMember,
      action.payload.data,
    );
    if ("error" in res) {
      yield all([
        put(da.teams.crud.apiFetching(false)),
        put(da.teams.crud.apiSuccess(false)),
        put(da.teams.crud.apiError(res.error)),
      ]);
    } else {
      yield delay(400);
      const [teamMembers, hasUpdateError] = yield call(
        insertTeamMember,
        res.data,
      );
      if (hasUpdateError) {
        yield all([
          put(da.teams.crud.apiSuccess(true)),
          put(da.teams.crud.apiFetching(false)),
        ]);
      } else {
        yield all([
          put(da.teams.all.setAllTeamMembers(teamMembers)),
          put(da.teams.crud.apiSuccess(true)),
          put(da.teams.crud.apiFetching(false)),
        ]);
      }
    }
  } catch (error) {
    const errMsg =
      "An error occured while fetching your Team Members. Please try again.";
    if (error instanceof Error) {
      console.error(error.message);
    }
    yield all([
      put(
        da.teams.crud.apiError({
          message: errMsg,
          status: MANUAL_ERROR_CODE,
        }),
      ),
      put(da.teams.crud.apiFetching(false)),
      put(da.teams.crud.apiSuccess(false)),
    ]);
  }
}

function* insertTeamMember(
  teamMember: TeamMemberDbType,
): Generator<any, [TeamMemberDbType[] | null, boolean], any> {
  try {
    const allTeamMembers: TeamMemberDbType[] =
      yield select(selectAllTeamMembers);
    const updatedTeamMembers = [teamMember].concat(allTeamMembers);
    return [updatedTeamMembers, false];
  } catch (err) {
    if (err instanceof Error) {
      console.error(err.message);
    }
    return [null, true];
  }
}

export function* onDeleteTeam(
  action: ReturnType<typeof da.teams.crud.deleteTeamMember>,
) {
  try {
    yield all([
      put(da.teams.crud.apiFetching(true)),
      put(da.teams.crud.apiSuccess(false)),
      put(da.teams.crud.apiError({ message: "", status: 0 })),
    ]);
    const res: DeleteTeamMemberResType = yield call(
      fetchDeleteTeamMember,
      action.payload.id,
    );
    if ("error" in res) {
      yield all([
        put(da.teams.crud.apiFetching(false)),
        put(da.teams.crud.apiSuccess(false)),
        put(da.teams.crud.apiError(res.error)),
      ]);
    } else {
      yield delay(1000);
      yield all([
        put(da.teams.crud.apiSuccess(true)),
        put(da.teams.crud.apiFetching(false)),
        put(da.teams.all.getAllTeamMembers()),
      ]);
    }
  } catch (error) {
    const errMsg =
      "An error occured while editing your Team Member. Please try again.";
    if (error instanceof Error) {
      console.error(error.message);
    }
    yield all([
      put(
        da.teams.crud.apiError({
          message: errMsg,
          status: MANUAL_ERROR_CODE,
        }),
      ),
      put(da.teams.crud.apiFetching(false)),
      put(da.teams.crud.apiSuccess(false)),
    ]);
  }
}

export function* onEditTeamMember(
  action: ReturnType<typeof da.teams.crud.editTeamMember>,
) {
  try {
    yield all([
      put(da.teams.crud.apiFetching(true)),
      put(da.teams.crud.apiError({ message: "", status: 0 })),
    ]);
    const res: EditTeamMemberResType = yield call(
      fetchEditTeamMember,
      action.payload.data,
      action.payload.id,
    );
    if ("error" in res) {
      yield all([
        put(da.teams.crud.apiFetching(false)),
        put(da.teams.crud.apiSuccess(false)),
        put(da.teams.crud.apiError(res.error)),
      ]);
    } else {
      const allTeamMembers: TeamMemberDbType[] =
        yield select(selectAllTeamMembers);
      const newTeamMembers = allTeamMembers.filter(
        (member) => member.id !== action.payload.id,
      );
      newTeamMembers.unshift(res.data);
      yield delay(400);
      yield all([
        put(da.teams.all.setAllTeamMembers(newTeamMembers)),
        put(da.teams.crud.apiSuccess(true)),
        put(da.teams.crud.apiFetching(false)),
      ]);
    }
  } catch (error) {
    const errMsg =
      "An error occured while deleting your Team Member. Please try again.";
    if (error instanceof Error) {
      console.error(error.message);
    }
    yield all([
      put(
        da.teams.crud.apiError({
          message: errMsg,
          status: MANUAL_ERROR_CODE,
        }),
      ),
      put(da.teams.crud.apiFetching(false)),
      put(da.teams.crud.apiSuccess(false)),
    ]);
  }
}
