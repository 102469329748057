import { useState, useCallback, useEffect } from "react";
import { Modal, Button, Fade, Stack, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "@pd/redux/store";
import StitchLoading from "@pd/components/StitchLoading";
import pd from "@pd/layouts/MktplaceDashboard/redux/actions";
import { CheckCircleOutline } from "@mui/icons-material";
import {
  selectCrudTeamMembersApiError,
  selectCrudTeamMembersApiFetching,
  selectCrudTeamMembersApiSuccess,
} from "@pd/layouts/MktplaceDashboard/redux/selectors/teams";
import ModalTitle from "@pd/components/ModalTitle";

import { modalStyles } from "../modalStyles";
import CreateTeamMemberForm from "./CreateTeamMemberForm";
import { RoleSelectionType } from "../../../types";

type Props = {
  show: boolean;
  onClose: () => void;
};
export default function AddTeamMember(props: Props) {
  const dispatch = useAppDispatch();

  const apiFetching = useAppSelector(selectCrudTeamMembersApiFetching);
  const apiError = useAppSelector(selectCrudTeamMembersApiError);
  const apiSuccess = useAppSelector(selectCrudTeamMembersApiSuccess);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("+1");
  const [roleType, setRoleType] = useState<RoleSelectionType | null>(null);
  const [timezone, setTimezone] = useState("");

  const handleResetForm = useCallback(() => {
    setFirstName("");
    setLastName("");
    setEmail("");
    setPhone("");
    setRoleType(null);
    setTimezone("");
  }, []);

  const handleOnClose = useCallback(() => {
    dispatch(pd.teams.crud.apiSuccess(false));
    handleResetForm();
    props.onClose();
  }, []);

  const handleConfirmCreate = useCallback(() => {
    dispatch(
      pd.teams.crud.createTeamMember({
        firstName,
        lastName,
        email,
        phone,
        timezone,
        role: roleType!.name,
      }),
    );
  }, [firstName, lastName, email, phone, timezone, roleType]);

  if (!props.show) return null;

  useEffect(() => {
    if (apiError) {
      if (apiError.message.includes("phone")) {
        setPhone("+1");
      } else if (apiError.message.includes("email")) {
        setEmail("");
      } else if (apiError.message.includes("name")) {
        setFirstName("");
        setLastName("");
      } else if (apiError.message.includes("role")) {
        setRoleType(null);
      } else if (apiError.message.includes("timezone")) {
        setTimezone("");
      }
    }
  }, [apiError]);

  useEffect(() => {
    dispatch(pd.teams.crud.apiError({ message: "", status: 0 }));
  }, []);

  return (
    <Modal open>
      <Fade in>
        <Stack
          gap={2}
          sx={{
            ...modalStyles,
            height: "715px",
          }}
          justifyContent="flex-start"
        >
          <ModalTitle
            title={(() => {
              if (apiFetching) return "Creating...";
              if (apiError.message) return "Error";
              if (apiSuccess) return "Success";
              return "Create Member";
            })()}
            onClose={handleOnClose}
          />
          <Stack
            gap={3}
            alignItems="center"
            justifyContent="center"
            sx={{ my: "auto", p: 3 }}
          >
            {(() => {
              if (apiFetching) {
                return <StitchLoading />;
              }
              if (apiSuccess) {
                return (
                  <Stack gap={4} alignItems="center">
                    <CheckCircleOutline style={{ fontSize: "48px" }} />
                    <Typography variant="h3">Member Created!</Typography>
                    <Button
                      variant="contained"
                      onClick={handleOnClose}
                      sx={{ width: "200px", height: "40px" }}
                    >
                      Close
                    </Button>
                  </Stack>
                );
              }
              return (
                <CreateTeamMemberForm
                  firstName={firstName}
                  lastName={lastName}
                  email={email}
                  phone={phone}
                  roleType={roleType}
                  timezone={timezone}
                  setFirstName={setFirstName}
                  setLastName={setLastName}
                  setEmail={setEmail}
                  setPhone={setPhone}
                  setRoleType={setRoleType}
                  setTimezone={setTimezone}
                  onConfirmCreate={handleConfirmCreate}
                  onClose={handleOnClose}
                  apiFetching={apiFetching}
                  apiSuccess={apiSuccess}
                  apiError={apiError}
                />
              );
            })()}
          </Stack>
        </Stack>
      </Fade>
    </Modal>
  );
}
