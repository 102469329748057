/* eslint-disable react/no-array-index-key */
import { useState, useEffect } from "react";
import { Modal, Fade, Stack, Tabs, Tab } from "@mui/material";
import { useAppDispatch, useAppSelector } from "@pd/redux/store";
import da from "@pd/layouts/MktplaceDashboard/redux/actions";
import { selectUploadReport } from "@pd/layouts/MktplaceDashboard/redux/selectors/orders/csvUpload";
import ModalTitle from "@pd/components/ModalTitle";

import {
  TabPanel,
  FilePickerTab,
  ErrorReportTab,
  ExampleTab,
} from "./components";
import { modalStyles } from "../constants/styles";

type Props = {
  showModal: boolean;
  onClose: () => void;
};
export default function CsvUploadModal(props: Props) {
  const dispatch = useAppDispatch();
  const uploadReport = useAppSelector(selectUploadReport);

  const [tabValue, setTabValue] = useState(0);
  const [hasUploadReport, setHasUploadReport] = useState(false);
  const [pickedFile, setPickedFile] = useState<File | null>(null);

  useEffect(() => {
    if (uploadReport && tabValue === 0 && !hasUploadReport) {
      setTabValue(2);
      setHasUploadReport(true);
    }
  }, [uploadReport, tabValue]);

  const cleanup = () => {
    setPickedFile(null);
    setTabValue(0);
    setHasUploadReport(false);
  };

  const handleCloseModal = () => {
    dispatch(da.orders.csvUpload.reset());
    props.onClose();
    cleanup();
  };

  useEffect(
    () => () => {
      dispatch(da.orders.csvUpload.reset());
      handleCloseModal();
      cleanup();
    },
    [],
  );

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const handlePickFile = (file: File | null) => {
    if (!file) {
      setHasUploadReport(false);
    }
    setTabValue(0);
    setPickedFile(file);
  };

  return (
    <Modal open={props.showModal}>
      <Fade in={props.showModal}>
        <Stack
          gap={2}
          sx={{
            ...modalStyles,
            height: "50vh",
            width: "830px",
          }}
          justifyContent="flex-start"
        >
          <ModalTitle title="Upload CSV" onClose={handleCloseModal} />
          <Tabs value={tabValue} onChange={handleTabChange}>
            <Tab label="Upload" />
            <Tab label="Examples" />
            <Tab label="Error Report" disabled={!hasUploadReport} />
          </Tabs>
          <TabPanel value={tabValue} index={0} style={{ height: "100%" }}>
            <FilePickerTab
              onClose={props.onClose}
              onPickFile={handlePickFile}
              onTabChange={setTabValue}
              pickedFile={pickedFile}
            />
          </TabPanel>
          <TabPanel
            value={tabValue}
            index={1}
            style={{ maxHeight: "100%", flexGrow: 1 }}
          >
            <ExampleTab
              onTabChange={(tabIndex: number) => setTabValue(tabIndex)}
            />
          </TabPanel>
          <TabPanel
            value={tabValue}
            index={2}
            style={{
              maxHeight: "85%",
            }}
          >
            <ErrorReportTab />
          </TabPanel>
        </Stack>
      </Fade>
    </Modal>
  );
}
