import { createSelector } from "@reduxjs/toolkit";

import { isDashboardState } from "@pd/layouts/MktplaceDashboard/types";
import { AppStateType } from "@pd/redux/store/reducers";

export const selectNextRoute = createSelector(
  (state: AppStateType) => state,
  (state): string => {
    if (isDashboardState(state)) {
      return state.dashboard.routes.nextRoute;
    }
    return "";
  },
);
