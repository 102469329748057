import MiscColors from "@pd/mantis/themes/miscColors";
import {
  isVendorKycStatusType,
  VENDOR_KYC_STATUS_COLORS,
} from "@pd/layouts/MktplaceDashboard/pages/Vendors/types";
import {
  TeamMemberRoleType,
  TeamMemberStatusType,
} from "@pd/layouts/MktplaceDashboard/pages/Settings/components/ManageTeam/types";
import {
  OrderPayoutStatus,
  OrderStatus,
  OrderFinanceStatus,
} from "@pd/redux/types/orders";
import { TransferStatus } from "@pd/redux/types/transfers";
import { VendorKycStatus } from "@pd/redux/types/vendors";
import {
  isOrderPayoutStatusType,
  isOrderStatusType,
  isOrderFinanceStatusType,
  ORDER_PAYOUT_STATUS_COLORS,
  ORDER_FINANCE_STATUS_COLORS,
  ORDER_STATUS_COLORS,
} from "@pd/layouts/MktplaceDashboard/pages/Orders/types";
import {
  isTransfersStatusType,
  TRANSFER_STATUS_COLORS,
} from "@pd/layouts/MktplaceDashboard/pages/TransferActivity/types";
import { FadeInBox } from "../FadeComponents";

const mColors = MiscColors();

type ColorType = string | undefined;
type StatusTypes =
  | "order-status"
  | "order-payout-status"
  | "order-finance-status"
  | "transfers"
  | "vendors"
  | "vendors/payoutHistory"
  | "vendors/orderHistory"
  | "teams/status"
  | "teams/roles";

type StatusCellTypes =
  | keyof typeof OrderStatus
  | keyof typeof OrderPayoutStatus
  | keyof typeof OrderFinanceStatus
  | keyof typeof TransferStatus
  | keyof typeof VendorKycStatus
  | keyof typeof TeamMemberRoleType
  | keyof typeof TeamMemberStatusType;

type Props = {
  status: StatusCellTypes;
  type: StatusTypes;
};
export default function StatusCell(props: Props) {
  let bgColor: ColorType;
  let status: string = props.status as string;
  switch (props.type) {
    case "order-status": {
      if (isOrderStatusType(props.status)) {
        bgColor = ORDER_STATUS_COLORS[props.status];
      } else {
        bgColor = mColors.statusVioletBg;
      }
      break;
    }
    case "order-payout-status": {
      if (isOrderPayoutStatusType(props.status)) {
        bgColor = ORDER_PAYOUT_STATUS_COLORS[props.status];
      } else {
        bgColor = mColors.statusVioletBg;
      }
      break;
    }
    case "order-finance-status": {
      if (isOrderFinanceStatusType(props.status)) {
        bgColor = ORDER_FINANCE_STATUS_COLORS[props.status];
      } else {
        bgColor = mColors.statusGrayBg;
      }
      break;
    }
    case "vendors/payoutHistory":
    case "transfers": {
      if (isTransfersStatusType(props.status)) {
        bgColor = TRANSFER_STATUS_COLORS[props.status];
      } else {
        bgColor = mColors.statusVioletBg;
      }
      break;
    }
    case "vendors": {
      if (isVendorKycStatusType(props.status)) {
        bgColor = VENDOR_KYC_STATUS_COLORS[props.status];
        if (props.status === VendorKycStatus.manual_review) {
          status = "manual review";
        } else if (props.status === VendorKycStatus.document) {
          status = "needs documentation";
        }
      } else {
        bgColor = mColors.statusVioletBg;
      }
      break;
    }
    case "teams/status": {
      switch (props.status) {
        case "verified":
          bgColor = mColors.statusGreenBg;
          break;
        case "inactive":
          bgColor = mColors.statusGrayBg;
          break;
        case "pending":
          bgColor = mColors.statusYellowBg;
          break;
        default:
          bgColor = mColors.statusVioletBg;
      }
      break;
    }
    case "teams/roles": {
      switch (props.status) {
        case "admin":
          bgColor = mColors.statusGreenBg;
          break;
        case "editor":
          bgColor = mColors.statusPurpleBg;
          break;
        case "viewer":
          bgColor = mColors.statusVioletBg;
          break;
        default:
          bgColor = mColors.statusVioletBg;
      }
      break;
    }
    default:
      bgColor = mColors.statusVioletBg;
  }

  return (
    <FadeInBox>
      <div
        style={{
          display: "inline-block",
          backgroundColor: bgColor,
          color: mColors.textBlack,
          padding: "5px 10px",
          borderRadius: "5px",
          fontSize: "12px",
          lineHeight: "16px",
          textAlign: "center",
          fontFamily: "CircularBold",
          textTransform: "capitalize",
        }}
      >
        {status}
      </div>
    </FadeInBox>
  );
}
