import { Stack, Box, Typography, useTheme } from "@mui/material";
import { AlertOutlined } from "@ant-design/icons";
import BlackDot from "@pd/components/BlackDot";
import "./style.css";

type Props = {
  rejectReasons: string[];
};
export default function RejectionMsg(props: Props) {
  const theme = useTheme();
  return (
    <Stack alignItems="flex-start" sx={{ width: "70%", ml: "25px" }} gap={3}>
      <Stack gap={3} direction="row" alignItems="baseline">
        <Box className="pulse">
          <AlertOutlined
            style={{ fontSize: "30px", color: theme.palette.error.main }}
          />
        </Box>
        <Typography variant="h2">User rejected</Typography>
      </Stack>
      <Typography variant="h5">
        The Vendors identity information was rejected from our banking provider.
        <br /> Below is the provided reason from the provider.
      </Typography>
      <Stack direction="row" gap={2}>
        <Typography variant="body1" sx={{ fontFamily: "CircularBold" }}>
          {"Reason(s): "}
        </Typography>
        {props.rejectReasons.length > 1 ? (
          <Stack>
            {props.rejectReasons.map((reason) => (
              <Stack gap={1} direction="row" alignItems="center" key={reason}>
                <BlackDot />
                <Typography
                  variant="body1"
                  sx={{ textTransform: "capitalize" }}
                >
                  {reason}.
                </Typography>
              </Stack>
            ))}
          </Stack>
        ) : (
          <Typography variant="body1" sx={{ textTransform: "capitalize" }}>
            {props.rejectReasons[0]}.
          </Typography>
        )}
      </Stack>
    </Stack>
  );
}
