import { useEffect, useState } from "react";
import { Stack, Typography, Box } from "@mui/material";
import { useAppSelector, useAppDispatch } from "@pd/redux/store";
import da from "@pd/layouts/MktplaceDashboard/redux/actions";
import {
  selectOrderById,
  selectCrudOrderFetching,
  selectCrudOrderError,
} from "@pd/layouts/MktplaceDashboard/redux/selectors/orders/crudOrder";

import LeftBackBtn from "@pd/components/LeftBackBtn";
import StitchLoading from "@pd/components/StitchLoading";
import DashboardErrorBanner from "@pd/components/DashboardErrorBanner";
import { FadeInStack } from "@pd/components/FadeComponents";
import CopyIdButton from "@pd/components/CopyIdButton";
import OverviewSection from "./components/OverviewSection";
import AdvancesSection from "./components/AdvancesSection";
import OrderList from "./components/OrderList";
import BuyerTerms from "./components/BuyerTerms";
import VendorTerms from "./components/VendorTerms";

type Props = {
  orderId: string;
  onCloseForm: () => void;
};
export default function OrderDetails(props: Props) {
  const dispatch = useAppDispatch();
  const order = useAppSelector(selectOrderById);
  const apiError = useAppSelector(selectCrudOrderError);
  const apiFetching = useAppSelector(selectCrudOrderFetching);

  const [uiFetching, setUiFetching] = useState(false);

  function handleGetOrderById() {
    setUiFetching(true);
    dispatch(da.orders.crud.getOrderById(props.orderId));
  }

  useEffect(() => {
    if (apiError.status && uiFetching) {
      setUiFetching(false);
    } else if (!apiFetching && uiFetching) {
      setUiFetching(false);
    }
  }, [apiError, apiFetching, uiFetching]);

  useEffect(() => {
    handleGetOrderById();
    return () => {
      dispatch(da.orders.crud.clearOrderById());
    };
  }, []);

  if (uiFetching) {
    return (
      <FadeInStack
        data-testid="order-details-loading"
        direction={{ xs: "column", md: "row" }}
        sx={{
          width: "100%",
          mt: "25px",
          padding: { xs: "0px 5%", sm: "0px 10%", md: "0px 15%" },
        }}
      >
        <LeftBackBtn onCloseForm={props.onCloseForm} />
        <Stack
          sx={{
            width: "100%",
            mt: 1,
            alignItems: { xs: "center", md: "flex-start" },
          }}
        >
          <Typography
            variant="h2"
            sx={{
              mb: 2,
              fontFamily: "CircularBold",
              textAlign: { xs: "center", md: "left" },
            }}
          >
            Order #
          </Typography>
          <Box
            sx={{
              width: "100%",
              pt: { xs: "50px", sm: "75px", md: "100px" },
              flexGrow: 1,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <StitchLoading />
          </Box>
        </Stack>
      </FadeInStack>
    );
  }

  return (
    <FadeInStack
      data-testid="order-details"
      direction="row"
      sx={{ width: "100%", mt: "25px", mb: "150px", padding: "0px 15%" }}
    >
      <LeftBackBtn
        onCloseForm={() => {
          dispatch(da.orders.crud.clearOrderById());
          props.onCloseForm();
        }}
      />
      <Stack sx={{ width: "100%", mt: 1 }}>
        <Stack
          gap={1}
          direction={{
            xs: "column",
            md: "row",
          }}
          alignItems="baseline"
          sx={{ width: "100%", mb: 2 }}
        >
          <Typography
            variant="h2"
            sx={{
              fontSize: {
                xs: "18px",
                sm: "18px",
                md: "22px",
                lg: "26px",
              },
              fontFamily: "CircularBold",
            }}
          >
            {"Order #"}
          </Typography>
          <CopyIdButton
            id={`${order?.id || props.orderId}`}
            iconStyle={{
              fontSize: {
                xs: "14px",
                sm: "14px",
                md: "16px",
                lg: "20px",
              },
              fontFamily: "CircularBold",
            }}
            idStyle={{
              fontSize: {
                xs: "14px",
                sm: "16px",
                md: "22px",
                lg: "22px",
              },
              fontFamily: "CircularBold",
            }}
          />
        </Stack>
        <OverviewSection order={order} />
        <OrderList order={order} />
        <Stack
          sx={{ mb: 5 }}
          direction={{
            lg: "row",
            md: "column",
          }}
          gap={2}
        >
          <BuyerTerms order={order} />
          <VendorTerms order={order} />
        </Stack>
        <AdvancesSection order={order} />
        <DashboardErrorBanner
          onRetry={() => handleGetOrderById()}
          apiError={apiError}
        />
      </Stack>
    </FadeInStack>
  );
}
