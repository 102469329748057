/* eslint-disable no-shadow */
import {
  TransferSourceType,
  TransferMethodEnum,
  TransferTypeEnum,
  TransferStatus,
  TransferPaymentProcessorEnum,
} from "./transfers";
import { OrderPayoutStatus, OrderStatus } from "./orders";
import { VendorKycStatus } from "./vendors";

export type AddressDbType = {
  line1: string;
  line2: string;
  city: string;
  state: string;
  postalCode: string;
  country: string;
};

export const defaultAddress: AddressDbType = {
  line1: "",
  line2: "",
  city: "",
  state: "",
  postalCode: "",
  country: "USA",
};

export type BuyerDbType = {
  id: string;
  totalResults: number;
  createdAt: string;
  updatedAt: string;
  merchantId: string;
  extId: string;
  name: string;
  firstName: string;
  lastName: string;
  taxId: string;
  email: string;
  phone: string;
  address: AddressDbType;
  defaultOrderRate: number;
  defaultOrderFee: number;
  settlementPeriod: number;
};

export type BuyerCreatedDbType = {
  id: string;
  createdAt: string;
  updatedAt: string;
  merchantId: string;
  extId: string;
  companyName: string;
  firstName: string;
  lastName: string;
  taxId: string;
  email: string;
  phone: string;
  address: AddressDbType;
  defaultOrderRate: number;
  defaultOrderFee: number;
  settlementPeriod: number;
};

// TODO: Uncomment when there is a getBuyerByIdAndMerchantId
// export type BuyerDetailDbType = {
//   id: string;
//   createdAt: string;
//   updatedAt: string;
//   merchantId: string;
//   extId: string;
//   name: string;
//   taxId: string;
//   email: string;
//   phone: string;
//   address: AddressDbType;
//   defaultOrderRate: number;
//   defaultOrderFee: number;
//   settlementPeriod: number;
// }

export type BuyersSearchDbType = {
  id: string;
  name: string;
};
export type VendorsSearchDbType = {
  id: string;
  name: string;
};

export type PaymentStatusDbType =
  | "pending"
  | "cancelled"
  | "completed"
  | "reversed"
  | "failed";

export type PaymentDbType = {
  id: string;
  created_at: string;
  updated_at: string;
  amount: number;
  invoice_id: string;
  status: PaymentStatusDbType;
};

export type LinkedAccountStatusType = "active" | "expired";

export type LinkedAccountDbType = {
  institution_id: string;
  account_name: string;
  account_number: string;
  subtype: string;
  mask_number: string;
  status: LinkedAccountStatusType;
};

export type TermsDbType = {
  terms_offer: string;
  credit_limit: number;
  backup_payment_method: LinkedAccountDbType;
};

export type AutoPayDbType = {
  payment_method: LinkedAccountDbType;
  enabled: boolean;
  next_payment_date: string;
  next_payment_invoices: string[];
};

export type AchWireInfoDbType = {
  routing_number: string;
  account_number: string;
  stitch_address_line_1: string;
  stitch_address_line_2: string;
  bank_name: string;
  bank_address_line_1: string;
  bank_address_line_2: string;
};

export type OrderDbType = {
  id: string;
  totalResults: number;
  createdAt: string;
  referenceId: string;
  buyerId: string;
  buyerName: string;
  vendorId: string;
  vendorName: string;
  total: string;
  status: keyof typeof OrderStatus;
  payoutId: string;
  isFinanced: boolean;
};

export type OrderLineItemDbType = {
  id?: string;
  description: string;
  code: string;
  amount: number;
};

export type OrderCreatedDbType = {
  id: string;
  status: keyof typeof OrderStatus;
  merchantId: string;
  buyerId: string;
  buyerName: string;
  vendorId: string;
  vendorName: string;
  referenceId: string;
  total: number;
  billedTotal: number;
  billDate: string;
  deductions: number;
  distributedTotal: number;
  buyerFee: number;
  lineItems: OrderLineItemDbType[];
  buyerRate: string;
  markup: number;
  payoutId: string | null;
  payoutStatus: keyof typeof OrderPayoutStatus;
  vendorFee: number;
  vendorRate: string;
  serviceDate: string;
  updatedAt: string;
  createdAt: string;
  isFinanced: boolean;
};

export type OrderDetailDbType = {
  // Strings
  id: string;
  merchantId: string;
  createdAt: string;
  updatedAt: string;
  serviceDate: string;
  referenceId: string;
  buyerId: string;
  buyerName: string;
  vendorId: string;
  vendorName: string;
  payoutId: string;
  // Numbers
  total: number;
  buyerRate: string;
  buyerFee: number;
  vendorRate: string;
  vendorFee: number;
  markup: number;
  billedTotal: number;
  billDate: string;
  deductions: number;
  distributedTotal: number;
  // Other
  lineItems: OrderLineItemDbType[];
  payoutStatus: keyof typeof OrderPayoutStatus;
  status: keyof typeof OrderStatus;
  repaymentStatus: keyof typeof TransferStatus;
  isFinanced: boolean;
  payoutAmount: number;
  fees: number;
  debitTotal: number;
};

export type TransferDbType = {
  id: string;
  createdAt: string;
  sourceId: string;
  sourceType: TransferSourceType;
  destinationId: string;
  destinationType: TransferSourceType;
  type: TransferTypeEnum;
  method: TransferMethodEnum;
  status: TransferStatus;
  subtotal: number;
  amount: number;
  intentId: string;
  extId: string;
  processor: TransferPaymentProcessorEnum;
};

export type CardDbType = {
  id: string;
  companyName: string;
  last4: string;
  expDate: string;
};

export type VendorDbType = {
  id: string;
  totalResults: number;
  createdAt: string;
  updatedAt: string;
  bankAccount: string;
  referenceId: string;
  card: string;
  email: string;
  firstName: string;
  lastName: string;
  payoutBalance: number;
  phone: string;
  status: VendorKycStatus;
  cardExpiration: string | null;
  cardActive: boolean | null;
  cardDisabled: boolean | null;
  cardPushEnabled: boolean | null;
  bankAccountDisabled: boolean | null;
};

export type VendorType = "individual" | "business" | "entity";
export enum VendorAccountType {
  CARD = "card",
  BANK = "bank_account",
}

export type VendorInfoDbType = {
  id: string;
  updatedAt: string;
  type: VendorType;
  status: VendorKycStatus;
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  referenceId: string;
  address: AddressDbType;
  dob: string;
  accountLine_1: string;
  accountLine_2: string;
  payoutBalance: number;
  rejectionReasons: string[];
  cardExpiration: string;
  cardActive: boolean;
  cardDisabled: boolean;
  cardPushEnabled: boolean;
  bankAccountDisabled: boolean;
  accountType: VendorAccountType;
};
export type VendorInviteDbType = {
  link: string;
};
export type VendorUnpaidOrdersDbType = {
  id: string;
  payout: number;
};
export type VendorPayoutsDbType = {
  id: string;
  createdAt: string;
  status: keyof typeof OrderPayoutStatus;
  subtotal: number;
  fees: number;
  failureReason: string;
  method: string;
  estimatedClearingDate: string;
  amount: number;
};
export type VendorOrdersDbType = {
  id: string;
  createdAt: string;
  updatedAt: string;
  serviceDate: string;
  referenceId: string;
  hasPayout: boolean;
  total: number;
  buyerName: string;
  deductions: number;
  payout: number;
  status: OrderStatus;
};

export type ApiKeyDbType = {
  id: string;
  createdAt: string;
  updatedAt: string;
  merchantId: string;
  apiKey: string;
};

export type BankingAccountDbType = {
  id: string;
  createdAt: string;
  updatedAt: string;
  ownerId: string;
  active: boolean;
  deleted: boolean;
  isDefault: boolean;
  type: string;
  subType: string;
  institutionName: string;
  name: string;
  mask: string;
};
export type RoleType = "admin" | "editor" | "viewer";

export type TeamMemberDbType = {
  id: string;
  merchantId: string;
  createdAt: string;
  updatedAt: string;
  firstName: string;
  lastName: string;
  active: boolean;
  status: VendorKycStatus;
  email: string;
  role: RoleType;
  preferredTimezone: string;
  preferredLanguage: string;
  activity: string;
  phone: string;
};

export type UserStatusDbType = "pending" | "verified" | "inactive";
export type MerchantProfileDbType = {
  id: string;
  merchantId: string;
  createdAt: string;
  updatedAt: string;
  firstName: string;
  lastName: string;
  active: boolean;
  status: UserStatusDbType;
  verifiedOn: string;
  avatarPath: string;
  email: string;
  phone: string;
  role: RoleType;
  preferredTimezone: string;
  preferredLanguage: string;
  loggedInOn: string;
  loginIpAddress: string;
};

export type BulkPayoutInfoDbType = {
  vendorsCount: number;
  ordersCount: number;
  distributedTotal: number;
};

export type BulkPayoutResultError = {
  error: string;
  payoutBalance: number;
  vendorId: string;
  vendorName: string;
  referenceId: string;
};

export type BulkPayoutResultDbType = {
  actionSize: number;
  successCount: number;
  errors: BulkPayoutResultError[];
};
