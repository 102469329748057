import { Stack, Typography, Divider, ButtonBase } from "@mui/material";
import MainCard from "@pd/components/MainCard";
import { VendorUnpaidOrdersDbType } from "@pd/redux/types/dbTypes";
import { RightOutlined } from "@ant-design/icons";
import formatCurrency from "@pd/utils/formatCurrency";
import { useNavigate } from "react-router";

const w100 = { width: "100%" };
type Props = {
  unpaidOrders: VendorUnpaidOrdersDbType[];
};
export default function UnpaidOrdersCard(props: Props) {
  const navigate = useNavigate();

  const handleOnOrderClick = (id: string) => {
    navigate(`/dashboard/orders?id=${id}`);
  };

  if (!props.unpaidOrders.length) {
    return (
      <MainCard
        darkTitle
        title="Payable orders"
        sx={{
          mb: 5,
          ...w100,
        }}
      >
        <Typography>No payable orders</Typography>
      </MainCard>
    );
  }

  return (
    <MainCard
      darkTitle
      title="Payable orders"
      sx={{
        mb: 5,
        ...w100,
      }}
    >
      {props.unpaidOrders.map((pp, index, arr) => (
        <ButtonBase
          key={pp.id}
          sx={{ ...w100 }}
          onClick={() => handleOnOrderClick(pp.id)}
        >
          <Stack
            key={pp.id}
            sx={{ pb: 2, ...w100 }}
            justifyContent="space-between"
            gap={2}
          >
            <Stack
              gap={1}
              direction="row"
              justifyContent="space-between"
              sx={w100}
            >
              <Typography
                variant="h5"
                sx={{
                  direction: "rtl",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                }}
              >
                {`Order # ${pp.id}`}
              </Typography>
              <Stack gap={2} direction="row" alignItems="center">
                <Typography variant="h5">
                  {formatCurrency(pp.payout)}
                </Typography>
                <RightOutlined style={{ fontSize: "12px" }} />
              </Stack>
            </Stack>
            {index === arr.length - 1 ? null : <Divider />}
          </Stack>
        </ButtonBase>
      ))}
    </MainCard>
  );
}
