import { NavLink } from "react-router-dom";

import { AppBar, Stack, useTheme, Button } from "@mui/material";
import { selectProfile } from "@pd/redux/selectors/auth";

import MiscColors from "@pd/mantis/themes/miscColors";
import { useAppDispatch, useAppSelector } from "@pd/redux/store";
import authActions from "@pd/redux/actions/auth";
import WhiteLabelLogo from "./WhiteLabelLogo";
import NameBadge from "./NameBadge";

const miscColors = MiscColors();
function onNavLinkStyle({ isActive, ...rest }: { isActive: boolean }) {
  return {
    ...rest,
    color: isActive ? miscColors.primary : miscColors.secondary,
  };
}

export default function DashboardHeader() {
  const dispatch = useAppDispatch();
  const profile = useAppSelector(selectProfile);
  const theme = useTheme();

  return (
    <AppBar
      sx={{
        bgcolor: "white",
        color: theme.palette.text.primary,
        boxShadow: "none",
        px: "50px",
      }}
    >
      <Stack
        sx={{
          height: "80px",
          width: "100%",
          "& .header-link": {
            px: 1,
            "&:hover": { color: theme.palette.primary.main },
          },
        }}
        justifyContent="space-between"
        alignItems="center"
        direction="row"
      >
        <Stack direction="row" alignItems="center">
          <WhiteLabelLogo />
          <Stack sx={{ pl: 5 }} direction="row" gap={3}>
            <NavLink to="/dashboard/orders" style={onNavLinkStyle}>
              Orders
            </NavLink>
            <NavLink to="/dashboard/buyers" style={onNavLinkStyle}>
              Buyers
            </NavLink>
            <NavLink to="/dashboard/vendors" style={onNavLinkStyle}>
              Vendors
            </NavLink>
            {profile?.role !== "admin" ? null : (
              <NavLink
                to="/dashboard/settings"
                style={(p) => onNavLinkStyle(p)}
              >
                Settings
              </NavLink>
            )}
          </Stack>
        </Stack>
        <Stack direction="row" gap={2} alignItems="center">
          {profile ? (
            <NameBadge
              profile={profile}
              name={`${profile?.firstName} ${profile?.lastName}` || ""}
            />
          ) : null}
          <Button
            variant="text"
            onClick={() => dispatch(authActions.logout())}
            sx={{
              color: miscColors.blueText,
              width: "80px",
              height: "40px",
              "&:hover": { backgroundColor: theme.palette.secondary.lighter },
            }}
          >
            Logout
          </Button>
        </Stack>
      </Stack>
    </AppBar>
  );
}
