// eslint-disable-next-line no-shadow
export enum VendorType {
  individual = "individual",
  business = "business",
}

// eslint-disable-next-line no-shadow
export enum VendorKycStatus {
  approved = "approved",
  pending = "pending",
  suspended = "suspended",
  rejected = "rejected",
  manual_review = "manual_review",
  document = "document",
  unknown = "unknown",
}
