import { VendorDbType } from "@pd/redux/types/dbTypes";
import { VendorsTableDataType } from "../../../types";

export default function transformVendorTableData(
  vendors: VendorDbType[],
): VendorsTableDataType[] {
  const results = vendors.map((t) => ({
    id: t.id.toString(),
    vendor: `${t.firstName} ${t.lastName}`,
    account: t.card === "" ? t.bankAccount : t.card,
    referenceId: t.referenceId,
    phone: t.phone.toString(),
    email: t.email,
    kycStatus: t.status,
    hasCard: t.card !== "",
    cardActive: t.cardActive || false,
    cardDisabled: t.cardDisabled || false,
    cardPushEnabled: t.cardPushEnabled || false,
    cardExpiration: t.cardExpiration || "",
    bankAccountDisabled: t.bankAccountDisabled || false,
  }));
  return results;
}
