import { useEffect, useState } from "react";
import { Stack } from "@mui/material";
import { OrderCreatedDbType } from "@pd/redux/types/dbTypes";
import StitchAsyncButton from "@pd/components/StitchAsyncButton";
import { CreateOrderFormTypes } from "../../types";

type Props = {
  uiFetching: boolean;
  apiSuccess: boolean;
  apiOrder: OrderCreatedDbType | null;
  uiOrder: CreateOrderFormTypes | null;
  onSaveDraft: (uiOrder: CreateOrderFormTypes) => void;
  onConfirmAndSave: (uiOrder: CreateOrderFormTypes) => void;
  onCloseForm: () => void;
};
export default function SubmitButtons({
  uiFetching,
  apiSuccess,
  ...props
}: Props) {
  const [saveDraftFetching, setSaveDraftFetching] = useState(false);
  const [saveDraftSuccess, setSaveDraftSuccess] = useState(false);
  const [confirmOrderFetching, setConfirmOrderFetching] = useState(false);
  const [confirmOrderSuccess, setConfirmOrderSuccess] = useState(
    props.apiOrder?.status === "confirmed",
  );

  const disableConfirmOrder = (v: CreateOrderFormTypes | null) => {
    if (!v) {
      return true;
    }
    return [
      !v.buyerId,
      !v.vendorId,
      v.lineItems.length === 0,
      v.lineItems.some(
        (li: { description: string; amount: number }) =>
          !li.description || !li.amount,
      ),
    ].some(Boolean);
  };

  useEffect(() => {
    if (!uiFetching && saveDraftFetching) {
      setSaveDraftFetching(false);
    } else if (!uiFetching && confirmOrderFetching) {
      setConfirmOrderFetching(false);
    }
    if (apiSuccess && saveDraftFetching) {
      setSaveDraftSuccess(true);
      setTimeout(() => {
        setSaveDraftSuccess(false);
      }, 3000);
    }
    if (apiSuccess && confirmOrderFetching) {
      setConfirmOrderSuccess(true);
      props.onCloseForm();
    }
  }, [apiSuccess, uiFetching]);

  return (
    <Stack gap={1} direction="row" alignItems="center" justifyContent="center">
      <StitchAsyncButton
        buttonText="Save draft"
        variant="outlined"
        color="primary"
        logoColor="black"
        onClick={() => {
          if (props.uiOrder) {
            setSaveDraftFetching(true);
            props.onSaveDraft(props.uiOrder);
          }
        }}
        success={saveDraftSuccess}
        loading={saveDraftFetching}
        loadingSize="small"
        loadingPosition={{ top: -30, left: 0 }}
        disabled={[
          !props.uiOrder?.buyerId,
          !props.uiOrder?.vendorId,
          !props.uiOrder?.referenceId,
          !props.uiOrder?.billDate,
          props.apiOrder?.status === "confirmed",
        ].some(Boolean)}
        buttonWidth={"120px"}
      />
      <StitchAsyncButton
        buttonText="Save & confirm order"
        variant="contained"
        color="primary"
        logoColor="white"
        onClick={() => {
          if (props.uiOrder && props.uiOrder.status !== "confirmed") {
            setConfirmOrderFetching(true);
            props.onConfirmAndSave(props.uiOrder);
          }
        }}
        success={confirmOrderSuccess}
        loading={confirmOrderFetching}
        loadingSize="small"
        loadingPosition={{ top: -30, left: 0 }}
        disabled={disableConfirmOrder(props.uiOrder)}
        buttonWidth={"200px"}
      />
    </Stack>
  );
}
