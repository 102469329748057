import {
  Stack,
  Typography,
  FormControl,
  InputLabel,
  OutlinedInput,
} from "@mui/material";

type Props = {
  disableOnConfirmed: boolean;
  referenceId: string;
  onChange: (name: string, value: string) => void;
};
export default function ReferenceIdInput(props: Props) {
  return (
    <Stack spacing={1} sx={{ mt: "25px", pl: "65px" }}>
      <InputLabel htmlFor="referenceId">
        <Stack direction="row" alignItems="center" gap={0.5}>
          <Typography>Reference ID</Typography>
          <Typography variant="subtitle2" sx={{ pt: "6px" }}>
            *
          </Typography>
        </Stack>
      </InputLabel>
      <FormControl fullWidth>
        <OutlinedInput
          id="referenceId"
          name="referenceId"
          disabled={props.disableOnConfirmed}
          onChange={(e) => props.onChange("referenceId", e.target.value)}
          value={props.referenceId}
          placeholder="Enter a unique id"
        />
      </FormControl>
    </Stack>
  );
}
