import { createSelector } from "@reduxjs/toolkit";
import { ErrorPayload } from "@pd/api/utils/safeFetch";
import { AppStateType } from "@pd/redux/store/reducers";
import { BuyerDbType, BuyersSearchDbType } from "@pd/redux/types/dbTypes";
import { isDashboardState } from "../../../types";

export const selectAllBuyersNamesAndId = createSelector(
  (state: AppStateType) => state,
  (state): { id: string; name: string }[] => {
    if (isDashboardState(state)) {
      const data = state.dashboard.buyers.allBuyers.data.buyers;
      if (data) {
        return data.map((buyer: { id: string; name: string }) => ({
          id: buyer.id,
          name: buyer.name,
        }));
      }
    }
    return [];
  },
);

export const selectAllBuyers = createSelector(
  (state: AppStateType) => state,
  (state): BuyerDbType[] => {
    if (isDashboardState(state)) {
      const data = state.dashboard.buyers.allBuyers.data.buyers;
      if (data) {
        return data;
      }
    }
    return [];
  },
);

export const selectAllBuyersFiltered = createSelector(
  (state: AppStateType) => state,
  (state: AppStateType): BuyerDbType[] => {
    if (isDashboardState(state)) {
      const data = state.dashboard.buyers.allBuyers.data.filteredBuyers;
      if (data.length) return data;
    }
    return [];
  },
);

export const selectAllBuyersFetching = createSelector(
  (state: AppStateType) => state,
  (state: AppStateType): boolean => {
    if (isDashboardState(state)) {
      return state.dashboard.buyers.allBuyers.fetching;
    }
    return false;
  },
);

export const selectAllBuyersError = createSelector(
  (state: AppStateType) => state,
  (state: AppStateType): ErrorPayload => {
    if (isDashboardState(state)) {
      return state.dashboard.buyers.allBuyers.error;
    }
    return {
      message: "",
      status: 0,
    };
  },
);

export const selectAllBuyersSuccess = createSelector(
  (state: AppStateType) => state,
  (state: AppStateType): boolean => {
    if (isDashboardState(state)) {
      return state.dashboard.buyers.allBuyers.success;
    }
    return false;
  },
);

export const selectSearchedBuyers = createSelector(
  (state: AppStateType) => state,
  (state: AppStateType): BuyersSearchDbType[] => {
    if (isDashboardState(state)) {
      return state.dashboard.buyers.allBuyers.data.searchedBuyers;
    }
    return [];
  },
);
