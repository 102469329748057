import { AppStateType } from "@pd/redux/store/reducers";
import { createSelector } from "@reduxjs/toolkit";
import { VendorDbType, VendorsSearchDbType } from "@pd/redux/types/dbTypes";
import { isDashboardState } from "../../../types";

export const selectAllVendors = createSelector(
  (state: AppStateType) => state,
  (state: AppStateType): VendorDbType[] => {
    if (isDashboardState(state)) {
      const data = state.dashboard.vendors.all.data.allVendors;
      if (data) return data;
    }
    return [];
  },
);

export const selectAllVendorsFetching = createSelector(
  (state: AppStateType) => state,
  (state: AppStateType): boolean => {
    if (isDashboardState(state)) {
      return state.dashboard.vendors.all.fetching;
    }
    return false;
  },
);

export const selectAllVendorsError = createSelector(
  (state: AppStateType) => state,
  (state: AppStateType) => {
    if (isDashboardState(state)) {
      return state.dashboard.vendors.all.error;
    }
    return { message: "", status: 0 };
  },
);

export const selectAllVendorsSuccess = createSelector(
  (state: AppStateType) => state,
  (state: AppStateType): boolean => {
    if (isDashboardState(state)) {
      return state.dashboard.vendors.all.success;
    }
    return false;
  },
);

export const selectAllVendorsByNameAndId = createSelector(
  (state: AppStateType) => state,
  (state: AppStateType): { id: string; name: string }[] => {
    if (isDashboardState(state)) {
      const vendors = state.dashboard.vendors.all.data.allVendors;
      if (vendors) {
        return vendors.map((vendor) => {
          const data = {
            id: vendor.id,
            name:
              vendor.firstName && vendor.lastName
                ? `${vendor.firstName} ${vendor.lastName}`
                : vendor.firstName || vendor.lastName || "",
          };
          return data;
        });
      }
    }
    return [];
  },
);

export const selectAllVendorsFiltered = createSelector(
  (state: AppStateType) => state,
  (state: AppStateType): VendorDbType[] => {
    if (isDashboardState(state)) {
      const data = state.dashboard.vendors.all.data.filteredVendors;
      if (data) return data;
    }
    return [];
  },
);

export const selectSearchedVendors = createSelector(
  (state: AppStateType) => state,
  (state: AppStateType): VendorsSearchDbType[] => {
    if (isDashboardState(state)) {
      const data = state.dashboard.vendors.all.data.searchedVendors;
      if (data) return data;
    }
    return [];
  },
);
