import { Stack, useTheme, Button, Typography } from "@mui/material";
import formatCurrency from "@pd/utils/formatCurrency";
import formatNumbers from "@pd/utils/formatNumbers";
import { formatTimeWithFormat } from "@pd/utils/formatIsoTimeWithTz";
import { BulkPayoutInfoDbType } from "@pd/redux/types/dbTypes";

type Props = {
  bulkPayoutInfo: BulkPayoutInfoDbType;
  onConfirmPayout: () => void;
};
export default function PayoutDialogue(props: Props) {
  const theme = useTheme();

  return (
    <>
      <Stack alignItems="center" sx={{ width: "100%", px: 3, pt: 3 }} gap={3}>
        <Typography variant="h4">Review payout</Typography>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            width: "100%",
            px: "20px",
            pb: 3,
            borderBottom: `1px solid ${theme.palette.secondary.light}`,
          }}
          gap={2}
        >
          <Typography variant="h5">All unpaid</Typography>
          <Stack alignItems="flex-end">
            <Typography variant="h5">
              {formatCurrency(props.bulkPayoutInfo.distributedTotal)}
            </Typography>
            <Typography variant="h5" color="secondary">
              {formatNumbers(props.bulkPayoutInfo.ordersCount)} orders
            </Typography>
            <Typography variant="h5" color="secondary">
              {formatNumbers(props.bulkPayoutInfo.vendorsCount)} vendors
            </Typography>
          </Stack>
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            width: "100%",
            px: "20px",
          }}
          gap={2}
        >
          <Typography variant="h5">Payout date</Typography>
          <Stack alignItems="flex-end">
            <Typography variant="h5">Today</Typography>
            <Typography variant="h5" color="secondary">
              {formatTimeWithFormat(new Date().toISOString(), "PPP")}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      <Stack
        alignItems="center"
        gap={2}
        sx={{
          p: 3,
          width: "100%",
          borderTop: `1px solid ${theme.palette.secondary.light}`,
        }}
      >
        <Button
          variant="contained"
          onClick={() => props.onConfirmPayout()}
          disabled={
            !props.bulkPayoutInfo.distributedTotal ||
            props.bulkPayoutInfo.distributedTotal === 0
          }
          sx={{ width: "100%" }}
        >
          Initiate payout
        </Button>
      </Stack>
    </>
  );
}
