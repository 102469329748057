import { RoleSelectionType } from "../../../../types";

const roleTypes: RoleSelectionType[] = [
  {
    name: "admin",
  },
  {
    name: "editor",
  },
  {
    name: "viewer",
  },
];
export default roleTypes;
