import getUsTimeZones from "@pd/utils/getUsTimezones";

const usTimeZones = getUsTimeZones();

export function validateFirstName(value: string, cb: (error: string) => void) {
  // NOTE: Allow any alphanumeric, unicode basic Latin characters, unicode Latin extended-A, apostrophes ('), dashes (-), periods (.), and spaces.
  // See https://en.wikipedia.org/wiki/List_of_Unicode_characters#Latin_script
  const characterSet = "a-zA-Z0-9À-ÖØ-öø-ÿĀ-ſ";
  const nameRegex = new RegExp(`^[${characterSet}][${characterSet}' .-]+$`);
  if (!value) {
    cb("First Name is required");
  } else if (value && !nameRegex.test(value)) {
    cb("Invalid First Name");
  } else {
    cb("");
    return true;
  }
  return false;
}

export function validateLastName(value: string, cb: (error: string) => void) {
  // NOTE: Allow any alphanumeric, unicode basic Latin characters, unicode Latin extended-A, apostrophes ('), dashes (-), periods (.), and spaces.
  // See https://en.wikipedia.org/wiki/List_of_Unicode_characters#Latin_script
  const characterSet = "a-zA-Z0-9À-ÖØ-öø-ÿĀ-ſ";
  const nameRegex = new RegExp(`^[${characterSet}][${characterSet}' .-]+$`);
  if (!value) {
    cb("Last Name is required");
  } else if (value && !nameRegex.test(value)) {
    cb("Invalid Last Name");
  } else {
    cb("");
    return true;
  }
  return false;
}

export function validateEmail(
  value: string,
  cb: (error: string) => void,
): boolean {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/gim;
  if (!value) {
    cb("Email is required.");
  } else if (value && !emailRegex.test(value)) {
    cb("Invalid Email. Please use the format 'address@site.domain'");
  } else {
    cb("");
    return true;
  }
  return false;
}

export function validatePhone(
  value: string,
  cb: (error: string) => void,
): boolean {
  const phoneRegex = /^[0-9]{3}-[0-9]{3}-[0-9]{4}$/;
  if (!value) {
    cb("Phone number is required");
  } else if (value && !phoneRegex.test(value)) {
    cb("Invalid Phone number, please use the format 123-456-7890");
  } else {
    cb("");
    return true;
  }
  return false;
}

export function validateRole(value: string, cb: (error: string) => void) {
  const acctTypes = /^(admin|viewer|editor)$/i;
  if (!value) {
    cb("Role Type is required");
  } else if (value && !acctTypes.test(value)) {
    cb("Invalid Role Type. Please use 'admin', 'editor' or 'viewer'.");
  } else {
    cb("");
    return true;
  }
  return false;
}

export function validateTimezone(value: string, cb: (error: string) => void) {
  if (!value) {
    cb("Timezone is required");
  } else if (value && !usTimeZones.find((tz) => tz.name === value)) {
    cb("Invalid Timezone. Must be in the United States.");
  } else {
    cb("");
    return true;
  }
  return false;
}

export function validatePostalCode(value: string, cb: (error: string) => void) {
  if (!value) {
    cb("Postal code is required");
    return false;
  }
  const isValid = /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(value);
  if (isValid) {
    cb("");
    return true;
  }
  cb("Postal code must be a valid US ZIP code");
  return false;
}

export function validateReferenceId(
  value: string,
  cb: (error: string) => void,
) {
  const refIdChars = /[a-zA-Z0-9 -]+/;
  const refIdAll = /^[0-9a-zA-Z]+[a-zA-Z0-9 -]*[0-9a-zA-Z]+$/;
  if (!value) {
    cb("Reference id is required");
  } else if (value && !refIdChars.test(value)) {
    cb("Invalid Reference id. Should contain letters and/or numbers.");
  } else if (value && value.length < 2) {
    cb("Reference id must be at least 2 characters");
  } else if (value && !refIdAll.test(value)) {
    cb("Invalid Reference id");
  } else {
    cb("");
    return true;
  }
  return false;
}

export function generateNotEmptyValidator(emptyErrorMsg: string) {
  return (value: string, cb: (error: string) => void) => {
    if (!value) {
      cb(emptyErrorMsg);
      return false;
    }
    cb("");
    return true;
  };
}
