// import MktplaceActivity from "@pd/layouts/MktplaceDashboard/pages/TransferActivity"; // TODO: Wait for transfers backend before enabling
import Orders from "@pd/layouts/MktplaceDashboard/pages/Orders";
import Buyers from "@pd/layouts/MktplaceDashboard/pages/Buyers";
import Vendors from "@pd/layouts/MktplaceDashboard/pages/Vendors";
import VendorDetails from "@pd/layouts/MktplaceDashboard/pages/Vendors/VendorDetails";
import Settings from "@pd/layouts/MktplaceDashboard/pages/Settings";
import Home from "@pd/layouts/MktplaceDashboard/pages/Home";
import ErrorBoundary from "@pd/components/ErrorBoundary";
import Dashboard from "./index";

export default {
  path: "/",
  element: <Dashboard />,
  errorElement: <ErrorBoundary fallback />,
  children: [
    {
      path: "/",
      element: <Home />,
      errorElement: <ErrorBoundary fallback />,
    },
    {
      path: "dashboard",
      element: <Dashboard />,
      children: [
        //  TODO: Wait for transfers backend before enabling
        // {
        //   path: "activity",
        //   element: <MktplaceActivity />,
        // },
        {
          path: "orders",
          element: <Orders />,
          errorElement: <ErrorBoundary fallback />,
        },
        {
          path: "buyers",
          element: <Buyers />,
          errorElement: <ErrorBoundary fallback />,
        },
        {
          path: "vendors",
          element: <Vendors />,
          errorElement: <ErrorBoundary fallback />,
          children: [
            {
              path: ":vendorId",
              element: <VendorDetails />,
              errorElement: <ErrorBoundary fallback />,
            },
          ],
        },
        {
          path: "settings",
          element: <Settings />,
          errorElement: <ErrorBoundary fallback />,
        },
      ],
    },
  ],
};
