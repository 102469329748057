import { Outlet, useParams } from "react-router";
import { Stack, Typography } from "@mui/material";
import AuthGuard from "@pd/components/AuthGuard";

import { FadeInStack } from "@pd/components/FadeComponents";
import VendorsTable from "./VendorsTable";

export default function Vendors() {
  const params = useParams<{ vendorId: string }>();

  const renderSubview = () => {
    if (params.vendorId) {
      return <Outlet />;
    }
    return (
      <>
        <Stack
          direction={{
            lg: "row",
            xs: "column",
          }}
          justifyContent="flex-start"
          alignItems="flex-start"
          sx={{ mt: "30px", mb: "60px", width: "100%" }}
          spacing={2}
        >
          <Typography
            variant="h1"
            sx={{ fontWeight: 500, fontFamily: "CircularBold" }}
          >
            Vendors
          </Typography>
        </Stack>
        <Stack sx={{ width: "100%" }} data-testid="vendors-table">
          <VendorsTable />
        </Stack>
      </>
    );
  };

  return (
    <AuthGuard>
      <FadeInStack
        alignItems="flex-start"
        sx={{
          pt: "80px",
          px: "50px",
          width: "100%",
          height: "100%",
        }}
      >
        {renderSubview()}
      </FadeInStack>
    </AuthGuard>
  );
}
