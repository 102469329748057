import { useState, useEffect } from "react";
import {
  Stack,
  Typography,
  FormControl,
  InputLabel,
  Autocomplete,
  TextField,
} from "@mui/material";
import StitchLoading from "@pd/components/StitchLoading";
import { useAppSelector, useAppDispatch } from "@pd/redux/store";
import { selectAllVendorsFetching } from "@pd/layouts/MktplaceDashboard/redux/selectors/vendors/allVendors";
import da from "@pd/layouts/MktplaceDashboard/redux/actions";

type Props = {
  vendorId: string;
  vendorName: string;
  vendors: { id: string; name: string }[];
  disableOnConfirmed: boolean;
  onSearchVendors: (query: string) => void;
  onChange: (name: string, value: string) => void;
};

export default function VendorSelection(props: Props) {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const [uiFetching, setUiFetching] = useState(false);
  const apiFetching = useAppSelector(selectAllVendorsFetching);
  const [selectedVendorName, setSelectedVendorName] = useState(
    props.vendorName,
  );

  useEffect(() => {
    if (!apiFetching && uiFetching) {
      setUiFetching(false);
    }
  }, [apiFetching]);

  useEffect(
    () => () => {
      dispatch(da.vendors.all.setSearchedVendors([]));
    },
    [],
  );

  return (
    <Stack spacing={1} sx={{ mt: "20px", pl: "65px" }}>
      <InputLabel htmlFor="vendorId">
        <Stack direction="row" alignItems="center" gap={0.5}>
          <Typography>Vendors</Typography>
          <Typography variant="subtitle2" sx={{ pt: "6px" }}>
            *
          </Typography>
        </Stack>
      </InputLabel>
      <FormControl fullWidth>
        <Autocomplete
          disabled={props.disableOnConfirmed}
          id="vendorId"
          open={open}
          loading={uiFetching}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          onBlur={() => {
            if (!props.vendorId) {
              dispatch(da.vendors.all.setSearchedVendors([]));
              props.onChange("vendorId", "");
              setUiFetching(false);
            }
          }}
          onChange={(_, value, reason) => {
            if (reason === "clear") {
              props.onChange("vendorId", "");
              setSelectedVendorName("");
            } else if (reason === "selectOption") {
              if (value && value.id) {
                props.onChange("vendorId", value.id);
              }
              if (value && value.name) {
                setSelectedVendorName(value.name);
              }
            }
          }}
          onInputChange={(e, value, reason) => {
            e?.preventDefault();
            if (reason === "input") {
              props.onSearchVendors(value);
              setSelectedVendorName(value);
              if (value.length > 0) {
                setUiFetching(true);
              } else {
                dispatch(da.vendors.all.setSearchedVendors([]));
                props.onChange("vendorId", "");
                setUiFetching(false);
              }
            } else if (e && ["click"].includes(e.type) && value) {
              const targetVendor = props.vendors.find((v) => v.name === value);
              if (targetVendor) {
                props.onChange("vendorId", targetVendor.id);
                setSelectedVendorName(value);
              }
            }
          }}
          value={
            props.vendors.find((vendor) => vendor.id === props.vendorId) || null
          }
          inputValue={selectedVendorName}
          options={props.vendors}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          getOptionLabel={(option) => option.name}
          noOptionsText={
            uiFetching ? (
              <StitchLoading size="medium" />
            ) : (
              <Stack gap={1} sx={{ width: "100%" }} alignItems="center">
                <Typography variant="h6">Search Vendors</Typography>
                <Typography variant="subtitle2">
                  Type a {"vendor's"}{" "}
                  <span style={{ fontFamily: "CircularBold" }}>name</span> to
                  populate list.
                </Typography>
              </Stack>
            )
          }
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={
                props.vendors.length ? "Select a Vendor" : "Search Vendors"
              }
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {uiFetching ? (
                      <StitchLoading size="small" position={{ top: -29 }} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
          renderOption={(props, option) => (
            <li {...props} key={option.id}>
              {option.name}
            </li>
          )}
        />
      </FormControl>
    </Stack>
  );
}
