import { Stack, Box, Tab, Tabs, useTheme } from "@mui/material";

import TabPanel from "@pd/components/TabPanel";
import a11yProps from "@pd/components/TabPanel/a11yProps";

import { useAppSelector } from "@pd/redux/store";
import { selectVendorCrudById } from "@pd/layouts/MktplaceDashboard/redux/selectors/vendors/crudVendors";
import PayoutHistoryTab from "./components/PayoutsHistoryTab";
import OrderHistoryTab from "./components/OrderHistoryTab";

type Props = {
  tabValue: number;
  onTabChange: (event: React.SyntheticEvent, newValue: number) => void;
};
export default function VendorTableTabs(props: Props) {
  const theme = useTheme();
  const vendor = useAppSelector(selectVendorCrudById);

  return (
    <Stack sx={{ width: "70%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          variant="fullWidth"
          value={props.tabValue}
          onChange={props.onTabChange}
          aria-label="vendor table tabs"
        >
          <Tab
            sx={{
              display: "flex",
              fontSize: "14px",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              textTransform: "none",
              fontFamily:
                props.tabValue === 0 ? "CircularBold" : "CircularRegular",
            }}
            label="Payout history"
            {...a11yProps(0)}
          />
          <Tab
            sx={{
              display: "flex",
              fontSize: "14px",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              textTransform: "none",
              fontFamily:
                props.tabValue === 1 ? "CircularBold" : "CircularRegular",
            }}
            label="Order history"
            {...a11yProps(1)}
          />
        </Tabs>
      </Box>
      <TabPanel value={props.tabValue} index={0} dir={theme.direction}>
        <PayoutHistoryTab vendorId={vendor?.id} />
      </TabPanel>
      <TabPanel value={props.tabValue} index={1} dir={theme.direction}>
        <OrderHistoryTab vendorId={vendor?.id} />
      </TabPanel>
    </Stack>
  );
}
