import { OrderDbType } from "@pd/redux/types/dbTypes";
import { OrdersForTableType } from "@pd/layouts/MktplaceDashboard/pages/Orders/types";

export default function zipSelectedOrders(
  orders: OrderDbType[],
  selected: string[],
): OrdersForTableType[] {
  return orders.map((order) => {
    const isSelected = selected.includes(order.id);
    return {
      ...order,
      selected: isSelected,
    };
  });
}
