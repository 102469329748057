export default function formatTimestamp(mysqlTimestamp: string) {
  try {
    if (!mysqlTimestamp) return "";
    const timestamp = new Date(mysqlTimestamp);
    if (Number.isNaN(timestamp.getTime())) {
      throw new Error("Invalid timestamp");
    }
    const today = new Date();
    const isToday = timestamp.toDateString() === today.toDateString();
    const options = { hour: "numeric", minute: "2-digit", hour12: true };
    const timeString = timestamp
      .toLocaleTimeString("en-US", options as any)
      .toLowerCase();
    const result = isToday
      ? `Today, ${timeString}`
      : timestamp.toLocaleDateString("en-US", options as any);
    return result;
  } catch (error) {
    console.error(error);
    return "Invalid date";
  }
}
