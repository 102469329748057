import {
  Stack,
  Typography,
  FormControl,
  InputLabel,
  OutlinedInput,
  useTheme,
} from "@mui/material";
import { useState } from "react";

type Props = {
  keyName: string;
  value?: string;
  label: string;
  placeholder: string;
  disabled?: boolean;
  required?: boolean;
  onChange: (keyName: string, value: string, isValid: boolean) => void;
  validator?: (
    value: string,
    errorMsgHandler: (errorMsg: string) => void,
  ) => boolean;
};

export default function StringInputSection({
  disabled = false,
  required = false,
  value = "",
  validator = undefined,
  ...props
}: Props) {
  const [showError, setShowError] = useState(false);
  const [validationError, setValidationError] = useState("");
  const theme = useTheme();

  const handleOnChange = (newVal: string) => {
    const isValid = !validator || validator(newVal, (_) => {});
    props.onChange(props.keyName, newVal, isValid);
  };

  const handleOnBlur = () => {
    if (!value) {
      if (required) {
        setValidationError("This field is required");
        setShowError(true);
      }
      return;
    }
    if (!validator) {
      setValidationError("");
      setShowError(false);
      return;
    }
    const valid = validator(value, (errorMsg) => {
      setValidationError(errorMsg);
    });
    setShowError(!valid);
  };

  return (
    <Stack
      spacing={1}
      sx={{
        width: "100%",
      }}
    >
      <InputLabel htmlFor={`outlinedInput-${props.keyName}`}>
        <Stack direction="row" alignItems="center" gap={0.5}>
          <Typography>{props.label}</Typography>
          {required && (
            <Typography variant="subtitle2" sx={{ pt: "6px" }}>
              *
            </Typography>
          )}
        </Stack>
      </InputLabel>
      <FormControl fullWidth>
        <OutlinedInput
          id={`outlinedInput-${props.keyName}`}
          name={`outlinedInput-${props.keyName}`}
          disabled={disabled}
          onChange={(e) => handleOnChange(e.target.value)}
          onBlur={handleOnBlur}
          value={value}
          placeholder={props.placeholder}
          style={{
            border: `1px solid ${
              showError
                ? theme.palette.error.main
                : theme.palette.secondary.light
            }`,
          }}
        />
      </FormControl>
      <Typography
        variant="subtitle1"
        color="error"
        sx={{
          pt: "5px",
          pl: "10px",
          display: showError ? "block" : "none",
          lineHeight: "16px",
        }}
      >
        {validationError}
      </Typography>
    </Stack>
  );
}
