import {
  all,
  call,
  put,
  takeLeading,
  // takeLatest,
  delay,
} from "redux-saga/effects";
import {
  DashboardBuyersCreateResType,
  fetchCreateBuyer,
} from "@pd/api/dashboard/buyers";
import { MANUAL_ERROR_CODE } from "@pd/utils/constants";
import da from "../../actions";

export default function* crudBuyerSagas() {
  yield all([
    takeLeading(da.buyers.crud.createBuyer.toString(), onCreateBuyer),
    // takeLatest(da.buyers.crud.getApiOrder.toString(), onGetApiBuyer),
    // takeLatest(da.buyers.crud.editBuyer.toString(), onEditBuyer),
  ]);
}

function* onCreateBuyer(action: ReturnType<typeof da.buyers.crud.createBuyer>) {
  try {
    yield all([
      put(da.buyers.crud.apiFetching(true)),
      put(da.buyers.crud.apiSuccess(false)),
      put(da.buyers.crud.apiError({ message: "", status: 0 })),
    ]);
    const res: DashboardBuyersCreateResType = yield call(
      fetchCreateBuyer,
      action.payload.buyer,
    );
    yield delay(400);
    if ("error" in res) {
      yield all([
        put(da.buyers.crud.apiError(res.error)),
        put(da.buyers.crud.apiSuccess(false)),
        put(da.buyers.crud.apiFetching(false)),
      ]);
    } else {
      yield all([
        put(da.buyers.crud.setBuyerApiData(res.data)),
        put(da.buyers.crud.apiFetching(false)),
        put(da.buyers.crud.apiSuccess(true)),
      ]);
    }
  } catch (error: unknown) {
    const errMsg =
      "An error occurred while creating the buyer. Please try again later.";
    if (error instanceof Error) {
      console.error(error.message);
    }
    yield all([
      put(
        da.buyers.crud.apiError({ message: errMsg, status: MANUAL_ERROR_CODE }),
      ),
      put(da.buyers.crud.apiFetching(false)),
      put(da.buyers.crud.apiSuccess(false)),
    ]);
  }
}
