import { useState } from "react";
import { SendOutlined } from "@ant-design/icons";
import { Divider, Stack, Typography, Button } from "@mui/material";
import StatusCell from "@pd/components/DynamicTable/StatusCell";
import ExpiredLabel from "@pd/components/ExpiredLabel";
import IneligibleLabel from "@pd/components/IneligibleLabel";
import MainCard from "@pd/components/MainCard";
import { VendorAccountType, VendorInfoDbType } from "@pd/redux/types/dbTypes";
import formatPhone from "@pd/utils/formatPhone";
import SendLoginLinkModal from "@pd/components/DynamicTable/SendLoginLinkModal";
import { isVendorCardIneligible } from "@pd/utils/cards";
import isCardExpired from "@pd/utils/isCardExpired";
import CopyIdButton from "@pd/components/CopyIdButton";

const w100 = { width: "100%" };

type Props = {
  vendor: VendorInfoDbType | null;
};
export default function VendorDetailsCard(props: Props) {
  const [showSendLinkModal, setShowSendLinkModal] = useState(false);
  const cardIneligible =
    props.vendor != null && isVendorCardIneligible(props.vendor);
  const cardExpired =
    props.vendor?.cardExpiration != null &&
    isCardExpired(props.vendor?.cardExpiration);

  if (!props.vendor) {
    return null;
  }

  return (
    <MainCard
      darkTitle
      title="Vendor details"
      sx={{
        mb: 5,
        ...w100,
      }}
    >
      <Stack
        sx={{
          ...w100,
        }}
        justifyContent="space-between"
        alignItems={{
          xs: "flex-start",
          md: "flex-start",
        }}
        gap={2}
      >
        <Stack gap={1} direction="row" justifyContent="space-between" sx={w100}>
          <Typography variant="h5" color="secondary">
            Full name
          </Typography>
          <Stack direction="row" gap={1}>
            <Typography variant="h5">{props.vendor.firstName}</Typography>
            <Typography variant="h5">{props.vendor.lastName}</Typography>
          </Stack>
        </Stack>
        <Divider sx={w100} />
        <Stack gap={1} direction="row" justifyContent="space-between" sx={w100}>
          <Typography variant="h5" color="secondary">
            ID
          </Typography>
          <Stack direction="row" gap={1}>
            <Stack sx={{ width: "127px" }}>
              <CopyIdButton id={props.vendor.id} />
            </Stack>
          </Stack>
        </Stack>
        <Divider sx={w100} />
        <Stack gap={1} direction="row" justifyContent="space-between" sx={w100}>
          <Typography variant="h5" color="secondary">
            Reference ID
          </Typography>
          <Stack direction="row" gap={1}>
            <Stack sx={{ width: "127px" }} alignItems="flex-end">
              <CopyIdButton id={props.vendor.referenceId} />
            </Stack>
          </Stack>
        </Stack>
        <Divider sx={w100} />

        <Stack gap={1} direction="row" justifyContent="space-between" sx={w100}>
          <Typography variant="h5" color="secondary">
            Phone number
          </Typography>
          <Typography variant="h5">
            {formatPhone(props.vendor.phone)}
          </Typography>
        </Stack>
        <Divider sx={w100} />
        <Stack gap={1} direction="row" justifyContent="space-between" sx={w100}>
          <Typography variant="h5" color="secondary">
            Email
          </Typography>
          <Typography variant="h5">{props.vendor.email}</Typography>
        </Stack>
        <Divider sx={w100} />
        <Stack gap={1} direction="row" justifyContent="space-between" sx={w100}>
          <Typography variant="h5" color="secondary">
            Date of birth
          </Typography>
          <Typography variant="h5">
            {formatBirthday(props.vendor.dob)}
          </Typography>
        </Stack>
        <Divider sx={w100} />
        <Stack
          gap={1}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={w100}
        >
          <Typography variant="h5" color="secondary">
            Address
          </Typography>
          <Stack>
            <Typography variant="h5" textAlign="right">
              {props.vendor.address.line1}
            </Typography>
            <Typography variant="h5" textAlign="right">
              {props.vendor.address.line2}
            </Typography>
            <Typography variant="h5" textAlign="right">
              {`${props.vendor.address.city}, ${props.vendor.address.state} ${props.vendor.address.postalCode}`}
            </Typography>
          </Stack>
        </Stack>
        <Divider sx={w100} />
        <Stack
          gap={1}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={w100}
        >
          <Typography variant="h5" color="secondary">
            Account
          </Typography>
          <Stack gap={1} direction="row">
            {props.vendor.accountType === VendorAccountType.CARD && (
              <Stack gap={1}>
                <IneligibleLabel show={cardIneligible} />
                <ExpiredLabel show={!cardIneligible && cardExpired} />
              </Stack>
            )}
            {props.vendor.accountType === VendorAccountType.BANK && (
              <Stack gap={1}>
                <IneligibleLabel show={props.vendor.bankAccountDisabled} />
              </Stack>
            )}
            <Typography variant="h5" textAlign="right">
              {props.vendor.accountLine_1}
              <br />
              {props.vendor.accountLine_2}
            </Typography>
          </Stack>
        </Stack>
        <Divider sx={w100} />
        <Stack
          gap={1}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={w100}
        >
          <Typography variant="h5" color="secondary">
            Status
          </Typography>
          <Typography variant="h5" textAlign="right">
            <StatusCell status={props.vendor.status} type="vendors" />
          </Typography>
        </Stack>
        <Divider sx={w100} />
        <Stack
          gap={1}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={w100}
        >
          <Typography variant="h5" color="secondary">
            Invite link
          </Typography>
          <Button
            size="small"
            variant="contained"
            onClick={() => setShowSendLinkModal(true)}
          >
            <Typography variant="body2" sx={{ marginRight: 1 }}>
              Send login link
            </Typography>
            <SendOutlined style={{ fontSize: "16px" }} />
          </Button>
        </Stack>
      </Stack>
      {showSendLinkModal ? (
        <SendLoginLinkModal
          firstName={props.vendor.firstName}
          email={props.vendor.email}
          vendorId={props.vendor.id}
          onClose={() => setShowSendLinkModal(false)}
        />
      ) : null}
    </MainCard>
  );
}

function formatBirthday(birthday: string) {
  return birthday.replace(/(\d{4})-(\d{2})-(\d{2}).*/, "$2 / $3 / $1");
}
