type Props = {
  marginLeft?: number;
  marginRight?: number;
};
export default function BlackDot(props: Props) {
  const { marginLeft = 1, marginRight = 1 } = props;
  return (
    <span
      style={{
        display: "inline-block",
        borderRadius: "10px",
        border: "3px solid black",
        height: "3px",
        marginBottom: "2px",
        marginLeft: `${marginLeft}px`,
        marginRight: `${marginRight}px`,
      }}
    />
  );
}
