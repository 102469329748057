import { useState, useEffect, useMemo } from "react";
import {
  Snackbar,
  Alert,
  Link,
  useTheme,
  Stack,
  Typography,
} from "@mui/material";
import { WarningOutlined, ExportOutlined } from "@ant-design/icons";
import da from "@pd/layouts/MktplaceDashboard/redux/actions";
import {
  selectSettingsError,
  selectSettingsFetching,
  selectSettingsApiKeys,
} from "@pd/layouts/MktplaceDashboard/redux/selectors/settings";
import StitchLoading from "@pd/components/StitchLoading";
import { useAppDispatch, useAppSelector } from "@pd/redux/store";

import MainCard from "@pd/components/MainCard";
import { isProdEnv, isSandboxEnv, isStagingEnv } from "@pd/utils/envCheck";
import LabeledKeyRow from "./components/LabeledKeyRow";

export default function ApiKeysTable() {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const apiError = useAppSelector(selectSettingsError);
  const apiFetching = useAppSelector(selectSettingsFetching);
  const apiKeys = useAppSelector(selectSettingsApiKeys);

  const [uiFetching, setUiFetching] = useState(false);
  const [showCopyMsg, setShowCopyMsg] = useState(false);

  const { status: errorStatusCode } = apiError;

  const environmentLabel = (() => {
    if (isProdEnv()) {
      return "Production";
    }
    if (isSandboxEnv()) {
      return "Sandbox";
    }
    if (isStagingEnv()) {
      return "Staging";
    }
    return "Development";
  })();

  useEffect(() => {
    setUiFetching(true);
    dispatch(da.settings.getApiKeys());
  }, []);

  useEffect(() => {
    if (!apiFetching && uiFetching) {
      setUiFetching(false);
    } else if (errorStatusCode && uiFetching) {
      setUiFetching(false);
    }
  }, [apiFetching, errorStatusCode, uiFetching]);

  if (apiKeys.length === 0) {
    console.error("No API keys found.");
  }
  const merchantId = apiKeys.length ? apiKeys[0].merchantId : "";
  const rows = apiKeys || [];

  async function copyKeyToClipboard(key: string) {
    try {
      Promise.resolve().then(async () => {
        navigator.clipboard.writeText(key);
        setShowCopyMsg(true);
      });
    } catch (err) {
      console.error("Failed to copy id: ", err);
    }
  }

  const keysHeader = useMemo(
    () => (
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" gap={1}>
          <Typography variant="h5" sx={{ fontFamily: "CircularBold" }}>
            Keys
          </Typography>
        </Stack>
        <Link
          href="https://docs.withstitch.com/"
          underline="none"
          target="_blank"
          lineHeight="36px"
        >
          Learn more in the docs &nbsp;
          <ExportOutlined />
        </Link>
      </Stack>
    ),
    [apiFetching],
  );

  if (uiFetching) {
    return (
      <MainCard
        darkTitle
        title={keysHeader}
        sx={{
          mb: 5,
          width: "100%",
          minHeight: "200px",
        }}
      >
        <Stack
          sx={{ width: "100%" }}
          alignItems="center"
          justifyContent="center"
        >
          <StitchLoading size="medium" />
        </Stack>
      </MainCard>
    );
  }
  return (
    <MainCard
      darkTitle
      title={keysHeader}
      sx={{
        mb: 5,
        width: "100%",
        minHeight: "200px",
        display: apiKeys.length ? "block" : "none",
      }}
    >
      <Stack spacing={3}>
        <LabeledKeyRow
          label="merchant_id"
          value={merchantId}
          showCopyButton
          useVisibilityToggle={false}
          onCopyClicked={(value) => {
            copyKeyToClipboard(value);
          }}
        />

        <Typography variant="h4">Secrets</Typography>

        <MainCard>
          <Stack direction="row" spacing={2}>
            <WarningOutlined
              style={{
                fontSize: "18px",
                paddingTop: "3px",
              }}
            />
            <Typography>
              Never share your secret keys in plaintext with anyone, including
              Stitch employees.
            </Typography>
          </Stack>
        </MainCard>

        {rows.map((row, index) => (
          <LabeledKeyRow
            label={environmentLabel}
            value={row.apiKey}
            key={`${row.apiKey}-${index}`} /* eslint-disable-line react/no-array-index-key */
            showCopyButton
            useVisibilityToggle
            onCopyClicked={(value) => {
              copyKeyToClipboard(value);
            }}
          />
        ))}

        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          open={showCopyMsg}
          autoHideDuration={6000}
          onClose={() => setShowCopyMsg(false)}
          sx={{ mb: 5 }}
        >
          <Alert
            onClose={() => setShowCopyMsg(false)}
            severity="info"
            variant="filled"
            sx={{ width: "100%", backgroundColor: theme.palette.primary.main }}
          >
            Copied to clipboard!
          </Alert>
        </Snackbar>
      </Stack>
    </MainCard>
  );
}
