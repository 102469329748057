import { call, put, delay, all } from "redux-saga/effects";
import {
  fetchTransfersByFilter,
  TransfersResType,
} from "@pd/api/dashboard/transfers";

import da from "../../actions";
import { TableAllFiltersType } from "../../../types/tables";

export default function* transfersSagas() {
  yield all([]);
}

/**
 * This function is used to get orders by filter. It's called from the Table
 * Saga (actions.table.getTableData) as a helper function. The Table Saga is responsible for setting the
 * table filters and handling async state (fetching, error, etc.). This function
 * is only responsible for fetching the orders by filter and returning the
 * response.
 * @returns {TransfersResType} - The response from the API
 */
export function* handleGetTableTransfers(
  filters: TableAllFiltersType,
): Generator<any, string, any> {
  try {
    yield all([
      put(da.transfers.apiFetching(true)),
      put(da.transfers.apiSuccess(false)),
      put(da.transfers.apiError({ message: "", status: 0 })),
    ]);
    const res: TransfersResType = yield call(fetchTransfersByFilter, filters);
    if ("error" in res) {
      yield all([
        put(da.transfers.apiFetching(false)),
        put(da.transfers.apiError(res.error)),
        put(da.transfers.apiSuccess(false)),
      ]);
    } else {
      yield put(da.transfers.setAllTransfers(res.data.transfers));
      yield delay(400);
      yield all([
        put(da.transfers.apiSuccess(true)),
        put(da.transfers.apiFetching(false)),
      ]);
    }
    return "";
  } catch (error: unknown) {
    const errMsg = "An error occurred while fetching table orders.";
    if (error instanceof Error) {
      console.error(error.message);
    }
    return errMsg;
  }
}
