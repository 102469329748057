import { Stack, Typography, useTheme } from "@mui/material";
import formatCurrency from "@pd/utils/formatCurrency";

type Props = {
  total: number;
};
export default function BaseTotal(props: Props) {
  const theme = useTheme();

  return (
    <Stack
      sx={{
        mt: "25px",
        mb: "70px",
        pt: "25px",
        ml: "65px",
        borderTop: `1px solid ${theme.palette.secondary.lighter}`,
      }}
      direction="row"
      justifyContent="space-between"
    >
      <Typography variant="h5" sx={{ fontFamily: "CircularBold" }}>
        Base Total
      </Typography>
      <Typography variant="h5">{formatCurrency(props.total)}</Typography>
    </Stack>
  );
}
