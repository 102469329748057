import { Stack, useTheme, Typography } from "@mui/material";
import formatCurrency from "@pd/utils/formatCurrency";

type Props = {
  buyerMarkup: number;
  buyerTotal: number;
};
export default function BuyerBilling(props: Props) {
  const theme = useTheme();

  return (
    <Stack spacing={1} sx={{ mt: "25px", pl: "65px" }}>
      <Stack
        gap={1}
        direction="row"
        sx={{
          mt: 1,
          borderBottom: `1px solid ${theme.palette.secondary.light}`,
          pb: 1,
        }}
        alignItems="flex-end"
      >
        <Typography variant="h5" sx={{ flexGrow: 1 }}>
          Buyer Markup
        </Typography>
        <Stack direction="row">
          <Typography variant="h5">
            {formatCurrency(props.buyerMarkup)}
          </Typography>
        </Stack>
      </Stack>
      <Stack sx={{ pt: 1 }} direction="row" justifyContent="space-between">
        <Typography variant="h5">Total billed to buyer</Typography>
        <Typography variant="h5">{formatCurrency(props.buyerTotal)}</Typography>
      </Stack>
    </Stack>
  );
}
