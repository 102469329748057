import { Stack, Typography, useTheme } from "@mui/material";

export default function RequiredFieldsMessage() {
  const theme = useTheme();

  return (
    <Stack sx={{ mb: 4, pl: "65px" }}>
      <Typography
        variant="subtitle2"
        sx={{ mt: "25px", color: theme.palette.secondary.main }}
      >
        * Required fields to {'"Save Draft"'}
      </Typography>
      <Typography
        variant="subtitle2"
        sx={{ color: theme.palette.secondary.main }}
      >
        ** Required fields to {'"Confirm Order"'}
      </Typography>
    </Stack>
  );
}
