import { Stack, Typography, useTheme } from "@mui/material";
import { CheckCircleOutline } from "@mui/icons-material";
import { CloseCircleOutlined } from "@ant-design/icons";
import { ErrorPayload } from "@pd/api/utils/safeFetch";

type Props = {
  draftSuccess: boolean;
  confirmedSuccess: boolean;
  loading: boolean;
  apiError: ErrorPayload;
};
export default function StatusMessage(props: Props) {
  const theme = useTheme();

  return (
    <Stack
      justifyContent="flex-start"
      sx={{ mt: "50px", minHeight: "142px" }}
      gap={3}
    >
      <Stack justifyContent="center" alignItems="center" sx={{ mb: "25px" }}>
        <Typography align="center" variant="subtitle1">
          Save Draft to continue editing your order.
          <br />
          When ready, Confirm the order to finalize terms. Your vendor’s balance
          will update immediately.
        </Typography>
      </Stack>
      <Stack
        direction="row"
        gap={1}
        sx={{
          display: props.draftSuccess ? "flex" : "none",
          justifyContent: "center",
          alignItems: "center",
          mb: 3,
          minHeight: "25px",
        }}
      >
        <CheckCircleOutline style={{ color: theme.palette.success.darker }} />
        <Typography
          variant="subtitle1"
          sx={{ color: theme.palette.success.darker }}
        >
          Draft Saved!
        </Typography>
      </Stack>
      <Stack
        direction="row"
        gap={1}
        sx={{
          display: props.confirmedSuccess ? "flex" : "none",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography variant="h4" sx={{ color: theme.palette.success.darker }}>
          Order Saved & Confirmed!&nbsp;
        </Typography>
      </Stack>
      <Stack
        direction="row"
        gap={1}
        sx={{
          display: props.apiError.message && !props.loading ? "flex" : "none",
          justifyContent: "center",
          alignItems: "center",
          mb: 3,
        }}
      >
        <CloseCircleOutlined style={{ color: theme.palette.error.main }} />
        <Typography
          variant="subtitle1"
          sx={{ color: theme.palette.error.main }}
        >
          {props.apiError.message ||
            "There was an issue with your order details. Please try again."}
        </Typography>
      </Stack>
    </Stack>
  );
}
