import {
  Typography,
  InputLabel,
  OutlinedInput,
  Grid,
  Stack,
  useTheme,
  Autocomplete,
  TextField,
} from "@mui/material";
import { defaultAddress, AddressDbType } from "@pd/redux/types/dbTypes";
import {
  generateNotEmptyValidator,
  validatePostalCode,
} from "@pd/utils/validation";
import usStates from "@pd/utils/usStates";
import { useState } from "react";

type Props = {
  address: AddressDbType | null;
  disabled?: boolean;
  required?: boolean;
  onAddressChange: (value: AddressDbType) => void;
};

export default function AddressInputSection({
  disabled = false,
  required = false,
  ...props
}: Props) {
  const theme = useTheme();
  const [validationErrors, setValidationErrors] = useState({
    line1: "",
    line2: "",
    city: "",
    state: "",
    postalCode: "",
  });

  const hasValidationErrors = (): boolean =>
    [
      validationErrors.line1,
      validationErrors.city,
      validationErrors.state,
      validationErrors.postalCode,
    ].some(Boolean);

  // NOTE: This type is weird, but all it does is map a string to a validation function.
  // The validation function can have a callback "cb" with an error string in it.
  const addressValidators: {
    [key: string]: (value: string, cb: (error: string) => void) => void;
  } = {
    line1: generateNotEmptyValidator("Street 1 address is required"),
    city: generateNotEmptyValidator("City is required"),
    state: generateNotEmptyValidator("State is required"),
    postalCode: validatePostalCode,
  };

  const errorMessages = Object.values(validationErrors).filter(
    (oneError: string) => oneError,
  );

  const handleOnChange = (keyName: string, value: string) => {
    const newAddress: AddressDbType = {
      ...defaultAddress,
      ...props.address,
      [keyName]: value,
      country: "USA", // TODO: Why isn't this picked up by the defaultAddress?
    };
    props.onAddressChange(newAddress);
  };

  const handleOnBlur = (keyName: string, lastVal: string) => {
    const fieldValidator = addressValidators[keyName];
    if (!fieldValidator) {
      return;
    }
    fieldValidator(lastVal, (error: string) => {
      setValidationErrors((prev) => ({ ...prev, [keyName]: error }));
    });
  };

  const handleOnUsStateCodeChange = (value: string) => {
    handleOnChange("state", value);
    handleOnBlur("state", value);
  };

  return (
    <Grid container>
      <InputLabel sx={{ pb: 1 }}>
        <Stack direction="row" alignItems="center" gap={0.5}>
          <Typography>Address</Typography>
          {required && (
            <Typography variant="subtitle2" sx={{ pt: "6px" }}>
              *
            </Typography>
          )}
        </Stack>
      </InputLabel>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <OutlinedInput
            disabled={disabled}
            onChange={(e) => handleOnChange("line1", e.target.value)}
            onBlur={(e) => handleOnBlur("line1", e.target.value)}
            value={props.address?.line1 || ""}
            placeholder="Street 1"
            sx={{ width: "100%" }}
            style={{
              border: `1px solid ${
                validationErrors.line1
                  ? theme.palette.error.main
                  : theme.palette.secondary.light
              }`,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <OutlinedInput
            disabled={disabled}
            onChange={(e) => handleOnChange("line2", e.target.value)}
            onBlur={(e) => handleOnBlur("line2", e.target.value)}
            value={props.address?.line2 || ""}
            placeholder="Street 2"
            sx={{ width: "100%" }}
            style={{
              border: `1px solid ${
                validationErrors.line2
                  ? theme.palette.error.main
                  : theme.palette.secondary.light
              }`,
            }}
          />
        </Grid>
        <Grid item xs={12} md={5}>
          <OutlinedInput
            disabled={disabled}
            onChange={(e) => handleOnChange("city", e.target.value)}
            onBlur={(e) => handleOnBlur("city", e.target.value)}
            value={props.address?.city || ""}
            placeholder="City"
            sx={{ width: "100%" }}
            style={{
              border: `1px solid ${
                validationErrors.city
                  ? theme.palette.error.main
                  : theme.palette.secondary.light
              }`,
            }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Autocomplete
            id="state"
            disableClearable
            onChange={(_, value) => {
              handleOnChange("state", value.code || "");
            }}
            onInputChange={(_, value, reason) => {
              if (reason === "reset") {
                const targetState = usStates.find(
                  (s) => `${s.name} (${s.code})` === value,
                );
                handleOnUsStateCodeChange(targetState?.code || "");
              }
            }}
            onBlur={(_) => handleOnBlur("state", props.address?.state || "")}
            value={undefined}
            options={usStates.sort((a, b) => -b.code.localeCompare(a.code))}
            isOptionEqualToValue={(option, value) => option.code === value.code}
            getOptionLabel={(option) => `${option.name} (${option.code})`}
            renderOption={(_props, option) => (
              <li key={option.code} {..._props} data-option-code={option.code}>
                {`${option.name} (${option.code})`}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="State"
                style={{
                  color: theme.palette.primary.main,
                  backgroundColor: "transparent",
                  borderRadius: "4px",
                  fontSize: "16px",
                  lineHeight: "24px",
                  fontFamily: "CircularRegular",
                  width: "100%",
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <OutlinedInput
            disabled={disabled}
            onChange={(e) => handleOnChange("postalCode", e.target.value)}
            onBlur={(e) => handleOnBlur("postalCode", e.target.value)}
            value={props.address?.postalCode || ""}
            placeholder="Postal code"
            sx={{ width: "100%" }}
            style={{
              border: `1px solid ${
                validationErrors.postalCode
                  ? theme.palette.error.main
                  : theme.palette.secondary.light
              }`,
            }}
          />
        </Grid>
      </Grid>
      {hasValidationErrors() && (
        <Stack
          gap={2}
          sx={{
            pt: "16px",
            pl: "10px",
          }}
        >
          {errorMessages.map((error: string) => (
            <Typography
              key={error}
              variant="subtitle1"
              color="error"
              sx={{
                lineHeight: "16px",
              }}
            >
              {error}
            </Typography>
          ))}
        </Stack>
      )}
    </Grid>
  );
}
