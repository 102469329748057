import { Stack, Typography, CardHeader, useTheme } from "@mui/material";
import MainCard from "@pd/components/MainCard";
import { OrderDetailDbType } from "@pd/redux/types/dbTypes";
import formatCurrency from "@pd/utils/formatCurrency";

type Props = {
  order: OrderDetailDbType | null;
};

export default function OrderList(props: Props) {
  const theme = useTheme();

  if (!props.order) {
    return null;
  }

  return (
    <Stack sx={{ mb: 5 }}>
      <MainCard
        darkTitle
        title="Order details"
        sx={{
          borderBottomLeftRadius: "0px",
          borderBottomRightRadius: "0px",
        }}
      >
        {props.order.lineItems.map((item) => (
          <Stack
            data-testid="order-list-item"
            key={`${item.description}-${item.code}`}
            direction="row"
            sx={{
              pt: 1,
              pb: 2,
              mb: 1,
              justifyContent: "space-between",
              borderBottom: `1px solid ${theme.palette.grey.A800}`,
            }}
          >
            <Typography variant="h5" justifyContent="space-between">
              {item.description} {item.code ? `(${item.code})` : ""}
            </Typography>
            <Typography>
              {formatCurrency(Math.ceil(item.amount * 100))}
            </Typography>
          </Stack>
        ))}
      </MainCard>
      <CardHeader
        sx={{
          py: 4,
          fontFamily: "CircularBold",
          border: `1px solid ${theme.palette.grey.A800}`,
          borderBottomLeftRadius: "5px",
          borderBottomRightRadius: "5px",
          borderTop: "none",
        }}
        titleTypographyProps={{ variant: "subtitle1" }}
        title={
          <Stack direction="row" justifyContent="space-between">
            <Typography sx={{ fontFamily: "CircularBold" }}>
              Base Total
            </Typography>
            <Typography variant="h5">
              {formatCurrency(props.order.total)}
            </Typography>
          </Stack>
        }
      />
    </Stack>
  );
}
