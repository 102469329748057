import safeFetch, { ErrorResType } from "@pd/api/utils/safeFetch";
import { endpoints } from "@pd/config/envSetup";
import { CreateOrderFormTypes } from "@pd/layouts/MktplaceDashboard/pages/Orders/components/CreateEditOrder/types";
import { TableAllFiltersType } from "@pd/layouts/MktplaceDashboard/types/tables";
import { convertToCents, convertToDollars } from "@pd/utils/convertMoney";

import {
  OrderDbType,
  OrderDetailDbType,
  OrderCreatedDbType,
  BulkPayoutInfoDbType,
  BulkPayoutResultDbType,
  OrderLineItemDbType,
} from "@pd/redux/types/dbTypes";
import addFilterQueryParams from "@pd/api/utils/addFilterQueryParams";
import { OrderStatus } from "@pd/redux/types/orders";

export type OrdersResType = { data: OrderDbType[] } | ErrorResType;
export const fetchOrders = async (
  filters: TableAllFiltersType,
): Promise<OrdersResType> => {
  const response = await safeFetch(
    addFilterQueryParams(
      `${endpoints.stitchBackend}/dashboard/orders`,
      filters,
    ),
    {
      method: "GET",
      credentials: "include",
    },
  );
  return response;
};

export type OrderCreateResType = { data: OrderCreatedDbType } | ErrorResType;
export const fetchCreateOrder = async (
  order: CreateOrderFormTypes,
  confirm: boolean,
): Promise<OrderCreateResType> => {
  let url = `${endpoints.stitchBackend}/dashboard/orders`;
  if (confirm) url += "?confirm=true";
  if (order.isFinanced) url += `${confirm ? "&" : "?"}with_financing=true`;

  const truthyLineItems = order.lineItems
    .filter((item) => item.amount > 0 && item.description)
    .map((item) => ({ ...item, amount: convertToCents(item.amount) }));

  const response = await safeFetch(url, {
    method: "POST",
    credentials: "include",
    body: JSON.stringify({
      bill_date: order.billDate,
      service_date: order.serviceDate,
      buyer_id: order.buyerId,
      vendor_id: order.vendorId,
      reference_id: order.referenceId,
      vendor_fee: convertToCents(order.deduction.amount),
      line_items: truthyLineItems,
    }),
  });
  if (response.error) {
    return response;
  }
  const result = {
    data: {
      ...response.data,
      lineItems: response.data.lineItems.map((item: OrderLineItemDbType) => ({
        ...item,
        amount: convertToDollars(item.amount),
      })),
      vendorFee: convertToDollars(response.data.vendorFee),
      vendorRate: parseFloat(response.data.vendorRate),
      buyerRate: parseFloat(response.data.buyerRate),
    },
  };
  return result;
};

export type OrderDetailResType = { data: OrderDetailDbType } | ErrorResType;
export const fetchOrderById = async (
  id: string,
): Promise<OrderDetailResType> => {
  const response = await safeFetch(
    `${endpoints.stitchBackend}/dashboard/orders/${id}`,
    {
      method: "GET",
      credentials: "include",
    },
  );
  if (response.error) {
    return response;
  }
  const result = {
    data: {
      ...response.data,
      lineItems: response.data.lineItems.map((item: OrderLineItemDbType) => ({
        ...item,
        amount: convertToDollars(item.amount),
      })),
      vendorFee: convertToDollars(response.data.vendorFee),
      vendorRate: parseFloat(response.data.vendorRate),
    },
  };
  return result;
};

export type DeleteOrderResType =
  | {
      data: {
        ID: string;
        isDeleted: boolean;
      };
    }
  | ErrorResType;
export const fetchOrderDeleteById = async (
  id: string,
): Promise<DeleteOrderResType> => {
  const response = await safeFetch(
    `${endpoints.stitchBackend}/dashboard/orders/${id}`,
    {
      method: "DELETE",
      credentials: "include",
    },
  );
  return response;
};

export const fetchUpdateOrderById = async (
  orderId: string,
  order: CreateOrderFormTypes,
  confirm: boolean,
): Promise<OrderDetailResType> => {
  let url = `${endpoints.stitchBackend}/dashboard/orders/${orderId}`;
  if (confirm) url += "?confirm=true";
  if (order.isFinanced) url += `${confirm ? "&" : "?"}with_financing=true`;

  const truthyLineItems = order.lineItems
    .filter((item) => item.amount > 0 && item.description)
    .map((item) => ({ ...item, amount: convertToCents(item.amount) }));

  const response = await safeFetch(url, {
    method: "PUT",
    credentials: "include",
    body: JSON.stringify({
      bill_date: order.billDate,
      service_date: order.serviceDate,
      buyer_id: order.buyerId,
      vendor_id: order.vendorId,
      vendor_fee: convertToCents(order.deduction.amount),
      reference_id: order.referenceId,
      line_items: truthyLineItems,
    }),
  });
  if (response.error) {
    return response;
  }
  const result = {
    data: {
      ...response.data,
      lineItems: response.data.lineItems.map((item: OrderLineItemDbType) => ({
        ...item,
        amount: convertToDollars(item.amount),
      })),
      vendorFee: convertToDollars(response.data.vendorFee),
    },
  };
  return result;
};
export const fetchPreviewOrder = async (
  order: CreateOrderFormTypes,
  orderId: string | undefined,
): Promise<OrderDetailResType> => {
  let url = `${endpoints.stitchBackend}/dashboard/orders/preview`;
  if (order.isFinanced)
    url += `${order.isFinanced ? "?with_financing=true" : ""}`;

  const truthyLineItems = order.lineItems
    .filter((item) => item.amount > 0 && item.description)
    .map((item) => ({ ...item, amount: convertToCents(item.amount) }));

  const response = await safeFetch(url, {
    method: "POST",
    credentials: "include",
    body: JSON.stringify({
      id: orderId || "",
      bill_date: order.billDate,
      service_date: order.serviceDate,
      buyer_id: order.buyerId,
      vendor_id: order.vendorId,
      reference_id: order.referenceId,
      vendor_fee: convertToCents(order.deduction.amount),
      line_items: truthyLineItems,
    }),
  });
  if (response.error) {
    return response;
  }
  const result = {
    data: {
      ...response.data,
      lineItems: response.data.lineItems.map((item: OrderLineItemDbType) => ({
        ...item,
        amount: convertToDollars(item.amount),
      })),
      vendorFee: convertToDollars(response.data.vendorFee),
    },
  };
  return result;
};

export type VoidOrderResType =
  | {
      data: { status: OrderStatus };
    }
  | ErrorResType;
export const fetchVoidOrderById = async (
  id: string,
): Promise<VoidOrderResType> => {
  const response = await safeFetch(
    `${endpoints.stitchBackend}/dashboard/orders/${id}/void`,
    {
      method: "POST",
      credentials: "include",
    },
  );
  return response;
};

export type SelectedOrdersActionResDataType = {
  successCount: number;
  selectionCount: number;
  errors: string[];
};
export type SelectedOrdersActionResType =
  | { data: SelectedOrdersActionResDataType }
  | ErrorResType;
export const fetchDeleteSelectedOrders = async (
  ids: string[],
): Promise<SelectedOrdersActionResType> => {
  const response = await safeFetch(
    `${endpoints.stitchBackend}/dashboard/orders/selections/delete`,
    {
      method: "POST",
      credentials: "include",
      body: JSON.stringify({
        order_ids: ids,
      }),
    },
  );
  return response;
};

export const fetchConfirmSelectedOrders = async (
  ids: string[],
): Promise<SelectedOrdersActionResType> => {
  const response = await safeFetch(
    `${endpoints.stitchBackend}/dashboard/orders/selections/confirm`,
    {
      method: "POST",
      credentials: "include",
      body: JSON.stringify({
        order_ids: ids,
      }),
    },
  );
  return response;
};

export const fetchVoidSelectedOrders = async (
  ids: string[],
): Promise<SelectedOrdersActionResType> => {
  const response = await safeFetch(
    `${endpoints.stitchBackend}/dashboard/orders/selections/void`,
    {
      method: "POST",
      credentials: "include",
      body: JSON.stringify({
        action: "void",
        order_ids: ids,
      }),
    },
  );
  return response;
};

export type BulkPayoutInfoResType =
  | { data: BulkPayoutInfoDbType }
  | ErrorResType;
export const fetchBulkPayoutInfo = async (): Promise<BulkPayoutInfoResType> => {
  const response = await safeFetch(
    `${endpoints.stitchBackend}/dashboard/orders/bulk_actions/payout`,
    {
      method: "GET",
      credentials: "include",
    },
  );
  return response;
};

export type BulkPayoutResultResType =
  | { data: BulkPayoutResultDbType }
  | ErrorResType;
export const fetchTriggerBulkPayout =
  async (): Promise<BulkPayoutResultResType> => {
    const response = await safeFetch(
      `${endpoints.stitchBackend}/dashboard/orders/bulk_actions/payout`,
      {
        method: "POST",
        credentials: "include",
      },
    );
    return response;
  };
