import MiscColors from "@pd/mantis/themes/miscColors";
import { VendorKycStatus } from "@pd/redux/types/vendors";

export interface VendorsTableDataType {
  id: string;
  vendor: string;
  account: string;
  phone: string;
  email: string;
  referenceId: string;
  kycStatus: VendorKycStatus;
  hasCard: boolean;
  cardActive: boolean;
  cardDisabled: boolean;
  cardPushEnabled: boolean;
  cardExpiration: string;
  bankAccountDisabled: boolean;
}

type VendorsStatusColorType = {
  [VendorKycStatus.approved]: string;
  [VendorKycStatus.pending]: string;
  [VendorKycStatus.manual_review]: string;
  [VendorKycStatus.suspended]: string;
  [VendorKycStatus.rejected]: string;
  [VendorKycStatus.document]: string;
  [VendorKycStatus.unknown]: string;
};

const mColors = MiscColors();
export const VENDOR_KYC_STATUS_COLORS: VendorsStatusColorType = {
  [VendorKycStatus.approved]: mColors.statusGreenBg,
  [VendorKycStatus.pending]: mColors.statusVioletBg,
  [VendorKycStatus.manual_review]: mColors.statusOrangeBg,
  [VendorKycStatus.suspended]: mColors.statusRedBg,
  [VendorKycStatus.rejected]: mColors.statusRedBg,
  [VendorKycStatus.document]: mColors.statusYellowBg,
  [VendorKycStatus.unknown]: mColors.statusVioletBg,
};

export function isVendorKycStatus(status: string): status is VendorKycStatus {
  return Object.values(VendorKycStatus).includes(status as VendorKycStatus);
}

export type VendorsKycStatusOptionType = {
  key: number;
  label: string;
  status: keyof typeof VendorKycStatus;
};

export function isVendorsKycStatusOptionType(
  option: any,
): option is VendorsKycStatusOptionType {
  return [
    typeof option === "object",
    option !== null,
    "label" in option,
    "status" in option,
    typeof option.label === "string",
    Object.values(VendorKycStatus).includes(option.status as VendorKycStatus),
  ].every(Boolean);
}

export interface VendorsColDefType {
  id: keyof VendorsTableDataType;
  label: string;
  index: number;
}

export function isVendorKycStatusType(
  status: string,
): status is VendorKycStatus {
  return Object.values(VendorKycStatus).includes(status as VendorKycStatus);
}
