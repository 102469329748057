/* eslint-disable react/no-array-index-key */
import { Stack, Typography, useTheme } from "@mui/material";
import { useAppSelector } from "@pd/redux/store";
import {
  selectUploadReport,
  selectFilename,
} from "@pd/layouts/MktplaceDashboard/redux/selectors/orders/csvUpload";

export default function ErrorReportTab() {
  const theme = useTheme();
  const filename = useAppSelector(selectFilename);
  const report = useAppSelector(selectUploadReport);

  if (!report) return null;

  return (
    <Stack
      sx={{
        overflowY: "scroll",
        overflowX: "hidden",
        whiteSpace: "nowrap",
        height: "100%",
        "::-webkit-scrollbar": {
          width: "12px",
          height: "7px",
        },
        "::-webkit-scrollbar-track": {
          background: "#F1F1F1",
        },
        "::-webkit-scrollbar-thumb": {
          background: "#ddd",
        },
        "::-webkit-scrollbar-thumb:hover": {
          background: "#333",
        },
      }}
    >
      <Stack gap={1}>
        <Typography
          variant="body1"
          sx={{
            fontFamily: "CircularBold",
            backgroundColor: "secondary",
          }}
        >
          {filename}
        </Typography>
        <Stack gap={2} sx={{ ml: 5 }}>
          <Stack gap={1}>
            <Stack direction="row" alignItems="baseline" gap={1}>
              <Typography variant="body1">Total Rows:</Typography>
              <Typography
                variant="body1"
                sx={{
                  color:
                    report.validRowCount === report.totalRowCount
                      ? theme.palette.success.main
                      : theme.palette.warning.darker,
                }}
              >
                {report.totalRowCount}
              </Typography>
            </Stack>
            <Stack direction="row" alignItems="baseline" gap={1}>
              <Typography variant="body1">Valid Rows:</Typography>
              <Typography
                variant="body1"
                sx={{
                  color:
                    report.validRowCount === report.totalRowCount
                      ? theme.palette.success.main
                      : theme.palette.error.main,
                }}
              >
                {report.validRowCount}
              </Typography>
            </Stack>
          </Stack>
          <Stack
            gap={2}
            sx={{
              borderLeft: "2px solid",
              borderColor: theme.palette.error.main,
              pl: 2,
            }}
          >
            <Typography variant="h5" sx={{ fontFamily: "CircularBold" }}>
              Errors
            </Typography>
            {report.errors.map((error) => (
              <Stack
                direction="row"
                key={`${error.row}`}
                gap={1}
                sx={{ ml: 5 }}
              >
                <Typography variant="body1">
                  row{" "}
                  <span
                    style={{
                      backgroundColor: theme.palette.secondary.light,
                      borderRadius: "5px",
                      padding: "2px 5px",
                    }}
                  >
                    {error.row}
                  </span>
                  :
                </Typography>
                <Stack gap={1}>
                  {error.errorMessages.map((msg, i, a) => (
                    <Stack key={`${msg}-${i}`} direction="row" gap={2}>
                      {" "}
                      <Typography key={msg} variant="subtitle1">
                        <span
                          style={{
                            fontWeight: "400",
                            color: theme.palette.error.main,
                            fontFamily: "monospace",
                          }}
                        >
                          {msg}
                        </span>
                        {a.length > 1 && i < a.length - 1 ? "," : ""}
                      </Typography>
                    </Stack>
                  ))}
                </Stack>
              </Stack>
            ))}
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}
