import { useState, useEffect, useMemo, useCallback } from "react";
import { Stack, Typography } from "@mui/material";
import MainCard from "@pd/components/MainCard";
import { useAppDispatch, useAppSelector } from "@pd/redux/store";
import {
  selectAllTeamMembersApiFetching,
  selectAllTeamMembersApiError,
  selectAllTeamMembers,
} from "@pd/layouts/MktplaceDashboard/redux/selectors/teams";
import da from "@pd/layouts/MktplaceDashboard/redux/actions";
import StitchLoading from "@pd/components/StitchLoading";
import { FadeInButton } from "@pd/components/FadeComponents";
import DashboardErrorBanner from "@pd/components/DashboardErrorBanner";
import { TeamMemberDbType } from "@pd/redux/types/dbTypes";
import { selectProfile } from "@pd/redux/selectors/auth";
import { AddTeamMemberModal, DeleteTeamMemberModal } from "./components/Modals";
import TeamTable from "./components/TeamTable";

export default function ManageTeam() {
  const dispatch = useAppDispatch();

  const apiFetching = useAppSelector(selectAllTeamMembersApiFetching);
  const apiError = useAppSelector(selectAllTeamMembersApiError);
  const profile = useAppSelector(selectProfile);
  const allTeamMembers = useAppSelector(selectAllTeamMembers);
  const hasApiError = apiError.message || apiError.status;

  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [targetMember, setTargetMember] = useState<TeamMemberDbType | null>(
    null,
  );

  useEffect(() => {
    dispatch(da.teams.all.getAllTeamMembers());
  }, []);

  const handleDeleteTeamMember = useCallback(
    (id: string) => {
      const memberById = allTeamMembers.find((member) => member.id === id);
      setTargetMember(memberById || null);
      setShowDeleteModal(true);
    },
    [allTeamMembers],
  );

  const manageTeamHeader = useMemo(
    () => (
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" gap={1}>
          <Typography variant="h5" sx={{ fontFamily: "CircularBold" }}>
            Manage team
          </Typography>
        </Stack>
        <FadeInButton
          variant="contained"
          sx={{
            display:
              hasApiError || profile?.role !== "admin" ? "none" : "block",
            fontSize: "14px",
            "&:hover": {
              backgroundColor: "rgba(0, 0, 0, 0.8)",
            },
          }}
          onClick={() => setShowCreateModal(true)}
          disabled={apiFetching}
        >
          Add member
        </FadeInButton>
      </Stack>
    ),
    [apiFetching],
  );

  const renderContent = () => {
    if (hasApiError) {
      return (
        <DashboardErrorBanner
          message={
            "An error occurred while fetching your Team Members. Please try again."
          }
          apiError={apiError}
          onRetry={() => {
            dispatch(da.teams.all.apiError({ message: "", status: 0 }));
            dispatch(da.teams.all.apiFetching(true));
            setTimeout(() => dispatch(da.teams.all.getAllTeamMembers()), 1000);
          }}
        />
      );
    }
    if (apiFetching) {
      return (
        <Stack
          sx={{ width: "100%" }}
          alignItems="center"
          justifyContent="center"
        >
          <StitchLoading size="medium" />
        </Stack>
      );
    }
    return (
      <Stack>
        <TeamTable
          userId={profile?.id || ""}
          userRole={profile?.role || ""}
          onDeleteMember={handleDeleteTeamMember}
        />
        {showCreateModal ? (
          <AddTeamMemberModal
            show={showCreateModal}
            onClose={() => setShowCreateModal(false)}
          />
        ) : null}
        {showDeleteModal ? (
          <DeleteTeamMemberModal
            show={showDeleteModal}
            id={targetMember?.id || ""}
            onClose={() => setShowDeleteModal(false)}
          />
        ) : null}
      </Stack>
    );
  };

  return (
    <MainCard
      darkTitle
      title={manageTeamHeader}
      sx={{
        mb: 5,
        width: "100%",
        minHeight: "200px",
      }}
    >
      {renderContent()}
    </MainCard>
  );
}
