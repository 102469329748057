import { FadeInStack } from "@pd/components/FadeComponents";
import { useAppDispatch, useAppSelector } from "@pd/redux/store";
import da from "@pd/layouts/MktplaceDashboard/redux/actions";
import { useEffect, useState } from "react";
import {
  selectUiBuyer,
  selectApiBuyer,
  selectCrudBuyerError,
  selectCrudBuyerFetching,
  selectCrudBuyerSuccess,
} from "@pd/layouts/MktplaceDashboard/redux/selectors/buyers/crudBuyer";
import { Stack, Typography, useTheme } from "@mui/material";
import StitchLoading from "@pd/components/StitchLoading";
import { AddressDbType } from "@pd/redux/types/dbTypes";
import StitchAsyncButton from "@pd/components/StitchAsyncButton";
import {
  validateEmail,
  validateFirstName,
  validateLastName,
  validatePhone,
} from "@pd/utils/validation";
import StringInputSection from "./components/StringInputSection";
import AddressInputSection from "./components/AddressInputSection";
import StatusMessage from "./components/StatusMessage";
import PhoneInputSection from "./components/PhoneInputSection";

type Props = {
  buyerId?: string;
  onBuyerCreated: () => void;
};

export default function CreateEditBuyer({ buyerId = "", ...props }: Props) {
  const dispatch = useAppDispatch();

  const apiFetching = useAppSelector(selectCrudBuyerFetching);
  const apiError = useAppSelector(selectCrudBuyerError);
  const apiSuccess = useAppSelector(selectCrudBuyerSuccess);
  const apiBuyer = useAppSelector(selectApiBuyer);
  const uiBuyer = useAppSelector(selectUiBuyer);

  const [onMounting, setOnMounting] = useState(true);
  const [saveFetching, setSaveFetching] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);

  const showEdit = [buyerId, apiBuyer?.id].some(Boolean);

  const [uiErrors, setUiErrors] = useState({
    companyName: false,
    taxId: false,
    address: false,
    extId: false,
    firstName: false,
    lastName: false,
    phone: false,
    email: false,
  });

  const hasUiErrors = () =>
    uiErrors.companyName ||
    uiErrors.taxId ||
    uiErrors.address ||
    uiErrors.extId ||
    uiErrors.firstName ||
    uiErrors.lastName ||
    uiErrors.phone ||
    uiErrors.email;

  const theme = useTheme();

  const handleFetchBuyerById = () => {
    setOnMounting(true);
    dispatch(da.buyers.crud.clearUpsertBuyer());
    // TODO: Uncomment when there's a getBuyerByIdAndMerchantId
    // if (buyerId) {
    //   dispatch(da.buyers.crud.getApiBuyer(buyerId));
    // }
  };

  useEffect(() => {
    handleFetchBuyerById();
    return () => {
      dispatch(da.buyers.crud.clearUpsertBuyer());
    };
  }, []);

  useEffect(() => {
    if (apiSuccess && !saveSuccess && saveFetching) {
      setSaveSuccess(true);
      setSaveFetching(false);
      setTimeout(() => {
        setSaveSuccess(false);
      }, 3000);
    }
    if (apiError.status && saveFetching) {
      setSaveFetching(false);
    } else if (!apiFetching && onMounting) {
      setOnMounting(false);
    } else if (!apiFetching && saveFetching) {
      setSaveFetching(false);
    }
    if (apiSuccess) {
      props.onBuyerCreated();
      dispatch(da.buyers.crud.apiSuccess(false));
    }
  }, [apiFetching, apiError, apiSuccess, saveSuccess, saveFetching]);

  function handleOnChange(
    keyName: string,
    value: string | AddressDbType,
    isValid: boolean,
  ) {
    setUiErrors((prev) => ({ ...prev, [keyName]: !isValid }));
    dispatch(da.buyers.crud.apiError({ message: "", status: 0 }));
    dispatch(da.buyers.crud.setBuyerUiData(keyName, value));
  }

  function shouldDisableConfirmBuyer() {
    return (
      hasUiErrors() ||
      !uiBuyer?.companyName ||
      !uiBuyer?.firstName ||
      !uiBuyer?.lastName ||
      !uiBuyer.taxId ||
      !uiBuyer.email ||
      !uiBuyer.phone ||
      !uiBuyer.address?.line1 ||
      !uiBuyer.address?.city ||
      !uiBuyer.address?.state ||
      !uiBuyer.address?.postalCode ||
      !uiBuyer.address?.country
    );
  }

  function onConfirmBuyer() {
    if (uiBuyer == null) {
      return;
    }
    setSaveSuccess(false);
    setSaveFetching(true);
    if (buyerId || apiBuyer?.id) {
      dispatch(
        da.buyers.crud.editBuyer(uiBuyer, buyerId || apiBuyer!.id, true),
      );
    } else {
      dispatch(da.buyers.crud.createBuyer(uiBuyer, true));
    }
  }

  if (apiError.status) {
    window.scrollTo(0, document.body.scrollHeight);
  }

  return (
    <FadeInStack
      key={buyerId}
      style={{
        width: "100%",
        marginTop: "15px",
        marginBottom: "25px",
      }}
    >
      {onMounting ? (
        <Stack
          sx={{
            flexGrow: 1,
            width: "100%",
            height: "50vh",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <StitchLoading size="large" />
        </Stack>
      ) : (
        <Stack
          spacing={3}
          sx={{
            flexGrow: 1,
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            pl: "70px",
            pr: "70px",
          }}
        >
          <Typography variant="body1">
            {showEdit
              ? "Edit a buyer's profile."
              : "Add a buyer profile to begin connecting orders."}
          </Typography>
          <StringInputSection
            keyName="companyName"
            value={uiBuyer?.companyName || ""}
            label="Company name"
            placeholder="Company Name"
            required
            disabled={showEdit}
            onChange={handleOnChange}
          />
          <StringInputSection
            keyName="taxId"
            value={uiBuyer?.taxId || ""}
            label="EIN"
            placeholder="12-1234567"
            required
            disabled={showEdit}
            onChange={handleOnChange}
          />
          <AddressInputSection
            address={uiBuyer?.address || null}
            required
            onAddressChange={(newAddress) =>
              handleOnChange("address", newAddress, true)
            }
          />
          <StringInputSection
            keyName="extId"
            value={uiBuyer?.extId || ""}
            label="Buyer reference ID"
            placeholder="Enter a unique reference ID"
            onChange={handleOnChange}
          />
          <StringInputSection
            keyName="firstName"
            value={uiBuyer?.firstName || ""}
            label="First name"
            placeholder="First name"
            required
            onChange={handleOnChange}
            validator={validateFirstName}
          />
          <StringInputSection
            keyName="lastName"
            value={uiBuyer?.lastName || ""}
            label="Last name"
            placeholder="Last name"
            required
            onChange={handleOnChange}
            validator={validateLastName}
          />
          <PhoneInputSection
            value={uiBuyer?.phone || ""}
            required
            onChange={handleOnChange}
            validator={validatePhone}
          />
          <StringInputSection
            keyName="email"
            value={uiBuyer?.email || ""}
            label="Email"
            placeholder="example@withstitch.com"
            required
            onChange={handleOnChange}
            validator={validateEmail}
          />

          <Stack
            sx={{
              width: "100%",
            }}
          >
            <Typography
              variant="subtitle2"
              sx={{ color: theme.palette.secondary.main }}
              justifyContent={"flex-start"}
            >
              * Required fields
            </Typography>
          </Stack>

          <StatusMessage loading={saveFetching} apiError={apiError} />

          <Stack
            sx={{
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <StitchAsyncButton
              success={saveSuccess}
              loading={saveFetching}
              onClick={onConfirmBuyer}
              color={"primary"}
              buttonText={"Confirm"}
              variant={"contained"}
              logoColor={"black"}
              loadingSize={"small"}
              loadingPosition={{ top: -30, left: 0 }}
              buttonWidth={"90px"}
              disabled={shouldDisableConfirmBuyer()}
            />
          </Stack>
        </Stack>
      )}
    </FadeInStack>
  );
}
