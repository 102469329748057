import { Stack, Typography, Button } from "@mui/material";
import BlackDot from "@pd/components/BlackDot";

type Props = {
  onTabChange: (tabIndex: number) => void;
};
export default function ExampleTab(props: Props) {
  return (
    <Stack gap={2} sx={{ width: "100%", height: "100%" }}>
      <Stack gap={6} sx={{ height: "100%" }}>
        <Stack direction="row" gap={5}>
          <a href="/orders_blank.csv" download="stitch_orders_TEMPLATE.csv">
            <Button sx={{ width: "200px" }} variant="outlined">
              Download Template
            </Button>
          </a>
          <Typography variant="h6">
            <span style={{ fontFamily: "CircularBold" }}>Blank Template:</span>{" "}
            Contains <strong>only column</strong> information. It describes the
            columns we expect to find when we process your CSV.
          </Typography>
        </Stack>
        <Stack direction="row" gap={5}>
          <a href="/orders_example.csv" download="stitch_orders_EXAMPLE.csv">
            <Button sx={{ width: "200px" }} variant="outlined">
              Download Example
            </Button>
          </a>
          <Stack gap={2}>
            <Typography variant="h6">
              <span style={{ fontFamily: "CircularBold" }}>Example:</span>{" "}
              Contains a few completed rows to show how to format your CSV to
              match our expectations.
            </Typography>
            <Typography variant="h6">
              {"Notes: "}
              <br />
              <BlackDot />
              &nbsp; Some cells are missing values. This indicates that these
              columns are optional.
              <br />
              <BlackDot />
              &nbsp; {"Max Line Items: There's "}a max of 5 line items per row.
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      <Stack sx={{ width: "100%" }} alignItems="center">
        <Button
          onClick={() => props.onTabChange(0)}
          variant="contained"
          sx={{ width: "100px" }}
        >
          Upload
        </Button>
      </Stack>
    </Stack>
  );
}
