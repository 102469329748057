import { useMemo, useEffect } from "react";
import { Button, Typography, Stack } from "@mui/material";
import MainCard from "@pd/mantis/components/MainCard";
import MiscColors from "@pd/mantis/themes/miscColors";
import PlaidLinkButton from "@pd/components/PlaidLink/LinkByButton";
import isDevEnv from "@pd/utils/envCheck";
import { useAppDispatch, useAppSelector } from "@pd/redux/store";
import {
  // selectLinkToken,
  selectLinkedAcctDetails,
} from "@pd/components/PlaidLink/redux/selectors";
import pla from "@pd/components/PlaidLink/redux/actions";
import usePlaid from "@pd/components/PlaidLink/hooks/usePlaid";
import { FadeInButton } from "@pd/components/FadeComponents";

type Props = {
  onRemount: () => void;
  onClearState: () => void;
};
export default function LinkedAccounts(props: Props) {
  const dispatch = useAppDispatch();
  const miscColors = MiscColors();
  const linkedAccount = useAppSelector(selectLinkedAcctDetails);
  const { startPlaidLink, plaidIsReady } = usePlaid();

  useEffect(() => {
    dispatch(pla.accounts.getBankAccounts());
  }, []);

  const linkedAcctsHeader = useMemo(
    () => (
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h5" sx={{ fontFamily: "CircularBold" }}>
          Linked account
        </Typography>
        <Stack direction="row" gap={2}>
          <Button
            variant="contained"
            color="error"
            sx={{
              display: isDevEnv() ? "block" : "none",
              fontSize: "16px",
            }}
            onClick={props.onClearState}
          >
            Clear state
          </Button>
          <FadeInButton
            variant="text"
            sx={{
              px: 2,
              display: linkedAccount?.account?.id ? "block" : "none",
              color: miscColors.blueText,
              fontSize: "16px",
            }}
            onClick={() => {
              props.onRemount();
              startPlaidLink();
            }}
          >
            Update
          </FadeInButton>
        </Stack>
      </Stack>
    ),
    [linkedAccount?.account?.id, startPlaidLink, plaidIsReady],
  );

  return (
    <MainCard
      data-testid="settingsLinkedAccounts"
      title={linkedAcctsHeader}
      sx={{
        marginBottom: "30px",
      }}
    >
      <PlaidLinkButton
        onRemount={props.onRemount}
        plaidReady={plaidIsReady}
        startPlaidLink={startPlaidLink}
      />
    </MainCard>
  );
}
