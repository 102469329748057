import { Modal, Fade, Stack, Typography, Button } from "@mui/material";
import ModalTitle from "@pd/components/ModalTitle";

import { modalStyles } from "../constants/styles";
import { SelectionApiErrorType } from "../../../types";

type Props = {
  selectionType: "delete" | "confirm" | "void" | "";
  selectionError: SelectionApiErrorType | null;
  showModal: boolean;
  onClose: () => void;
};
export default function SelectionErrors(props: Props) {
  const labels = (() => {
    switch (props.selectionType) {
      case "delete":
        return {
          title: "Error Deleting Orders",
          verb: "deleted",
        };
      case "confirm":
        return {
          title: "Error Confirming Orders",
          verb: "confirmed",
        };
      case "void":
        return {
          title: "Error Voiding Orders",
          verb: "voided",
        };
      default:
        return {
          title: "Error",
          verb: "processed",
        };
    }
  })();

  if (!props.showModal) {
    return null;
  }

  return (
    <Modal open={props.showModal}>
      <Fade in={props.showModal}>
        <Stack
          gap={2}
          sx={{
            ...modalStyles,
            height: "250px",
            width: "50%",
          }}
        >
          <ModalTitle title="Error" onClose={props.onClose} />
          <Stack
            alignItems="center"
            justifyContent="center"
            gap={2}
            sx={{ width: "100%", height: "100%", p: 3 }}
          >
            <Stack
              alignItems="center"
              justifyContent="center"
              sx={{ height: "100%" }}
              gap={4}
            >
              <Typography variant="h4">{labels.title}</Typography>
              <Stack alignItems="center">
                <Typography>
                  There was an error processing your selection.
                </Typography>
                <Stack direction="row" alignItems="center">
                  <Typography color="error" variant="body1">
                    {`${props.selectionError?.errorsTotal}`}&nbsp;
                  </Typography>
                  <Typography variant="body1">
                    {`of ${props.selectionError?.selectionCount} Orders could not be ${labels.verb}.`}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
            <Button
              variant="contained"
              onClick={() => props.onClose()}
              sx={{ width: "150px" }}
            >
              Ok
            </Button>
          </Stack>
        </Stack>
      </Fade>
    </Modal>
  );
}
