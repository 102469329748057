import {
  all,
  call,
  put,
  delay,
  takeLatest,
  takeLeading,
  select,
} from "redux-saga/effects";
import {
  fetchVendors,
  fetchGetVendorById,
  fetchConfirmVendorPayout,
  fetchDashboardVendorsSearch,
  DashboardVendorsSearchResType,
} from "@pd/api/dashboard/vendors";
import type {
  VendorsResType,
  ConfirmVendorPayoutResType,
} from "@pd/api/dashboard/vendors";
import { MANUAL_ERROR_CODE } from "@pd/utils/constants";

import da from "../../actions";
import { TableAllFiltersType } from "../../../types/tables";
import { selectTableFilters } from "../../selectors/table";

export default function* allVendorsSaga() {
  yield all([
    takeLeading(da.vendors.all.getVendors, handleGetVendors),
    takeLeading(da.vendors.all.confirmVendorPayout, onConfirmPayout),
    takeLatest(da.vendors.all.getVendorById, onGetVendorById),
    takeLatest(da.vendors.all.searchVendors.toString(), onSearchVendors),
  ]);
}

function* onConfirmPayout(
  action: ReturnType<typeof da.vendors.all.confirmVendorPayout>,
) {
  try {
    yield all([
      put(da.vendors.all.apiFetching(true)),
      put(da.vendors.all.apiSuccess(false)),
      put(da.vendors.all.apiError({ message: "", status: 0 })),
    ]);
    const res: ConfirmVendorPayoutResType = yield call(
      fetchConfirmVendorPayout,
      action.payload.vendorId,
    );
    if ("error" in res) {
      yield all([
        put(da.vendors.all.apiError(res.error)),
        put(da.vendors.all.apiSuccess(false)),
        put(da.vendors.all.apiFetching(false)),
      ]);
    } else {
      yield put(da.vendors.all.apiSuccess(true));
      yield delay(400);
      yield put(da.vendors.all.apiFetching(false));
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      console.error(error.message);
    }
    yield all([
      put(da.vendors.all.apiFetching(false)),
      put(da.vendors.all.apiSuccess(false)),
      put(
        da.vendors.all.apiError({
          message: "An error occurred while confirming payout.",
          status: MANUAL_ERROR_CODE,
        }),
      ),
    ]);
  }
}

export function* handleGetVendors(): Generator<any, string, any> {
  try {
    yield all([
      put(da.vendors.all.apiFetching(true)),
      put(da.vendors.all.apiSuccess(false)),
      put(da.vendors.all.apiError({ message: "", status: 0 })),
    ]);
    const filters: TableAllFiltersType = yield select(selectTableFilters);
    const res: VendorsResType = yield call(fetchVendors, filters);
    if ("error" in res) {
      yield all([
        put(da.vendors.all.apiError(res.error)),
        put(da.vendors.all.apiSuccess(false)),
        put(da.vendors.all.apiFetching(false)),
      ]);
    } else {
      if (res.data.length) {
        yield put(
          da.table.setTablePagination({
            ...filters.pagination,
            total: res.data[0].totalResults,
          }),
        );
      } else {
        yield put(
          da.table.setTablePagination({
            ...filters.pagination,
            total: 0,
          }),
        );
      }
      yield all([
        put(da.vendors.all.setVendors(res.data)),
        put(da.vendors.all.apiSuccess(true)),
      ]);
      yield put(da.vendors.all.apiFetching(false));
    }
    return "";
  } catch (error: unknown) {
    if (error instanceof Error) {
      console.error(error.message);
    }
    const errMsg = "An error occurred while fetching vendors.";
    yield all([
      put(
        da.vendors.all.apiError({
          message: errMsg,
          status: MANUAL_ERROR_CODE,
        }),
      ),
      put(da.vendors.all.apiFetching(false)),
      put(da.vendors.all.apiSuccess(false)),
    ]);
    return errMsg;
  }
}
function* onGetVendorById(
  action: ReturnType<typeof da.vendors.all.getVendorById>,
): Generator<any, any, any> {
  try {
    yield all([
      put(da.vendors.all.apiFetching(true)),
      put(da.vendors.all.apiSuccess(false)),
      put(da.vendors.all.apiError({ message: "", status: 0 })),
    ]);
    const res: VendorsResType = yield call(
      fetchGetVendorById,
      action.payload.id,
    );
    if ("error" in res) {
      yield all([
        put(da.vendors.all.apiError(res.error)),
        put(da.vendors.all.apiSuccess(false)),
        put(da.vendors.all.apiFetching(false)),
      ]);
    } else {
      yield all([
        put(da.vendors.all.setVendors(res.data)),
        put(da.vendors.all.apiSuccess(true)),
      ]);
      yield put(da.vendors.all.apiFetching(false));
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      console.error(error.message);
    }
    yield all([
      put(
        da.vendors.all.apiError({
          message: "An error occurred while fetching vendors.",
          status: MANUAL_ERROR_CODE,
        }),
      ),
      put(da.vendors.all.apiFetching(false)),
      put(da.vendors.all.apiSuccess(false)),
    ]);
  }
}

/**
 * This function is used to get orders by filter. It's called from the Table
 * Saga (actions.table.getTableData) as a helper function. The Table Saga is responsible for setting the
 * table filters and handling async state (fetching, error, etc.). This function
 * is only responsible for fetching the orders by filter and returning the
 * response.
 * @returns {VendorsResType} - The response from the API
 */
export function* handleApplyFiltersVendorsTable(
  _filters: TableAllFiltersType,
): Generator<any, null | string, any> {
  const filters = { ..._filters };
  try {
    yield put(da.table.setTablePagination(filters.pagination));
    const res: VendorsResType = yield call(fetchVendors, filters);
    if ("error" in res) {
      return res.error.message;
    }
    if (res.data.length) {
      yield put(
        da.table.setTablePagination({
          ...filters.pagination,
          total: res.data[0].totalResults,
        }),
      );
    } else {
      yield put(
        da.table.setTablePagination({
          ...filters.pagination,
          total: 0,
        }),
      );
    }
    yield put(da.vendors.all.setFilteredVendors(res.data));
    return null;
  } catch (error: unknown) {
    const errMsg =
      "An error occurred while fetching the vendors table. Please try again.";
    if (error instanceof Error) {
      console.error(error.message);
    }
    return errMsg;
  }
}

function* onSearchVendors(
  action: ReturnType<typeof da.vendors.all.searchVendors>,
) {
  yield delay(500);
  try {
    yield all([
      put(da.vendors.all.apiSuccess(false)),
      put(da.vendors.all.apiFetching(true)),
      put(da.vendors.all.apiError({ message: "", status: 0 })),
    ]);
    const res: DashboardVendorsSearchResType = yield call(
      fetchDashboardVendorsSearch,
      action.payload.query,
    );
    if ("error" in res) {
      yield all([
        put(da.vendors.all.apiFetching(false)),
        put(da.vendors.all.apiError(res.error)),
        put(da.vendors.all.apiSuccess(false)),
      ]);
    } else {
      if (res.data.length) {
        yield put(da.vendors.all.setSearchedVendors(res.data));
      }
      yield all([
        put(da.vendors.all.apiFetching(false)),
        put(da.vendors.all.apiSuccess(true)),
      ]);
    }
  } catch (error: unknown) {
    const errMsg =
      "An error occurred while searching vendors. Please try again later.";
    if (error instanceof Error) {
      console.error(error.message);
    }
    yield all([
      put(
        da.vendors.all.apiError({
          message: errMsg,
          status: MANUAL_ERROR_CODE,
        }),
      ),
      put(da.vendors.all.apiFetching(false)),
      put(da.vendors.all.apiSuccess(false)),
    ]);
  }
}
