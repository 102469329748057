import { addFilterQueryParams } from "@pd/api/utils";
import safeFetch, { ErrorResType } from "@pd/api/utils/safeFetch";
import { endpoints } from "@pd/config/envSetup";
import { CreateBuyerFormTypes } from "@pd/layouts/MktplaceDashboard/pages/Buyers/components/CreateEditBuyer/types";
import { TableAllFiltersType } from "@pd/layouts/MktplaceDashboard/types/tables";

import { BuyerDbType, BuyersSearchDbType } from "@pd/redux/types/dbTypes";
import camelCaseToSnakeCaseKeys from "@pd/utils/camelCaseToSnakeCaseKeys";

export type DashboardBuyersResType = { data: BuyerDbType[] } | ErrorResType;
export const fetchDashboardBuyers = async (
  filters: TableAllFiltersType,
): Promise<DashboardBuyersResType> => {
  const response = await safeFetch(
    addFilterQueryParams(
      `${endpoints.stitchBackend}/dashboard/buyers`,
      filters,
    ),
    {
      method: "GET",
      credentials: "include",
    },
  );
  return response;
};

export type DashboardBuyersSearchResType =
  | { data: BuyersSearchDbType[] }
  | ErrorResType;
export const fetchDashboardBuyersSearch = async (
  search: string,
): Promise<DashboardBuyersSearchResType> => {
  const response = await safeFetch(
    `${endpoints.stitchBackend}/dashboard/buyers/search?q=${search}`,
    {
      method: "GET",
      credentials: "include",
    },
  );
  return response;
};

export type DashboardBuyersCreateResType = { data: BuyerDbType } | ErrorResType;
export const fetchCreateBuyer = async (
  buyer: CreateBuyerFormTypes,
): Promise<DashboardBuyersCreateResType> => {
  const snakeCased = camelCaseToSnakeCaseKeys(buyer);
  const response = await safeFetch(
    `${endpoints.stitchBackend}/dashboard/buyers`,
    {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(snakeCased),
    },
  );
  return response;
};
