import { Grid, Typography } from "@mui/material";
import MainCard from "@pd/components/MainCard";
import formatIsoTimeWithTz from "@pd/utils/formatIsoTimeWithTz";
import { OrderDetailDbType } from "@pd/redux/types/dbTypes";
import StatusCell from "@pd/components/DynamicTable/StatusCell";
import formatCurrency from "@pd/utils/formatCurrency";
import CopyIdButton from "@pd/components/CopyIdButton";
import { TransferStatus } from "@pd/redux/types/transfers";

type Props = {
  order: OrderDetailDbType | null;
};

export default function OverviewSection(props: Props) {
  if (!props.order) return null;

  return (
    <MainCard title="Overview" darkTitle sx={{ mb: 5 }}>
      <Grid container spacing={4} sx={{ mb: 4 }}>
        <Grid item xs={12} md={3}>
          <Typography variant="h5" color="secondary">
            Reference id
          </Typography>
          <CopyIdButton
            id={props.order.referenceId}
            idStyle={{ fontSize: "16px" }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Typography variant="h5" color="secondary">
            Buyer
          </Typography>
          <Typography variant="h5">{props.order.buyerName}</Typography>
        </Grid>
        <Grid item xs={12} md={3}>
          <Typography variant="h5" color="secondary">
            Service date
          </Typography>
          <Typography variant="h5">
            {formatIsoTimeWithTz(props.order.serviceDate)}
          </Typography>
        </Grid>
        <Grid item xs={12} md={3}>
          <Typography variant="h5" color="secondary">
            Bill date
          </Typography>
          <Typography variant="h5">
            {formatIsoTimeWithTz(props.order.billDate)}
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item xs={12} md={3}>
          <Typography variant="h5" color="secondary">
            Total Billed
          </Typography>
          <Typography variant="h5">
            {formatCurrency(props.order.total)}
          </Typography>
        </Grid>
        <Grid item xs={12} md={3}>
          <Typography variant="h5" color="secondary">
            Vendor
          </Typography>
          <Typography variant="h5">{props.order.vendorName}</Typography>
        </Grid>
        <Grid item xs={12} md={3}>
          <Typography variant="h5" color="secondary">
            Payout Status
          </Typography>
          <Typography variant="h5">
            <StatusCell
              status={props.order.payoutStatus}
              type="order-payout-status"
            />
          </Typography>
        </Grid>
        {props.order.isFinanced && props.order.payoutAmount ? (
          <Grid item xs={12} md={3}>
            <Typography variant="h5" color="secondary">
              Repayment Status
            </Typography>
            <Typography variant="h5">
              <StatusCell
                status={props.order.repaymentStatus || TransferStatus.unpaid}
                type="order-payout-status"
              />
            </Typography>
          </Grid>
        ) : null}
      </Grid>
    </MainCard>
  );
}
