import { useState, useRef, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import {
  Box,
  Button,
  Stack,
  Typography,
  Tooltip,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/system";

import StitchLoading from "@pd/components/StitchLoading";
import da from "@pd/layouts/MktplaceDashboard/redux/actions";
import { useAppDispatch, useAppSelector } from "@pd/redux/store";
import {
  CheckOutlined,
  CloseOutlined,
  FileTextOutlined,
} from "@ant-design/icons";
import {
  selectCsvUploadError,
  selectCsvUploadLoading,
  selectCsvUploadSuccess,
} from "@pd/layouts/MktplaceDashboard/redux/selectors/orders/csvUpload";
import { CsvUploadOrdersResType } from "@pd/layouts/MktplaceDashboard/types/csvUpload";
import { FadeInStack, fadeInAnimation } from "@pd/components/FadeComponents";
import safeFetch from "@pd/api/utils/safeFetch";
import { endpoints } from "@pd/config/envSetup";
import { MANUAL_ERROR_CODE } from "@pd/utils/constants";

type Props = {
  onClose: () => void;
  onPickFile: (file: File | null) => void;
  onTabChange: (tabIndex: number) => void;
  pickedFile: File | null;
};

const FadeInCheckOutlined = styled(CheckOutlined)`
  animation: 0.5s ${fadeInAnimation};
`;

export default function FilePickerTab(props: Props) {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const apiError = useAppSelector(selectCsvUploadError);
  const apiSuccess = useAppSelector(selectCsvUploadSuccess);
  const apiFetching = useAppSelector(selectCsvUploadLoading);

  const [filePickError, setFilePickError] = useState("");
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  function onDrop(acceptedFiles: File[]) {
    try {
      if (!acceptedFiles.length) {
        setFilePickError(
          "No file selected. Please select a file and try again.",
        );
        return;
      }
      const fileData = acceptedFiles[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        const fileContent = event.target?.result;
        if (fileContent) {
          props.onPickFile(fileData);
        } else {
          setFilePickError(
            `Could not read file content for "${fileData.name}". Please check the file data and try again.`,
          );
        }
      };

      if (fileData) {
        reader.readAsText(fileData);
      } else {
        setFilePickError(
          `Could not read file content for file you provided. Please check the file and try again.`,
        );
      }
    } catch (err) {
      setFilePickError(
        "An error occurred while reading the file. Please try again.",
      );
    }
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handleBrowserClick = () => {
    props.onPickFile(null);
    setFilePickError("");
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    if (files?.length) {
      onDrop(Array.from(files));
    }
  };

  const handleCloseModal = () => {
    dispatch(da.orders.csvUpload.reset());
    props.onPickFile(null);
    setFilePickError("");
    props.onClose();
    if (apiSuccess) {
      dispatch(da.orders.all.getAllOrders());
    }
  };

  const handleRetry = () => {
    if (apiError.message) {
      dispatch(da.orders.csvUpload.apiError({ message: "", status: 0 }));
      props.onPickFile(null);
      setFilePickError("");
    }
  };

  async function handleUploadFile(fileToUpload: File | null) {
    if (fileToUpload) {
      dispatch(da.orders.csvUpload.apiSuccess(false));
      dispatch(da.orders.csvUpload.apiFetching(true));
      dispatch(da.orders.csvUpload.apiError({ message: "", status: 0 }));
      setFilePickError("");
      const formData = new FormData();
      formData.append("file", fileToUpload);
      await safeFetch(`${endpoints.stitchBackend}/dashboard/orders/csv`, {
        method: "POST",
        body: formData,
        credentials: "include",
      })
        .then((res: CsvUploadOrdersResType) => {
          if ("error" in res) {
            if (res.error.status === 422) {
              if (res.error.data) {
                dispatch(
                  da.orders.csvUpload.apiError({
                    message:
                      "Your file contains errors. Please fix then retry.",
                    status: MANUAL_ERROR_CODE,
                  }),
                );
                dispatch(
                  da.orders.csvUpload.setUploadReport(
                    res.error.data,
                    fileToUpload.name,
                  ),
                );
              } else {
                dispatch(
                  da.orders.csvUpload.apiError({
                    message: "An error occurred while uploading the file.",
                    status: MANUAL_ERROR_CODE,
                  }),
                );
              }
              dispatch(da.orders.csvUpload.apiFetching(false));
            } else {
              dispatch(da.orders.csvUpload.apiError(res.error));
              dispatch(da.orders.csvUpload.apiFetching(false));
              dispatch(da.orders.csvUpload.apiSuccess(false));
            }
          } else {
            dispatch(da.orders.csvUpload.apiFetching(false));
            dispatch(da.orders.csvUpload.apiSuccess(true));
          }
        })
        .catch((err) => {
          console.error(err);
          dispatch(
            da.orders.csvUpload.apiError({
              message:
                "An error occurred while uploading the file. Please try again.",
              status: MANUAL_ERROR_CODE,
            }),
          );
        });
    }
  }

  const renderFilesList = useCallback(
    () =>
      props.pickedFile ? (
        <Stack>
          <Stack
            direction="row"
            alignItems="center"
            gap={1}
            key={props.pickedFile.name}
          >
            {apiError.status ? (
              <Tooltip title="File has errors. Please retry.">
                <CloseOutlined
                  style={{ fontSize: "24px", color: theme.palette.error.main }}
                />
              </Tooltip>
            ) : (
              <Tooltip title="File read successfully">
                <FileTextOutlined style={{ fontSize: "18px" }} />
              </Tooltip>
            )}
            <pre style={{ margin: "0px" }}>{props.pickedFile?.name}</pre>
          </Stack>
        </Stack>
      ) : (
        <Typography>
          {"Drag 'n' drop a CSV file here, or click to select one"}
        </Typography>
      ),
    [props.pickedFile],
  );

  if (filePickError) {
    return (
      <Stack
        alignItems="center"
        justifyContent="space-around"
        style={{ height: "100%" }}
      >
        <Stack gap={2} alignItems="center" justifyContent="space-around">
          <Typography variant="h3">Sorry there was a problem</Typography>
          {filePickError ? (
            <Typography variant="body1">{filePickError}</Typography>
          ) : (
            <Typography variant="body1">
              {
                "There was an issue while trying to read your file. Please Try again."
              }
            </Typography>
          )}
        </Stack>
        <Stack direction="row" gap={1}>
          <Button
            variant="contained"
            onClick={handleRetry}
            sx={{ width: "100px" }}
          >
            Retry
          </Button>
        </Stack>
      </Stack>
    );
  }

  if (apiError.message) {
    return (
      <Stack
        alignItems="center"
        justifyContent="center"
        style={{ height: "100%" }}
        gap={4}
      >
        {apiError.status === MANUAL_ERROR_CODE ? (
          <Stack gap={2} alignItems="center" justifyContent="space-around">
            <Typography variant="h3">File Invalid</Typography>
            <Typography variant="body1">{apiError.message}</Typography>
          </Stack>
        ) : (
          <Stack gap={2} alignItems="center" justifyContent="space-around">
            <Typography variant="h3">Sorry there was a problem</Typography>
            <Box>
              <Typography
                variant="subtitle2"
                sx={{
                  fontFamily: "monospace",
                  color: theme.palette.error.darker,
                  backgroundColor: theme.palette.secondary.lighter,
                  border: `1px solid ${theme.palette.secondary.light}`,
                  borderRadius: "5px",
                  py: 3,
                  px: 2.5,
                }}
              >
                {apiError.message}
              </Typography>
            </Box>
          </Stack>
        )}
        <Button
          variant="contained"
          onClick={handleRetry}
          sx={{ width: "100px" }}
        >
          Retry
        </Button>
      </Stack>
    );
  }

  if (apiFetching) {
    return (
      <Stack
        alignItems="center"
        justifyContent="space-evenly"
        sx={{ height: "100%" }}
        gap={4}
      >
        <Stack alignItems="center">
          <StitchLoading size="medium" />
        </Stack>
      </Stack>
    );
  }

  if (apiSuccess) {
    return (
      <FadeInStack
        alignItems="center"
        justifyContent="space-evenly"
        sx={{ height: "100%" }}
        gap={4}
      >
        <Stack alignItems="center">
          <Stack direction="row" alignItems="center" gap={1}>
            <Typography variant="h4">Orders Created!</Typography>
            <FadeInCheckOutlined
              style={{ fontSize: "24px", color: theme.palette.success.darker }}
            />
          </Stack>
        </Stack>
        <Stack
          direction="row"
          gap={2}
          alignItems="center"
          justifyContent="center"
          sx={{ mt: 2 }}
        >
          <Button
            variant="contained"
            onClick={handleCloseModal}
            sx={{ width: "100px" }}
          >
            Ok
          </Button>
        </Stack>
      </FadeInStack>
    );
  }

  return (
    <Stack sx={{ height: "100%" }} gap={2}>
      <Stack
        alignItems="center"
        justifyContent="center"
        {...getRootProps()}
        sx={{
          height: "100%",
          width: "100%",
          border: "1px dashed black",
          borderRadius: "5px",
          padding: "20px",
          cursor: "pointer",
        }}
      >
        <input {...getInputProps()} />
        {isDragActive ? (
          <Typography>Drop a file here ...</Typography>
        ) : (
          renderFilesList()
        )}
      </Stack>
      <Stack
        direction="row"
        gap={2}
        alignItems="center"
        justifyContent="center"
        sx={{ mt: 2 }}
      >
        <Button variant="outlined" onClick={() => props.onTabChange(1)}>
          See Example
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() =>
            props.pickedFile
              ? handleUploadFile(props.pickedFile)
              : handleBrowserClick()
          }
          sx={{ width: "100px" }}
        >
          {props.pickedFile ? "Upload" : "Browse"}
        </Button>
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: "none" }}
          onChange={handleFileChange}
          accept=".csv"
          multiple
        />
      </Stack>
    </Stack>
  );
}
