import MiscColors from "@pd/mantis/themes/miscColors";
import { OrderDbType } from "@pd/redux/types/dbTypes";
import {
  OrderPayoutStatus,
  OrderStatus,
  OrderFinanceStatus,
} from "@pd/redux/types/orders";
import React from "react";

export interface OrderDataType {
  id: string;
  createdAt: string;
  referenceId: string;
  buyerId: string;
  buyerName: string;
  vendorId: string;
  vendorName: string;
  total: number;
  status: string;
  isFinanced: string;
}

export interface OrderActionsType {
  actionBtn: string;
}

export interface OrderColDefType {
  id: keyof OrderDataType | keyof OrderActionsType | "checkbox";
  label: string;
  index: number;
  component?: React.JSX.Element;
}

type OrderStatusColorType = {
  [OrderStatus.voided]: string;
  [OrderStatus.confirmed]: string;
  [OrderStatus.created]: string;
  [OrderStatus.unknown]: string;
};

type OrderFinanceStatusColorType = {
  [OrderFinanceStatus.yes]: string;
  [OrderFinanceStatus.no]: string;
};

type OrderPayoutStatusColorType = {
  [OrderPayoutStatus.failed]: string;
  [OrderPayoutStatus.voided]: string;
  [OrderPayoutStatus.pending]: string;
  [OrderPayoutStatus.complete]: string;
  [OrderPayoutStatus.unknown]: string;
};

const mColors = MiscColors();
export const ORDER_PAYOUT_STATUS_COLORS: OrderPayoutStatusColorType = {
  [OrderPayoutStatus.failed]: mColors.statusRedBg,
  [OrderPayoutStatus.voided]: mColors.statusRedBg,
  [OrderPayoutStatus.pending]: mColors.statusOrangeBg,
  [OrderPayoutStatus.complete]: mColors.statusGreenBg,
  [OrderPayoutStatus.unknown]: mColors.statusVioletBg,
};
export const ORDER_STATUS_COLORS: OrderStatusColorType = {
  [OrderStatus.created]: mColors.statusYellowBg,
  [OrderStatus.confirmed]: mColors.statusGreenBg,
  [OrderStatus.voided]: mColors.statusRedBg,
  [OrderStatus.unknown]: mColors.statusVioletBg,
};
export const ORDER_FINANCE_STATUS_COLORS: OrderFinanceStatusColorType = {
  [OrderFinanceStatus.yes]: mColors.statusVioletBg,
  [OrderFinanceStatus.no]: mColors.statusGrayBg,
};

export function isOrderStatusType(status: string): status is OrderStatus {
  return Object.values(OrderStatus).includes(status as OrderStatus);
}
export function isOrderPayoutStatusType(
  status: string,
): status is OrderPayoutStatus {
  return Object.values(OrderPayoutStatus).includes(status as OrderPayoutStatus);
}
export function isOrderFinanceStatusType(
  status: string,
): status is OrderFinanceStatus {
  return Object.values(OrderFinanceStatus).includes(
    status as OrderFinanceStatus,
  );
}

export type OrderStatusOptionType = {
  key: number;
  label: string;
  status: keyof typeof OrderStatus;
};
export type OrderPayoutStatusOptionType = {
  key: number;
  label: string;
  status: keyof typeof OrderPayoutStatus;
};

export function isOrderStatusOptionType(
  option: any,
): option is OrderStatusOptionType {
  return "label" in option && "status" in option;
}

export type OrdersForTableType = OrderDbType & {
  selected: boolean;
};

export type SelectionApiErrorType = {
  selectionCount: number;
  successCount: number;
  errorsTotal: number;
};
