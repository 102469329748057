export default function formatNumbers(n: string | number) {
  if (!n) {
    return "0.00";
  }
  try {
    let number = n;
    if (typeof number === "string") {
      number = parseFloat(number);
    }
    // Format to 2 decimal places and add commas
    return `${number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
  } catch (error) {
    console.error(error);
    return "0.00";
  }
}
