import { AppStateType } from "@pd/redux/store/reducers";
import { createSelector } from "@reduxjs/toolkit";
import {
  VendorInfoDbType,
  VendorOrdersDbType,
  VendorPayoutsDbType,
  VendorUnpaidOrdersDbType,
} from "@pd/redux/types/dbTypes";
import { isDashboardState } from "../../../types";

export const selectVendorCrudApiSuccess = createSelector(
  (state: AppStateType) => state,
  (state: AppStateType): boolean => {
    if (isDashboardState(state)) {
      return state.dashboard.vendors.crud.success;
    }
    return false;
  },
);

export const selectVendorCrudApiFetching = createSelector(
  (state: AppStateType) => state,
  (state: AppStateType): boolean => {
    if (isDashboardState(state)) {
      return state.dashboard.vendors.crud.fetching;
    }
    return false;
  },
);

export const selectVendorCrudApiError = createSelector(
  (state: AppStateType) => state,
  (state: AppStateType) => {
    if (isDashboardState(state)) {
      return state.dashboard.vendors.crud.error;
    }
    return { message: "", status: 0 };
  },
);

export const selectVendorCrudById = createSelector(
  (state: AppStateType) => state,
  (state: AppStateType): VendorInfoDbType | null => {
    if (isDashboardState(state)) {
      const data = state.dashboard.vendors.crud.data.vendorById;
      if (data) {
        return data;
      }
    }
    return null;
  },
);

export const selectVendorCrudOrdersHistory = createSelector(
  (state: AppStateType) => state,
  (state: AppStateType): VendorOrdersDbType[] => {
    if (isDashboardState(state)) {
      const data = state.dashboard.vendors.crud.data.ordersHistory;
      if (data) {
        return data;
      }
    }
    return [];
  },
);

export const selectVendorCrudPayoutsHistory = createSelector(
  (state: AppStateType) => state,
  (state: AppStateType): VendorPayoutsDbType[] => {
    if (isDashboardState(state)) {
      const data = state.dashboard.vendors.crud.data.payoutsHistory;
      if (data) {
        return data;
      }
    }
    return [];
  },
);

export const selectVendorCrudUnpaidOrders = createSelector(
  (state: AppStateType) => state,
  (state: AppStateType): VendorUnpaidOrdersDbType[] => {
    if (isDashboardState(state)) {
      const data = state.dashboard.vendors.crud.data.unpaidOrders;
      if (data) {
        return data;
      }
    }
    return [];
  },
);

export const selectInviteLink = createSelector(
  (state: AppStateType) => state,
  (state: AppStateType): string => {
    if (isDashboardState(state)) {
      const data = state.dashboard.vendors.crud.data.inviteLink;
      if (data) {
        return data;
      }
    }
    return "";
  },
);
