import { Stack } from "@mui/material";
import StitchLoading from "@pd/components/StitchLoading";

export default function DateLoading(props: { show: boolean }) {
  if (!props.show) return null;
  return (
    <Stack justifyContent="center" direction="row">
      <StitchLoading />
    </Stack>
  );
}
