import { useState, useEffect, useMemo } from "react";
import DynamicTableHOC from "@pd/components/DynamicTable";
import { TableRow, TableCell } from "@mui/material";

import { useAppDispatch, useAppSelector } from "@pd/redux/store";
import { SortDirType, stableSort, getComparator } from "@pd/utils/stableSort";
import {
  selectVendorCrudApiError,
  selectVendorCrudApiFetching,
  selectVendorCrudOrdersHistory,
} from "@pd/layouts/MktplaceDashboard/redux/selectors/vendors/crudVendors";
import da from "@pd/layouts/MktplaceDashboard/redux/actions";
import formatTimes from "@pd/utils/formatTimes";
import formatCurrency from "@pd/utils/formatCurrency";
import CopyIdButton from "@pd/components/CopyIdButton";

import { VendorsOrderHistoryTableDataType } from "../../types";
import { vendorOrderHistoryColumns } from "./tableSchemas";

type Props = {
  vendorId: string | undefined;
};
export default function OrderHistoryTab(props: Props) {
  const dispatch = useAppDispatch();
  const apiFetching = useAppSelector(selectVendorCrudApiFetching);
  const apiError = useAppSelector(selectVendorCrudApiError);
  const orderHistory = useAppSelector(selectVendorCrudOrdersHistory);
  const [uiFetching, setUiFetching] = useState(true);

  const [sortOrder, setSortOrder] = useState<SortDirType>("asc");
  const [orderBy, setOrderBy] =
    useState<keyof VendorsOrderHistoryTableDataType>("serviceDate");

  function handleRequestSort(_: React.MouseEvent<unknown>, property: any) {
    const isAsc = orderBy === property && sortOrder === "asc";
    setSortOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  }

  const rows = orderHistory || [];
  const sortedRows = useMemo(
    () => stableSort(rows, getComparator(sortOrder, orderBy)),
    [rows, sortOrder, orderBy],
  );

  useEffect(() => {
    if (!apiError.status && uiFetching) {
      setUiFetching(false);
    } else if (!apiFetching && uiFetching) {
      setUiFetching(false);
    }
  }, [apiError, apiFetching, uiFetching]);

  useEffect(() => {
    if (props.vendorId) {
      setUiFetching(true);
      dispatch(da.vendors.crud.getOrdersByVendorId(props.vendorId));
    }
    return () => {
      dispatch(da.vendors.crud.setOrders([]));
    };
  }, []);

  if (!props.vendorId) {
    return null;
  }

  return (
    <DynamicTableHOC
      columnDefs={vendorOrderHistoryColumns}
      onHeaderSort={handleRequestSort}
      order={sortOrder}
      orderBy={orderBy}
      tableSrc="orderHistory"
    >
      {sortedRows.map((row) => (
        <TableRow
          key={row.id}
          sx={{
            td: {
              fontFamily: "CircularRegular",
              fontSize: "14px",
              width: 150,
              overflowX: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            },
          }}
        >
          <TableCell>{formatTimes(row.serviceDate)}</TableCell>
          <TableCell>
            <CopyIdButton id={row.id} />
          </TableCell>
          <TableCell>
            <CopyIdButton id={row.referenceId} />
          </TableCell>
          <TableCell>{row.buyerName}</TableCell>
          <TableCell>{formatCurrency(row.deductions)}</TableCell>
          <TableCell>{formatCurrency(row.payout)}</TableCell>
        </TableRow>
      ))}
    </DynamicTableHOC>
  );
}
