import { Stack, Typography, Button } from "@mui/material";
import { FadeInStack } from "@pd/components/FadeComponents";
import GenericRetryError from "@pd/components/GenericRetryError";
import LocalErrorMsg from "@pd/components/LocalErrorMsg";
import StitchAsyncButton from "@pd/components/StitchAsyncButton";
import { ErrorPayload } from "@pd/api/utils/safeFetch";

type Props = {
  firstName: string;
  email: string;
  apiFetching: boolean;
  apiError: ErrorPayload;
  apiSuccess: boolean;
  onSubmit: () => void;
  onClose: () => void;
  onRetry: () => void;
};
export default function CreateLoginLink(props: Props) {
  return (
    <FadeInStack
      alignItems="center"
      justifyContent="space-between"
      gap={2}
      sx={{
        width: "100%",
        height: "100%",
        flexGrow: 1,
        p: 3,
      }}
    >
      <Typography variant="h5" align="center">
        Please confirm you would like to email {props.firstName} a login link.
      </Typography>
      <Stack
        data-testid="new-login-link"
        sx={{ minHeight: "50px", width: "50%" }}
        alignItems="center"
      >
        <Typography>{props.email}</Typography>
      </Stack>
      <Stack gap={1.5} sx={{ width: "75%", pb: 2 }}>
        <GenericRetryError error={props.apiError} onClick={props.onRetry} />
        <LocalErrorMsg error={props.apiError} />
        <StitchAsyncButton
          buttonText="Send login link"
          variant="contained"
          color="primary"
          logoColor="white"
          onClick={() => props.onSubmit()}
          success={props.apiSuccess}
          loading={props.apiFetching}
          loadingSize="small"
          loadingPosition={{ top: -31, left: 0 }}
          style={{
            height: "",
          }}
        />
        <Button variant="outlined" onClick={props.onClose}>
          Cancel
        </Button>
      </Stack>
    </FadeInStack>
  );
}
