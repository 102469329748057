import { Stack } from "@mui/material";

type Props = {
  children?: React.ReactNode; // eslint-disable-line react/require-default-props
  index: number;
  value: number;
  style?: object;
};
export default function TabPanel({
  children = null,
  style = {},
  index,
  value,
  ...other
}: Props) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{ height: "85%", ...style }}
      {...other}
    >
      {value === index && (
        <Stack gap={2} sx={{ p: 3, height: "100%" }}>
          {children}
        </Stack>
      )}
    </div>
  );
}
