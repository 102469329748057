import {
  Stack,
  Typography,
  FormControl,
  InputLabel,
  Box,
  ButtonBase,
  Tooltip,
  Button,
} from "@mui/material";
import { PlusCircleOutlined } from "@ant-design/icons";
import { OrderLineItemDbType } from "@pd/redux/types/dbTypes";
import LineItem from "../LineItem";

type Props = {
  disableOnReqFieldsMissing: boolean;
  disableOnConfirmed: boolean;
  lineItems: OrderLineItemDbType[];
  onChange: (name: string, value: string | number) => void;
  onAppend: () => void;
  onRemove: (index: number) => void;
};

export default function LineItemsInput(props: Props) {
  return (
    <Stack spacing={1} sx={{ mt: "25px", pl: "65px" }}>
      <InputLabel htmlFor="lineItems">
        <Stack direction="row" alignItems="center" gap={0.5}>
          <Typography>Line Items</Typography>
          <Typography variant="subtitle2" sx={{ pt: "6px" }}>
            **
          </Typography>
        </Stack>
      </InputLabel>
      <FormControl fullWidth>
        {props.lineItems.map((lineItem, index) => {
          if (props.disableOnReqFieldsMissing) {
            return (
              <Tooltip
                placement="top"
                arrow
                leaveDelay={300}
                title={
                  <Typography variant="body2">
                    This line item is disabled because you removed a required
                    field.
                  </Typography>
                }
                key={index /* eslint-disable-line  react/no-array-index-key */}
              >
                <Stack>
                  <LineItem
                    disableOnReqFieldsMissing={props.disableOnReqFieldsMissing}
                    shouldDisable={
                      props.disableOnConfirmed ||
                      props.disableOnReqFieldsMissing
                    }
                    hideRemove={props.lineItems.length === 1}
                    lineItem={lineItem}
                    index={index}
                    onChange={props.onChange}
                    onRemove={props.onRemove}
                  />
                </Stack>
              </Tooltip>
            );
          }
          return (
            <LineItem
              disableOnReqFieldsMissing={props.disableOnReqFieldsMissing}
              key={index /* eslint-disable-line  react/no-array-index-key */}
              shouldDisable={
                props.disableOnConfirmed || props.disableOnReqFieldsMissing
              }
              hideRemove={props.lineItems.length === 1}
              lineItem={lineItem}
              index={index}
              onChange={props.onChange}
              onRemove={props.onRemove}
            />
          );
        })}
      </FormControl>
      <Box sx={{ pt: 1, pr: 2.5, pb: 2.5 }}>
        {props.disableOnReqFieldsMissing ? (
          <Tooltip
            leaveDelay={300}
            title={
              <Typography variant="body2">
                Please fill out all required fields before adding a new line
                item.
              </Typography>
            }
            placement="top-start"
            arrow
          >
            <Button
              onClick={(_) => _}
              sx={{ py: 1, px: 1, cursor: "not-allowed" }}
            >
              <PlusCircleOutlined style={{ fontSize: "16px" }} />
              <Typography sx={{ ml: "25px" }}>Add a line item</Typography>
            </Button>
          </Tooltip>
        ) : (
          <ButtonBase
            disabled={props.disableOnConfirmed}
            onClick={props.onAppend}
            sx={{
              py: 1,
              px: 1,
              bgcolor: "transparent !important",
              cursor: "pointer",
            }}
          >
            <PlusCircleOutlined style={{ fontSize: "16px" }} />
            <Typography sx={{ ml: "25px" }}>Add a line item</Typography>
          </ButtonBase>
        )}
      </Box>
    </Stack>
  );
}
