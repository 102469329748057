type SnakeCaseable =
  | string
  | string[]
  | { [key: string]: any }
  | { [key: string]: any }[];

export default function camelCaseToSnakeCaseKeys(input: SnakeCaseable) {
  const snakeCaseString = (str: string): string =>
    str.replace(/[A-Z]/g, (letter, index) => {
      const replaceChar = index > 0 && str[index - 1] === "_" ? "" : "_";
      return index === 0
        ? letter.toLowerCase()
        : `${replaceChar}${letter.toLowerCase()}`;
    });

  const isArray = (obj: SnakeCaseable) => Array.isArray(obj);

  const isObject = (obj: SnakeCaseable) =>
    typeof obj === "object" && !isArray(obj);

  if (typeof input === "string") {
    return snakeCaseString(input);
  }
  // NOTE: The redundant "is not array" here prevents Typescript from
  // throwing a fit because it thinks `input[key]` can by type `any`.
  if (typeof input === "object" && !Array.isArray(input)) {
    if (isObject(input) && !isArray(input)) {
      const snaked: { [key: string]: SnakeCaseable } = {};
      Object.keys(input).forEach((key) => {
        if (typeof input === "object" && input != null && !isArray(input)) {
          const objValue =
            typeof input[key] === "string"
              ? input[key]
              : camelCaseToSnakeCaseKeys(input[key]);
          snaked[snakeCaseString(key)] = objValue;
        }
      });
      return snaked;
    }
  }
  if (isArray(input)) {
    return input.map((element: SnakeCaseable) => {
      const arrValue =
        typeof element === "string"
          ? element
          : camelCaseToSnakeCaseKeys(element);
      return arrValue;
    });
  }
  return input;
}
