import safeFetch, { ErrorResType } from "@pd/api/utils/safeFetch";
import { endpoints } from "@pd/config/envSetup";
import { ApiKeyDbType } from "@pd/redux/types/dbTypes";

export type ApiKeysResType = { data: ApiKeyDbType[] } | ErrorResType;
export const fetchApiKeys = async (): Promise<ApiKeysResType> => {
  const response = await safeFetch(
    `${endpoints.stitchBackend}/dashboard/apikeys`,
    {
      method: "GET",
      credentials: "include",
    },
  );
  return response;
};
