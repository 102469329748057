import {
  Alert,
  Dialog,
  DialogContent,
  DialogTitle,
  Snackbar,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import AuthGuard from "@pd/components/AuthGuard";

import { useCallback, useEffect, useState } from "react";
import { useAppDispatch } from "@pd/redux/store";
import da from "@pd/layouts/MktplaceDashboard/redux/actions";
import { FadeInStack } from "@pd/components/FadeComponents";
import { useLocation } from "react-router";
import ModalTitle from "@pd/components/ModalTitle";
import BuyersTable from "./BuyersTable";
import CreateEditBuyer from "./components/CreateEditBuyer";

export default function Buyers() {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const id = params.get("id");
  const dispatch = useAppDispatch();
  const [showCreateEditBuyer, setShowCreateEditBuyer] = useState(false);
  const [showAlertMsg, setShowAlertMsg] = useState(false);
  const [targetBuyerId, setTargetBuyerId] = useState("");

  const handleOnClose = useCallback((created: boolean) => {
    setShowCreateEditBuyer(false);
    setTargetBuyerId("");
    if (created) {
      setShowAlertMsg(true);
      dispatch(da.buyers.all.getBuyers());
    }
  }, []);

  useEffect(() => {
    dispatch(da.buyers.all.getBuyers());
  }, []);

  useEffect(() => {
    if (id) {
      setTargetBuyerId(id as string);
    }
  }, [id]);

  const theme = useTheme();

  const renderTable = () => (
    <>
      <Stack
        direction={{
          lg: "row",
          xs: "column",
        }}
        justifyContent="space-evenly"
        alignItems="flex-start"
        sx={{ my: "30px" }}
        spacing={2}
      >
        <Typography
          variant="h1"
          sx={{ fontWeight: 500, fontFamily: "CircularBold" }}
        >
          Buyers
        </Typography>
      </Stack>
      <Stack sx={{ width: "100%" }}>
        <BuyersTable
          onShowCreateBuyer={(newId) => {
            setShowCreateEditBuyer(true);
            if (newId) setTargetBuyerId(newId);
          }}
          // onSetBuyerId={(newId) => setTargetBuyerId(newId)}
        />
      </Stack>
    </>
  );

  const renderCreateEditBuyerModal = () => (
    <Dialog
      open={showCreateEditBuyer}
      onClose={() => handleOnClose(false)}
      scroll="paper"
      fullWidth
      maxWidth="sm"
      aria-labelledby="create-edit-buyer-title"
      PaperProps={{
        sx: {
          minHeight: "80vh",
          maxHeight: "80vh",
        },
      }}
    >
      <DialogTitle
        id="create-edit-buyer-title"
        height={"60px"}
        sx={{ pt: "16px" }}
      >
        <ModalTitle
          title="Add buyer"
          onClose={() => handleOnClose(false)}
          useBorderBottom={false}
        />
      </DialogTitle>

      <DialogContent
        dividers
        sx={{
          overflowX: "auto",
          overflowY: "auto",
          "::-webkit-scrollbar": {
            width: "12px",
            height: "7px",
          },
          "::-webkit-scrollbar-track": {
            background: "#F1F1F1",
          },
          "::-webkit-scrollbar-thumb": {
            background: "#ddd",
          },
          "::-webkit-scrollbar-thumb:hover": {
            background: "#333",
          },
        }}
      >
        <CreateEditBuyer
          buyerId={targetBuyerId}
          onBuyerCreated={() => handleOnClose(true)}
        />
      </DialogContent>
    </Dialog>
  );

  const renderBuyerCreatedAlert = () => (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      open={showAlertMsg}
      autoHideDuration={1500}
      onClose={() => setShowAlertMsg(false)}
      sx={{ mb: 5 }}
    >
      <Alert
        onClose={() => setShowAlertMsg(false)}
        severity="info"
        variant="filled"
        sx={{ width: "100%", backgroundColor: theme.palette.primary.main }}
      >
        Buyer created
      </Alert>
    </Snackbar>
  );

  return (
    <AuthGuard>
      <FadeInStack
        alignItems="flex-start"
        sx={{
          pt: "80px",
          px: "50px",
          width: "100%",
          height: "100%",
        }}
      >
        {renderTable()}
        {renderCreateEditBuyerModal()}
        {renderBuyerCreatedAlert()}
      </FadeInStack>
    </AuthGuard>
  );
}
