import { Stack, Tooltip, Typography } from "@mui/material";
import { InfoCircleOutlined } from "@ant-design/icons";
import StatusCell from "@pd/components/DynamicTable/StatusCell";
import { OrderPayoutStatus } from "@pd/redux/types/orders";

type Props = {
  status: keyof typeof OrderPayoutStatus;
  failureReason: string;
};
export default function VendorPaymentStatus(props: Props) {
  if (!props.failureReason) {
    return (
      <Stack direction="row" gap={1} alignItems="center">
        <StatusCell status={props.status} type="vendors/payoutHistory" />
      </Stack>
    );
  }

  return (
    <Stack direction="row" gap={1} alignItems="center">
      <StatusCell status={props.status} type="vendors/payoutHistory" />
      <Tooltip
        title={
          <Stack
            direction="row"
            sx={{ minWidth: "250px", mx: "5px" }}
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="subtitle2" sx={{ fontFamily: "CircularBold" }}>
              Error:
            </Typography>
            <pre>{props.failureReason}</pre>
          </Stack>
        }
        arrow
        sx={{ fontSize: "14px" }}
      >
        <InfoCircleOutlined style={{ fontSize: "16px" }} />
      </Tooltip>
    </Stack>
  );
}
