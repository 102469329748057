import { useState, useEffect } from "react";
import { Stack, Typography, Button, useTheme } from "@mui/material";
import { FadeInStack } from "@pd/components/FadeComponents";
import GenericRetryError from "@pd/components/GenericRetryError";
import LocalErrorMsg from "@pd/components/LocalErrorMsg";
import StitchAsyncButton from "@pd/components/StitchAsyncButton";
import { ErrorPayload } from "@pd/api/utils/safeFetch";
import {
  validateEmail,
  validateFirstName,
  validateLastName,
  validateReferenceId,
} from "@pd/utils/validation";

type Props = {
  firstName: string;
  lastName: string;
  referenceId: string;
  email: string;
  apiFetching: boolean;
  apiError: ErrorPayload;
  apiSuccess: boolean;
  setFirstName: (value: string) => void;
  setLastName: (value: string) => void;
  setReferenceId: (value: string) => void;
  setEmail: (value: string) => void;
  onSubmit: () => void;
  onClose: () => void;
  onRetry: () => void;
};
export default function CreateInvite(props: Props) {
  const theme = useTheme();
  const [showErrors, setShowErrors] = useState(false);
  const [uiErrors, setUiErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    referenceId: "",
  });

  const hasErrors =
    uiErrors.firstName ||
    uiErrors.lastName ||
    uiErrors.email ||
    uiErrors.referenceId;

  const handleValidationResult = (name: string) => (error: string) => {
    if (error) {
      setUiErrors((prev) => ({ ...prev, [name]: error }));
    } else {
      if (!hasErrors) {
        setShowErrors(false);
      }
      setUiErrors((prev) => ({ ...prev, [name]: "" }));
    }
  };

  const handleSubmit = () => {
    const firstNameIsValid = validateFirstName(
      props.firstName,
      handleValidationResult("firstName"),
    );
    const lastNameIsValid = validateLastName(
      props.lastName,
      handleValidationResult("lastName"),
    );
    const emailIsValid = validateEmail(
      props.email,
      handleValidationResult("email"),
    );
    const referenceIdIsvalid = validateReferenceId(
      props.referenceId,
      handleValidationResult("referenceId"),
    );

    const invalidInput = [
      !firstNameIsValid,
      !lastNameIsValid,
      !emailIsValid,
      !referenceIdIsvalid,
    ].some(Boolean);
    if (invalidInput) {
      setShowErrors(true);
      return;
    }

    if (!props.apiFetching) {
      props.onSubmit();
    }
  };

  const handleValidation = (name: string, value: string): boolean => {
    switch (name) {
      case "firstName":
        return validateFirstName(value, handleValidationResult(name));
      case "lastName":
        return validateLastName(value, handleValidationResult(name));
      case "email":
        return validateEmail(value, handleValidationResult(name));
      case "referenceId":
        return validateReferenceId(value, handleValidationResult(name));
      default:
        return false;
    }
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    handleValidation(name, value);
    if (name === "firstName") {
      props.setFirstName(value);
    } else if (name === "lastName") {
      props.setLastName(value);
    } else if (name === "email") {
      props.setEmail(value);
    } else if (name === "referenceId") {
      props.setReferenceId(value);
    }
  };

  const handleOnBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const valid = handleValidation(name, value);
    if (!valid) {
      setShowErrors(true);
    }
  };

  useEffect(() => {
    if (!hasErrors && showErrors) {
      setShowErrors(false);
    }
  }, [hasErrors]);

  return (
    <FadeInStack
      alignItems="center"
      justifyContent="space-between"
      gap={1}
      sx={{
        p: 3,
        width: "100%",
        height: "100%",
        flexGrow: 1,
      }}
    >
      <Typography variant="h5" align="center" sx={{ mb: 5 }}>
        Who should we send the invite to ?
      </Typography>
      <Stack
        data-testid="new-bank-account"
        gap={2}
        sx={{ minHeight: "300px", width: "75%" }}
      >
        <Stack>
          <input
            disabled={props.apiFetching}
            type="text"
            placeholder="First Name"
            name="firstName"
            value={props.firstName}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            onInput={() => setUiErrors((prev) => ({ ...prev, firstName: "" }))}
            className="firstName-input"
            style={{
              color: theme.palette.primary.main,
              border: `1px solid ${
                uiErrors.firstName && showErrors
                  ? theme.palette.error.main
                  : theme.palette.secondary.light
              }`,
              backgroundColor: "transparent",
              borderRadius: "4px",
              height: "40px",
              fontSize: "16px",
              lineHeight: "24px",
              fontFamily: "CircularRegular",
              paddingLeft: "10px",
              width: "100%",
            }}
          />
          <Typography
            variant="subtitle1"
            color="error"
            sx={{
              pt: "5px",
              pl: "10px",
              display: showErrors && uiErrors.firstName ? "block" : "none",
              lineHeight: "16px",
            }}
          >
            {uiErrors.firstName}
          </Typography>
        </Stack>
        <Stack>
          <input
            disabled={props.apiFetching}
            type="text"
            placeholder="Last Name"
            name="lastName"
            value={props.lastName}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            onInput={() => setUiErrors((prev) => ({ ...prev, lastName: "" }))}
            className="lastName-input"
            style={{
              color: theme.palette.primary.main,
              border: `1px solid ${
                uiErrors.lastName && showErrors
                  ? theme.palette.error.main
                  : theme.palette.secondary.light
              }`,
              backgroundColor: "transparent",
              borderRadius: "4px",
              height: "40px",
              fontSize: "16px",
              lineHeight: "24px",
              fontFamily: "CircularRegular",
              paddingLeft: "10px",
              width: "100%",
            }}
          />
          <Typography
            variant="subtitle1"
            color="error"
            sx={{
              pt: "5px",
              pl: "10px",
              display: showErrors && uiErrors.lastName ? "block" : "none",
              lineHeight: "16px",
            }}
          >
            {uiErrors.lastName}
          </Typography>
        </Stack>
        <Stack>
          <input
            disabled={props.apiFetching}
            type="text"
            placeholder="Reference id"
            name="referenceId"
            value={props.referenceId}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            onInput={() =>
              setUiErrors((prev) => ({ ...prev, referenceId: "" }))
            }
            className="referenceId-input"
            style={{
              color: theme.palette.primary.main,
              border: `1px solid ${
                uiErrors.referenceId && showErrors
                  ? theme.palette.error.main
                  : theme.palette.secondary.light
              }`,
              backgroundColor: "transparent",
              borderRadius: "4px",
              height: "40px",
              fontSize: "16px",
              lineHeight: "24px",
              fontFamily: "CircularRegular",
              paddingLeft: "10px",
              width: "100%",
            }}
          />
          <Typography
            variant="subtitle1"
            color="error"
            sx={{
              pt: "5px",
              pl: "10px",
              display: showErrors && uiErrors.referenceId ? "block" : "none",
              lineHeight: "16px",
            }}
          >
            {uiErrors.referenceId}
          </Typography>
        </Stack>
        <Stack>
          <input
            disabled={props.apiFetching}
            type="text"
            placeholder="Email"
            name="email"
            value={props.email}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            onInput={() => setUiErrors((prev) => ({ ...prev, email: "" }))}
            className="email-input"
            style={{
              color: theme.palette.primary.main,
              border: `1px solid ${
                uiErrors.email && showErrors
                  ? theme.palette.error.main
                  : theme.palette.secondary.light
              }`,
              backgroundColor: "transparent",
              borderRadius: "4px",
              height: "40px",
              fontSize: "16px",
              lineHeight: "24px",
              fontFamily: "CircularRegular",
              paddingLeft: "10px",
              width: "100%",
            }}
          />
          <Typography
            variant="subtitle1"
            color="error"
            sx={{
              pt: "5px",
              pl: "10px",
              display: showErrors && uiErrors.email ? "block" : "none",
              lineHeight: "16px",
            }}
          >
            {uiErrors.email}
          </Typography>
        </Stack>
      </Stack>
      <Stack gap={1.5} sx={{ width: "75%", pb: 2 }}>
        <GenericRetryError error={props.apiError} onClick={props.onRetry} />
        <LocalErrorMsg error={props.apiError} />
        <StitchAsyncButton
          buttonText="Send invite"
          variant="contained"
          color="primary"
          logoColor="white"
          onClick={() => handleSubmit()}
          success={props.apiSuccess}
          loading={props.apiFetching}
          loadingSize="small"
          loadingPosition={{ top: -31, left: 0 }}
          style={{
            height: "",
          }}
        />
        <Button variant="outlined" onClick={props.onClose}>
          Close
        </Button>
      </Stack>
    </FadeInStack>
  );
}
