import { takeLeading, all, put, call, delay } from "redux-saga/effects";
import { MANUAL_ERROR_CODE } from "@pd/utils/constants";
import {
  fetchAllTeamMembers,
  AllTeamMembersResType,
} from "@pd/api/dashboard/teams";
import da from "../../actions";

export default function* crudTeamSaga() {
  yield all([
    takeLeading(da.teams.all.getAllTeamMembers.toString(), onGetAllTeamMembers),
  ]);
}

function* onGetAllTeamMembers() {
  try {
    yield all([
      put(da.teams.all.apiFetching(true)),
      put(da.teams.all.apiError({ message: "", status: 0 })),
    ]);
    const res: AllTeamMembersResType = yield call(fetchAllTeamMembers);
    if ("error" in res) {
      yield all([
        put(da.teams.all.apiFetching(false)),
        put(da.teams.all.apiSuccess(false)),
        put(da.teams.all.apiError(res.error)),
      ]);
    } else {
      yield delay(400);
      yield all([
        put(da.teams.all.setAllTeamMembers(res.data)),
        put(da.teams.all.apiSuccess(true)),
        put(da.teams.all.apiFetching(false)),
      ]);
    }
  } catch (error) {
    const errMsg =
      "An error occured while fetching your Team Members. Please try again.";
    if (error instanceof Error) {
      console.error(error.message);
    }
    yield all([
      put(
        da.teams.all.apiError({
          message: errMsg,
          status: MANUAL_ERROR_CODE,
        }),
      ),
      put(da.teams.all.apiFetching(false)),
      put(da.teams.all.apiSuccess(false)),
    ]);
  }
}
