import { IconButton, Tooltip, Stack } from "@mui/material";
import { DeleteFilled } from "@ant-design/icons";
import { TeamMemberStatusType } from "../../../types";

type Props = {
  status: keyof typeof TeamMemberStatusType;
  rowId: string;
  userId: string;
  userRole: string;
  onDeleteMember: (id: string) => void;
};
export default function DeleteButton(props: Props) {
  if (props.userRole !== "admin" || props.status === "inactive") {
    return null;
  }

  if (props.userId === props.rowId) {
    return (
      <Tooltip title="You cannot deactivate yourself">
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{
            width: "50px",
            height: "50px",
            backgroundColor: "transparent",
            "&:hover": {
              cursor: "not-allowed",
              backgroundColor: "transparent",
            },
            "&:active::after": {
              backgroundColor: "transparent",
            },
          }}
        >
          <DeleteFilled
            style={{
              fontSize: "18px",
              color: "grey",
            }}
          />
        </Stack>
      </Tooltip>
    );
  }

  return (
    <Tooltip title="Deactivate Member">
      <IconButton
        sx={{
          width: "50px",
          height: "50px",
          backgroundColor: "transparent",
          "&:hover": {
            backgroundColor: "transparent",
            scale: "1.1",
            transition: "all 0.3s ease",
          },
          "&:active::after": {
            backgroundColor: "transparent",
          },
        }}
        onClick={() => props.onDeleteMember(props.rowId)}
      >
        <DeleteFilled
          style={{
            fontSize: "18px",
            color: "black",
          }}
        />
      </IconButton>
    </Tooltip>
  );
}
