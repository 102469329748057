import { useEffect, useState, useCallback } from "react";

import { Stack } from "@mui/material";
import { useAppDispatch, useAppSelector } from "@pd/redux/store";
import da from "@pd/layouts/MktplaceDashboard/redux/actions";
import {
  selectSearchedBuyers,
  selectAllBuyersFetching,
  selectAllBuyersError,
} from "@pd/layouts/MktplaceDashboard/redux/selectors/buyers/allBuyers";
import {
  selectSearchedVendors,
  selectAllVendorsFetching,
  selectAllVendorsError,
} from "@pd/layouts/MktplaceDashboard/redux/selectors/vendors/allVendors";
import {
  selectUiOrder,
  selectApiOrder,
  selectCrudOrderError,
  selectCrudOrderFetching,
  selectCrudOrderSuccess,
} from "@pd/layouts/MktplaceDashboard/redux/selectors/orders/crudOrder";
import StitchLoading from "@pd/components/StitchLoading";

import { OrderLineItemDbType } from "@pd/redux/types/dbTypes";
import { FadeInStack } from "@pd/components/FadeComponents";
import {
  VendorDeduction,
  BuyerBilling,
  HeaderSection,
  BuyerSelection,
  VendorSelection,
  ReferenceIdInput,
  LineItemsInput,
  RequiredFieldsMessage,
  BaseTotal,
  StatusMessage,
  SubmitButtons,
  BillDateInput,
  ServiceDateInput,
  FinancingToggle,
} from "./components";

import type { CreateOrderFormTypes } from "./types";

import "./editOrder.css";

type Props = {
  orderId: string;
  onCloseForm: () => void;
};
export default function CreateEditOrder(props: Props) {
  const { orderId = "" } = props;
  const dispatch = useAppDispatch();

  const apiFetching = useAppSelector(selectCrudOrderFetching);
  const apiError = useAppSelector(selectCrudOrderError);
  const apiSuccess = useAppSelector(selectCrudOrderSuccess);
  const apiOrder = useAppSelector(selectApiOrder);
  const uiOrder = useAppSelector(selectUiOrder);

  const buyers = useAppSelector(selectSearchedBuyers);
  const buyersFetching = useAppSelector(selectAllBuyersFetching);
  const buyersApiError = useAppSelector(selectAllBuyersError);

  const vendors = useAppSelector(selectSearchedVendors);
  const vendorsFetching = useAppSelector(selectAllVendorsFetching);
  const vendorsApiError = useAppSelector(selectAllVendorsError);

  const [onMounting, setOnMounting] = useState(true);
  const [uiFetching, setUiFetching] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);

  const disableOnConfirmed = apiOrder?.status === "confirmed";
  const disableOnReqFieldsMissing = [
    !uiOrder?.buyerId,
    !uiOrder?.vendorId,
    !uiOrder?.referenceId,
    !uiOrder?.serviceDate,
    !uiOrder?.billDate,
  ].some(Boolean);

  const showEdit = [orderId, apiOrder?.id].some(Boolean);

  const handleFetchOrderById = () => {
    setOnMounting(true);
    dispatch(da.orders.crud.clearUpsertOrder());
    if (orderId) {
      dispatch(da.orders.crud.getApiOrder(orderId));
    }
  };

  useEffect(() => {
    if (apiSuccess && !saveSuccess && uiFetching) {
      setSaveSuccess(true);
      setTimeout(() => {
        setSaveSuccess(false);
      }, 3000);
    }
    if (apiError.status && uiFetching) {
      setUiFetching(false);
    } else if (
      !apiFetching &&
      !buyersFetching &&
      !vendorsFetching &&
      onMounting
    ) {
      setOnMounting(false);
    } else if (!apiFetching && uiFetching) {
      setUiFetching(false);
    }
    if (apiSuccess) {
      setTimeout(() => dispatch(da.orders.crud.apiSuccess(false)), 2000);
    }
  }, [
    apiFetching,
    apiError,
    apiSuccess,
    buyersFetching,
    vendorsFetching,
    uiFetching,
    saveSuccess,
    onMounting,
  ]);

  const clearAsyncState = useCallback(() => {
    dispatch(da.orders.crud.apiError({ message: "", status: 0 }));
    dispatch(da.orders.crud.apiSuccess(false));
    dispatch(da.orders.crud.apiFetching(false));
  }, []);

  useEffect(() => {
    clearAsyncState();
    dispatch(da.orders.crud.clearUpsertOrder());
    handleFetchOrderById();
    return () => {
      clearAsyncState();
      dispatch(da.orders.crud.clearUpsertOrder());
    };
  }, []);

  function handleConfirmAndSave(values: CreateOrderFormTypes) {
    setUiFetching(true);
    setSaveSuccess(false);
    if (orderId || apiOrder?.id) {
      dispatch(da.orders.crud.editOrder(values, orderId || apiOrder!.id, true));
    } else {
      dispatch(da.orders.crud.createOrder(values, true));
    }
  }

  function handleOnSaveDraft(values: CreateOrderFormTypes) {
    setUiFetching(true);
    setSaveSuccess(false);
    if (orderId || apiOrder?.id) {
      dispatch(
        da.orders.crud.editOrder(values, orderId || apiOrder!.id, false),
      );
    } else {
      dispatch(da.orders.crud.createOrder(values, false));
    }
  }

  function handleUpdateTotals() {
    dispatch(da.orders.crud.updateOrderTotals());
  }

  function handleOnChange(
    key: string,
    values:
      | string
      | number
      | OrderLineItemDbType[]
      | { amount: number }
      | boolean,
  ) {
    dispatch(da.orders.crud.apiError({ message: "", status: 0 }));
    dispatch(da.orders.crud.setOrderUiData(key, values));
    if (key === "deduction" || key === "lineItems") {
      handleUpdateTotals();
    }
  }

  function handleChangeLineItem(name: string, value: string | number) {
    dispatch(da.orders.crud.apiError({ message: "", status: 0 }));
    if (!uiOrder?.lineItems) {
      return;
    }
    const targetIndex = +name.split("[")[1].split("]")[0];
    const targetKey = name.split(".")[1];
    const newLineItems = uiOrder.lineItems.map(
      (item: OrderLineItemDbType, index: number) => {
        if (index === targetIndex) {
          return {
            ...item,
            [targetKey]: value,
          };
        }
        return item;
      },
    );
    handleOnChange("lineItems", newLineItems);
    handleUpdateTotals();
  }

  function handleAppendLineItem() {
    handleOnChange("lineItems", [
      ...(uiOrder?.lineItems || []),
      {
        description: "",
        code: "",
        amount: NaN,
      },
    ]);
  }

  function handleRemoveLineItem(index: number) {
    if (!uiOrder?.lineItems) {
      return;
    }
    const newLineItems = uiOrder.lineItems.filter(
      (_: OrderLineItemDbType, i: number) => i !== index,
    );
    handleOnChange("lineItems", newLineItems);
    handleUpdateTotals();
  }

  function handleSearchBuyers(query: string) {
    dispatch(da.buyers.all.searchBuyers(query));
  }

  function handleSearchVendors(query: string) {
    dispatch(da.vendors.all.searchVendors(query));
  }

  if (apiError.status) {
    window.scrollTo(0, document.body.scrollHeight);
  }

  return (
    <FadeInStack
      key={props.orderId}
      style={{
        width: "100%",
        marginTop: "25px",
        padding: "0px 15%",
        marginBottom: "200px",
      }}
    >
      <HeaderSection onCloseForm={props.onCloseForm} showEdit={showEdit} />
      {onMounting ? (
        <Stack
          sx={{
            flexGrow: 1,
            width: "100%",
            height: "50vh",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <StitchLoading size="large" />
        </Stack>
      ) : (
        <>
          <BuyerSelection
            buyerId={uiOrder?.buyerId || ""}
            buyerName={uiOrder?.buyerName || ""}
            buyers={buyers}
            disableOnConfirmed={disableOnConfirmed}
            onSearchBuyers={handleSearchBuyers}
            onChange={handleOnChange}
          />
          <VendorSelection
            vendorId={uiOrder?.vendorId || ""}
            vendorName={uiOrder?.vendorName || ""}
            vendors={vendors}
            disableOnConfirmed={disableOnConfirmed}
            onSearchVendors={handleSearchVendors}
            onChange={handleOnChange}
          />
          <ReferenceIdInput
            disableOnConfirmed={disableOnConfirmed}
            referenceId={uiOrder?.referenceId || ""}
            onChange={handleOnChange}
          />
          <FinancingToggle
            disableOnConfirmed={disableOnConfirmed}
            isFinanced={uiOrder?.isFinanced || false}
            onChange={handleOnChange}
          />
          <ServiceDateInput
            disableOnConfirmed={disableOnConfirmed}
            serviceDate={uiOrder?.serviceDate || ""}
            onChange={handleOnChange}
          />
          <BillDateInput
            disableOnConfirmed={disableOnConfirmed}
            billDate={uiOrder?.billDate || ""}
            onChange={handleOnChange}
          />
          <LineItemsInput
            disableOnReqFieldsMissing={disableOnReqFieldsMissing}
            disableOnConfirmed={disableOnConfirmed}
            lineItems={uiOrder?.lineItems || []}
            onAppend={handleAppendLineItem}
            onChange={handleChangeLineItem}
            onRemove={handleRemoveLineItem}
          />
          <RequiredFieldsMessage />
          <BaseTotal total={apiOrder?.total || 0} />
          <BuyerBilling
            buyerMarkup={apiOrder?.markup || 0}
            buyerTotal={apiOrder?.billedTotal || 0}
          />
          <VendorDeduction
            distributedTotal={apiOrder?.distributedTotal || 0}
            amount={uiOrder?.deduction.amount || 0}
            onChange={handleOnChange}
          />
          <StatusMessage
            draftSuccess={Boolean(
              saveSuccess && apiOrder?.status === "created",
            )}
            confirmedSuccess={Boolean(
              saveSuccess && apiOrder?.status === "confirmed",
            )}
            loading={uiFetching}
            apiError={apiError || buyersApiError || vendorsApiError}
          />
          <SubmitButtons
            uiOrder={uiOrder}
            apiOrder={apiOrder}
            apiSuccess={apiSuccess}
            uiFetching={uiFetching}
            onSaveDraft={handleOnSaveDraft}
            onConfirmAndSave={handleConfirmAndSave}
            onCloseForm={props.onCloseForm}
          />
        </>
      )}
    </FadeInStack>
  );
}
