import { parseISO } from "date-fns";
import { format, utcToZonedTime } from "date-fns-tz";

export default function formatIsoTimeWithTz(isoDate: string) {
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  const dateInUserTimeZone = utcToZonedTime(parseISO(isoDate), timeZone);
  const formattedDate = format(dateInUserTimeZone, "MM/dd/yyyy");
  return formattedDate;
}

export function formatTimeWithFormat(isoDate: string, formatStr?: string) {
  if (!isoDate) return "";
  const dateInUserTimeZone = parseISO(isoDate);
  const formattedDate = format(dateInUserTimeZone, formatStr || "MM/dd/yyyy");
  return formattedDate;
}
