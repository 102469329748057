/* eslint-disable no-shadow */
export enum TransferSourceType {
  bankAccount = "bankAccount",
  card = "card",
}

export enum TransferMethodEnum {
  ACH = "ACH",
  WIRE = "WIRE",
}

export enum TransferTypeEnum {
  payout = "payout",
  payment = "payment",
  repayment = "repayment",
  advance = "advance",
}

export enum TransferStatus {
  pending = "pending",
  complete = "complete",
  cancelled = "cancelled",
  failed = "failed",
  unpaid = "unpaid",
  unknown = "unknown",
}

export enum TransferPaymentProcessorEnum {
  stripe = "stripe",
  astra = "astra",
}
