import { useState } from "react";
import { Modal, Fade, Stack } from "@mui/material";
import ModalTitle from "@pd/components/ModalTitle";

import { DatePickerBody } from "./components";
import DateLoading from "./components/Loading";

const modalStyles = {
  position: "absolute" as const,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  borderRadius: "5px",
  boxShadow: 24,
  minWidth: "670px",
  minHeight: "300px",
};

type Props = {
  fetching: boolean;
  startDate: string;
  endDate: string;
  onClose: () => void;
  onApplyFilter: (startDate: Date | null, endDate: Date | null) => void;
};
export default function FilterByDateModal(props: Props) {
  const [startDate, setStartDate] = useState<Date | null>(
    props.startDate ? new Date(props.startDate) : null,
  );
  const [endDate, setEndDate] = useState<Date | null>(
    props.endDate ? new Date(props.endDate) : null,
  );

  return (
    <Modal open disableEnforceFocus closeAfterTransition>
      <Fade in>
        <Stack gap={2} sx={modalStyles} justifyContent="flex-start">
          <ModalTitle title="Choose a time period" onClose={props.onClose} />
          <DatePickerBody
            show={!props.fetching}
            startDate={startDate}
            endDate={endDate}
            onSetStartDate={setStartDate}
            onSetEndDate={setEndDate}
            onApplyFilter={props.onApplyFilter}
          />
          <DateLoading show={props.fetching} />
        </Stack>
      </Fade>
    </Modal>
  );
}
