import { TableAllFiltersType } from "@pd/layouts/MktplaceDashboard/types/tables";

export default function addFilterQueryParams(
  _url: string,
  filters: TableAllFiltersType,
): string {
  let url = _url;
  const queryParams: string[] = [];

  queryParams.push(
    `limit=${encodeURIComponent(filters.pagination.limit || 25)}`,
  );
  queryParams.push(
    `offset=${encodeURIComponent(filters.pagination.offset || 0)}`,
  );

  if (filters.miscFilter.type && filters.miscFilter.value) {
    queryParams.push(
      `${encodeURIComponent(filters.miscFilter.type)}=${encodeURIComponent(
        filters.miscFilter.value,
      )}`,
    );
  }

  if (filters.dateFilter.startDate) {
    queryParams.push(
      `from=${encodeURIComponent(filters.dateFilter.startDate)}`,
    );
  }

  if (filters.dateFilter.endDate) {
    queryParams.push(`to=${encodeURIComponent(filters.dateFilter.endDate)}`);
  }

  if (filters.search.value) {
    queryParams.push(`search=${encodeURIComponent(filters.search.value)}`);
  }

  if (filters.pagination.sortKey) {
    queryParams.push(`sort=${encodeURIComponent(filters.pagination.sortKey)}`);
  }

  if (filters.pagination.sortDir) {
    queryParams.push(`dir=${encodeURIComponent(filters.pagination.sortDir)}`);
  }

  if (queryParams.length > 0) {
    url += `?${queryParams.join("&")}`;
  }

  return url;
}
