import type { TeamMemberColDefType } from "./types";

export const teamMembersSchema: readonly TeamMemberColDefType[] = [
  {
    index: 0,
    id: "actions",
    label: "Actions",
  },
  {
    index: 1,
    id: "name",
    label: "Name",
  },
  {
    index: 2,
    id: "email",
    label: "Email",
  },
  {
    index: 3,
    id: "phone",
    label: "Phone",
  },
  {
    index: 4,
    id: "role",
    label: "Role",
  },
  {
    index: 5,
    id: "status",
    label: "Status",
  },
  {
    index: 5,
    id: "timezone",
    label: "Time zone",
  },
];
