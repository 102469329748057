import { takeLeading, all, put, call, delay } from "redux-saga/effects";
import da from "@pd/layouts/MktplaceDashboard/redux/actions";
import { MANUAL_ERROR_CODE } from "@pd/utils/constants";
import { fetchApiKeys, ApiKeysResType } from "@pd/api/dashboard/settings";

export default function* settingsSaga() {
  yield takeLeading(da.settings.getApiKeys.toString(), onGetApiKeys);
}

function* onGetApiKeys() {
  try {
    yield all([
      put(da.settings.apiFetching(true)),
      put(da.settings.apiError({ message: "", status: 0 })),
    ]);
    const res: ApiKeysResType = yield call(fetchApiKeys);
    if ("error" in res) {
      yield all([
        put(da.settings.apiFetching(false)),
        put(da.settings.apiSuccess(false)),
        put(da.settings.apiError(res.error)),
      ]);
    } else {
      yield delay(400);
      yield all([
        put(da.settings.setApiKeys(res.data)),
        put(da.settings.apiSuccess(true)),
        put(da.settings.apiFetching(false)),
      ]);
    }
  } catch (error) {
    const errMsg =
      "An error occured while fetching your api keys. Please try again.";
    if (error instanceof Error) {
      console.error(error.message);
    }
    yield all([
      put(
        da.settings.apiError({
          message: errMsg,
          status: MANUAL_ERROR_CODE,
        }),
      ),
      put(da.settings.apiFetching(false)),
      put(da.settings.apiSuccess(false)),
    ]);
  }
}
