import { Box, ButtonBase, useTheme } from "@mui/material";
import { LeftOutlined } from "@ant-design/icons";

type Props = {
  onCloseForm: () => void;
};
export default function LeftBackBtn(props: Props) {
  const theme = useTheme();

  return (
    <Box sx={{ alignSelf: "flex-start", mr: "25px", mt: "10px" }}>
      <ButtonBase
        onClick={props.onCloseForm}
        sx={{
          fontSize: "24px",
          fontWeight: "500",
          p: "10px",
          borderRadius: "5px",
          "&:hover": {
            backgroundColor: theme.palette.secondary.lighter,
          },
        }}
      >
        <LeftOutlined />
      </ButtonBase>
    </Box>
  );
}
