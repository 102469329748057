import { TeamMemberDbType } from "@pd/redux/types/dbTypes";
import { TeamsTableDataType } from "../types";

export default function composeTableData(
  allTeamMembers: TeamMemberDbType[],
): TeamsTableDataType[] {
  return allTeamMembers.map((member) => ({
    id: member.id,
    name: `${member.firstName} ${member.lastName}`,
    email: member.email,
    phone: member.phone,
    role: member.role,
    timezone: member.preferredTimezone,
    status: member.active ? member.status : "inactive",
  }));
}
