import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { Stack, Typography, Box } from "@mui/material";

import { useAppDispatch, useAppSelector } from "@pd/redux/store";
import {
  selectVendorCrudApiFetching,
  selectVendorCrudApiError,
  selectVendorCrudById,
  selectVendorCrudUnpaidOrders,
  selectVendorCrudApiSuccess,
} from "@pd/layouts/MktplaceDashboard/redux/selectors/vendors/crudVendors";
import da from "@pd/layouts/MktplaceDashboard/redux/actions";
import StitchLoading from "@pd/components/StitchLoading";
import DashboardErrorBanner from "@pd/components/DashboardErrorBanner";
import formatCurrency from "@pd/utils/formatCurrency";
import { FadeInStack } from "@pd/components/FadeComponents";
import StitchAsyncButton from "@pd/components/StitchAsyncButton";
import VendorDetailsCard from "./components/VendorDetailsCard";
import UnpaidOrdersCard from "./components/UnpaidOrdersCard";
import VendorTableTabs from "./components/VendorTableTabs";
import LeftBackBtn from "./components/LeftBackBtn";
import StatusMessage from "./components/StatusMessage";
import PayoutSuccessModal from "./components/PayoutModal";
import RejectionMsg from "./components/RejectionMsg";

export default function VendorDetails() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams<{ vendorId: string }>();

  const apiFetching = useAppSelector(selectVendorCrudApiFetching);
  const apiError = useAppSelector(selectVendorCrudApiError);
  const apiSuccess = useAppSelector(selectVendorCrudApiSuccess);
  const vendorInfo = useAppSelector(selectVendorCrudById);
  const pendingPayouts = useAppSelector(selectVendorCrudUnpaidOrders);

  const [uiFetching, setUiFetching] = useState(true);
  const [payoutFetching, setPayoutFetching] = useState(false);
  const [payoutSuccess, setPayoutSuccess] = useState(false);
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (_: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const handleGetVendorById = () => {
    if (!params.vendorId) return;
    setUiFetching(true);
    dispatch(da.vendors.crud.getVendorById(params.vendorId));
    dispatch(da.vendors.crud.getOrdersByVendorId(params.vendorId));
  };

  const handlePayout = () => {
    if (!params.vendorId || payoutFetching) return;
    setPayoutFetching(true);
    dispatch(da.vendors.crud.payoutVendor(params.vendorId));
  };

  useEffect(() => {
    if (apiError.status && uiFetching) {
      setUiFetching(false);
    } else if (!apiFetching && uiFetching) {
      setUiFetching(false);
    } else if (apiError.status && payoutFetching) {
      setPayoutFetching(false);
    } else if (!apiFetching && payoutFetching) {
      if (apiSuccess) {
        setPayoutSuccess(true);
      }
      setPayoutFetching(false);
    }
  }, [apiError, apiFetching, uiFetching]);

  useEffect(() => {
    handleGetVendorById();
    return () => {
      dispatch(da.vendors.crud.clearVendorById());
    };
  }, []);

  if (uiFetching) {
    return (
      <FadeInStack
        direction="row"
        sx={{ width: "100%", mt: "25px", padding: "0px 15%" }}
      >
        <LeftBackBtn onCloseForm={() => navigate(-1)} />
        <Stack sx={{ width: "100%", mt: 1 }}>
          <Box
            sx={{
              width: "100%",
              pt: "100px",
              flexGrow: 1,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <StitchLoading />
          </Box>
        </Stack>
      </FadeInStack>
    );
  }

  return (
    <FadeInStack
      gap={2}
      justifyContent="space-between"
      sx={{
        width: "100%",
        mt: "25px",
      }}
    >
      <Stack direction="row">
        <LeftBackBtn onCloseForm={() => navigate(-1)} />
        <DashboardErrorBanner
          apiError={!vendorInfo ? apiError : { message: "", status: 0 }}
          onRetry={() => handleGetVendorById()}
        />
        <Stack sx={{ width: "50%", mt: 1 }}>
          <Typography variant="h1" sx={{ mb: 2, fontFamily: "CircularBold" }}>
            {`${vendorInfo?.firstName} ${vendorInfo?.lastName}`}
          </Typography>
          <Typography variant="h1" sx={{ mb: 2, fontFamily: "CircularBold" }}>
            {`${formatCurrency(vendorInfo?.payoutBalance || 0)} available`}
          </Typography>
        </Stack>
      </Stack>
      <Stack direction="row" gap={4}>
        <Stack sx={{ width: "30%" }}>
          <Stack>
            <VendorDetailsCard vendor={vendorInfo} />
            <UnpaidOrdersCard unpaidOrders={pendingPayouts} />
            <StitchAsyncButton
              buttonText="Pay out"
              variant="contained"
              color="primary"
              logoColor="white"
              onClick={() => handlePayout()}
              success={payoutSuccess}
              loading={payoutFetching}
              disabled={payoutSuccess || !vendorInfo?.payoutBalance}
              loadingSize="small"
              loadingPosition={{ top: -30, left: 0 }}
              buttonWidth={"100%"}
            />
            <StatusMessage
              loading={payoutFetching}
              apiError={apiError}
              success={payoutSuccess}
            />
            <PayoutSuccessModal success={payoutSuccess} />
          </Stack>
        </Stack>
        {vendorInfo?.rejectionReasons.length ? (
          <RejectionMsg rejectReasons={vendorInfo.rejectionReasons} />
        ) : (
          <VendorTableTabs tabValue={tabValue} onTabChange={handleTabChange} />
        )}
      </Stack>
    </FadeInStack>
  );
}
