import { useMemo, useState, useEffect } from "react";
import { Pagination, useTheme } from "@mui/material";
import { useAppDispatch, useAppSelector } from "@pd/redux/store";
import da from "@pd/layouts/MktplaceDashboard/redux/actions";
import MiscColors from "@pd/mantis/themes/miscColors";

import {
  selectTableFetching,
  selectTableLimit,
  selectTableTotalRows,
  selectTableOffset,
} from "@pd/layouts/MktplaceDashboard/redux/selectors/table";
import {
  TableDataSrcType,
  TablePaginationType,
} from "@pd/layouts/MktplaceDashboard/types/tables";

type Props = {
  order: "asc" | "desc";
  orderBy: string;
  tableSrc: TableDataSrcType;
  autoHide?: boolean;
};

export default function StitchPagination({
  autoHide = false,
  order,
  orderBy,
  tableSrc,
}: Props) {
  const theme = useTheme();
  const miscColors = MiscColors();
  const dispatch = useAppDispatch();
  const limit = useAppSelector(selectTableLimit);
  const total = useAppSelector(selectTableTotalRows);
  const offset = useAppSelector(selectTableOffset);
  const apiFetching = useAppSelector(selectTableFetching);
  const [page, setPage] = useState(1);
  const totalCount = useMemo(
    () => Math.ceil(total / limit) || 1,
    [total, limit],
  );

  useEffect(() => {
    if (offset === 0 && page !== 1) {
      setPage(1);
    }
  }, [offset]);

  const paginationParams: TablePaginationType = {
    offset,
    limit,
    total,
    sortDir: order,
    sortKey: orderBy,
    sortEnabled: false,
  };
  const handlePageChange = (_: React.ChangeEvent<unknown>, newPage: number) => {
    setPage(newPage);
    const newOffset = (newPage - 1) * limit;
    paginationParams.offset = newOffset;
    dispatch(da.table.setTablePagination(paginationParams));
    dispatch(
      da.table.getTableDataOnFilterChange({
        changeSrc: "pagination",
        dataSrc: tableSrc,
        filter: paginationParams,
      }),
    );
  };

  return (
    <Pagination
      variant="outlined"
      size="large"
      shape="rounded"
      page={page}
      disabled={apiFetching}
      count={totalCount}
      onChange={handlePageChange}
      sx={{
        display: autoHide && totalCount <= limit ? "none" : "block",
        py: 2,
        ".MuiButtonBase-root:hover": {
          borderColor: theme.palette.primary.main,
        },
        ".MuiPaginationItem-root.Mui-selected": {
          color: miscColors.paginationBlue,
          border: "1px solid #000",
        },
      }}
    />
  );
}
