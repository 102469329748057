import { useTheme } from "@mui/material";

export default function ExpiredLabel({ show }: { show: boolean }) {
  const theme = useTheme();

  return (
    <div
      style={{
        display: show ? "flex" : "none",
        backgroundColor: theme.palette.error.main,
        color: "white",
        borderRadius: "5px",
        width: "75px",
        height: "26px",
        fontSize: "12px",
        lineHeight: "16px",
        textAlign: "center",
        fontFamily: "CircularBold",
        textTransform: "capitalize",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      expired
    </div>
  );
}
