import { Stack, Typography, useTheme } from "@mui/material";
import { CloseCircleOutlined } from "@ant-design/icons";
import { ErrorPayload } from "@pd/api/utils/safeFetch";

type Props = {
  success: boolean;
  loading: boolean;
  apiError: ErrorPayload;
};
export default function StatusMessage(props: Props) {
  const theme = useTheme();

  if (props.success) return null;

  return (
    <Stack
      justifyContent="flex-start"
      sx={{ mt: "20px", minHeight: "50px" }}
      gap={3}
    >
      <Stack
        direction="row"
        gap={1}
        sx={{
          display: props.apiError.status && !props.loading ? "flex" : "none",
          alignItems: "center",
          mb: 3,
        }}
      >
        <CloseCircleOutlined
          style={{ color: theme.palette.error.main, fontSize: "18px" }}
        />
        <Typography
          variant="subtitle1"
          sx={{ color: theme.palette.error.main }}
          align="center"
        >
          {props.apiError.message ||
            "There was an unexpected issue with your payout request. Please try again."}
        </Typography>
      </Stack>
    </Stack>
  );
}
