import { SortDirType } from "@pd/utils/stableSort";
import { TableCell, TableHead, TableRow, TableSortLabel } from "@mui/material";
import { isOrderCheckboxColType } from "@pd/layouts/MktplaceDashboard/pages/Orders/components/OrdersTable/tableSchemas";
import { ColumnDefTypes, RowDataTypes } from "./types";

interface DyanmicTableHeaderProps {
  order: SortDirType;
  orderBy: string;
  columns: ColumnDefTypes;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof RowDataTypes,
  ) => void;
}
export default function DynamicTableHeader(props: DyanmicTableHeaderProps) {
  const { order, orderBy, onRequestSort } = props;
  const handleSortHeader =
    (property: keyof RowDataTypes) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead
      sx={{
        backgroundColor: "#FFF",
        borderTop: "none",
        borderBottomWidth: "1px",
      }}
    >
      <TableRow>
        {props.columns.map((columnDef) => {
          if (isOrderCheckboxColType(columnDef)) {
            const HeaderComponent = columnDef.component;
            if (HeaderComponent) {
              return HeaderComponent;
            }
            return null;
          }
          return (
            <TableCell
              key={columnDef.id}
              sortDirection={orderBy === columnDef.id ? order : false}
              sx={{
                width: 120,
                fontFamily: "CircularBold",
                fontSize: "12px",
                lineHeight: "14px",
              }}
            >
              <TableSortLabel
                active={orderBy === columnDef.id}
                direction={orderBy === columnDef.id ? order : "asc"}
                onClick={handleSortHeader(columnDef.id as keyof RowDataTypes)}
                sx={{ textTransform: "none" }}
              >
                {columnDef.label}
              </TableSortLabel>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}
