import {
  all,
  takeLatest,
  takeLeading,
  put,
  call,
  delay,
} from "redux-saga/effects";
import da from "@pd/layouts/MktplaceDashboard/redux/actions";
import type {
  GetVendorInfoByIdResType,
  GetPayoutsByVendorIdResType,
  GetOrdersByVendorIdResType,
  ConfirmVendorPayoutResType,
  VendorLinkResType,
  VendorLoginLinkResType,
} from "@pd/api/dashboard/vendors";
import {
  fetchGetVendorById,
  fetchPayoutsByVendorId,
  fetchOrdersByVendorId,
  fetchConfirmVendorPayout,
  fetchSendVendorInvite,
  fetchSendVendorLoginLink,
} from "@pd/api/dashboard/vendors";
import { VendorUnpaidOrdersDbType } from "@pd/redux/types/dbTypes";
import { MANUAL_ERROR_CODE } from "@pd/utils/constants";
import { OrderStatus } from "@pd/redux/types/orders";

export default function* crudVendorSaga() {
  yield all([
    takeLatest(da.vendors.crud.getVendorById, onGetVendorById),
    takeLeading(
      da.vendors.crud.getPayoutsByVendorId,
      onGetVendorPayoutsHistoryById,
    ),
    takeLeading(
      da.vendors.crud.getOrdersByVendorId,
      onGetVendorOrdersHistoryById,
    ),
    takeLeading(da.vendors.crud.payoutVendor, onPayoutVendor),
    takeLeading(da.vendors.crud.sendInvite, onSendInvite),
    takeLeading(da.vendors.crud.sendLoginLink, onSendLoginLink),
  ]);
}

function* onGetVendorById(
  action: ReturnType<typeof da.vendors.crud.getVendorById>,
) {
  try {
    yield all([
      put(da.vendors.crud.apiFetching(true)),
      put(da.vendors.crud.apiSuccess(false)),
      put(da.vendors.crud.apiError({ message: "", status: 0 })),
    ]);
    const res: GetVendorInfoByIdResType = yield call(
      fetchGetVendorById,
      action.payload.id,
    );
    if ("error" in res) {
      yield all([
        put(da.vendors.crud.apiError(res.error)),
        put(da.vendors.crud.apiSuccess(false)),
        put(da.vendors.crud.apiFetching(false)),
      ]);
    } else {
      yield put(da.vendors.crud.apiSuccess(true));
      yield put(da.vendors.crud.setVendorById(res.data));
      yield put(da.vendors.crud.apiFetching(false));
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      console.error(error.message);
    }
    yield all([
      put(da.vendors.crud.apiFetching(false)),
      put(da.vendors.crud.apiError({ message: "", status: 0 })),
    ]);
  }
}

function* onGetVendorPayoutsHistoryById(
  action: ReturnType<typeof da.vendors.crud.getPayoutsByVendorId>,
) {
  try {
    yield all([
      put(da.vendors.crud.apiFetching(true)),
      put(da.vendors.crud.apiSuccess(false)),
      put(da.vendors.crud.apiError({ message: "", status: 0 })),
    ]);
    const res: GetPayoutsByVendorIdResType = yield call(
      fetchPayoutsByVendorId,
      action.payload.id,
    );
    if ("error" in res) {
      yield all([
        put(da.vendors.crud.apiError(res.error)),
        put(da.vendors.crud.apiSuccess(false)),
        put(da.vendors.crud.apiFetching(false)),
      ]);
    } else {
      yield put(da.vendors.crud.apiSuccess(true));
      yield put(da.vendors.crud.setPayouts(res.data));
      yield put(da.vendors.crud.apiFetching(false));
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      console.error(error.message);
    }
    yield all([
      put(da.vendors.crud.apiFetching(false)),
      put(da.vendors.crud.apiError({ message: "", status: 0 })),
    ]);
  }
}

function* onGetVendorOrdersHistoryById(
  action: ReturnType<typeof da.vendors.crud.getOrdersByVendorId>,
) {
  try {
    yield all([
      put(da.vendors.crud.apiFetching(true)),
      put(da.vendors.crud.apiSuccess(false)),
      put(da.vendors.crud.apiError({ message: "", status: 0 })),
    ]);
    const res: GetOrdersByVendorIdResType = yield call(
      fetchOrdersByVendorId,
      action.payload.id,
    );
    if ("error" in res) {
      yield all([
        put(da.vendors.crud.apiError(res.error)),
        put(da.vendors.crud.apiSuccess(false)),
        put(da.vendors.crud.apiFetching(false)),
      ]);
    } else {
      yield all([
        put(da.vendors.crud.apiSuccess(true)),
        put(da.vendors.crud.apiFetching(false)),
      ]);
      const unpaidOrders = res.data
        .filter(
          (order) => !order.hasPayout && order.status === OrderStatus.confirmed,
        )
        .map(
          (n) =>
            ({
              id: n.id,
              payout: n.payout,
            }) as VendorUnpaidOrdersDbType,
        );
      const paidOrders = res.data.filter((order) => order.hasPayout);
      yield all([
        put(da.vendors.crud.setUnpaidOrders(unpaidOrders)),
        put(da.vendors.crud.setOrders(paidOrders)),
      ]);
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      console.error(error.message);
    }
    yield all([
      put(da.vendors.crud.apiFetching(false)),
      put(da.vendors.crud.apiError({ message: "", status: 0 })),
    ]);
  }
}

function* onPayoutVendor(
  action: ReturnType<typeof da.vendors.crud.payoutVendor>,
) {
  try {
    yield all([
      put(da.vendors.crud.apiFetching(true)),
      put(da.vendors.crud.apiSuccess(false)),
      put(da.vendors.crud.apiError({ message: "", status: 0 })),
    ]);
    const res: ConfirmVendorPayoutResType = yield call(
      fetchConfirmVendorPayout,
      action.payload.id,
    );
    if ("error" in res) {
      yield all([
        put(da.vendors.crud.apiError(res.error)),
        put(da.vendors.crud.apiSuccess(false)),
        put(da.vendors.crud.apiFetching(false)),
      ]);
    } else {
      yield delay(400);
      yield put(da.vendors.crud.apiSuccess(true));
      yield all([
        put(da.vendors.crud.apiFetching(false)),
        put(da.vendors.crud.getVendorById(action.payload.id)),
        put(da.vendors.crud.getPayoutsByVendorId(action.payload.id)),
        put(da.vendors.crud.getOrdersByVendorId(action.payload.id)),
      ]);
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      console.error(error.message);
    }
    yield all([
      put(da.vendors.crud.apiFetching(false)),
      put(da.vendors.crud.apiError({ message: "", status: 0 })),
    ]);
  }
}

function* onSendInvite(action: ReturnType<typeof da.vendors.crud.sendInvite>) {
  try {
    yield all([
      put(da.vendors.crud.apiFetching(true)),
      put(da.vendors.crud.apiSuccess(false)),
      put(da.vendors.crud.apiError({ message: "", status: 0 })),
    ]);
    const res: VendorLinkResType = yield call(
      fetchSendVendorInvite,
      action.payload.info,
    );
    if ("error" in res) {
      if (res.error.status === 400) {
        yield all([
          put(
            da.vendors.crud.apiError({
              message: res.error.message,
              status: MANUAL_ERROR_CODE,
            }),
          ),
          put(da.vendors.crud.apiSuccess(false)),
          put(da.vendors.crud.apiFetching(false)),
        ]);
      } else {
        yield all([
          put(da.vendors.crud.apiError(res.error)),
          put(da.vendors.crud.apiSuccess(false)),
          put(da.vendors.crud.apiFetching(false)),
        ]);
      }
    } else {
      yield all([
        put(da.vendors.crud.setInviteLink(res.data.link)),
        put(da.vendors.crud.apiSuccess(true)),
        put(da.vendors.crud.apiFetching(false)),
      ]);
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      console.error(error.message);
    }
    yield all([
      put(da.vendors.crud.apiFetching(false)),
      put(da.vendors.crud.apiError({ message: "", status: 0 })),
    ]);
  }
}

function* onSendLoginLink(
  action: ReturnType<typeof da.vendors.crud.sendLoginLink>,
) {
  try {
    yield all([
      put(da.vendors.crud.apiFetching(true)),
      put(da.vendors.crud.apiSuccess(false)),
      put(da.vendors.crud.apiError({ message: "", status: 0 })),
    ]);
    const res: VendorLoginLinkResType = yield call(
      fetchSendVendorLoginLink,
      action.payload.id,
    );
    if ("error" in res) {
      if (res.error.status === 400) {
        yield all([
          put(
            da.vendors.crud.apiError({
              message: res.error.message,
              status: MANUAL_ERROR_CODE,
            }),
          ),
          put(da.vendors.crud.apiSuccess(false)),
          put(da.vendors.crud.apiFetching(false)),
        ]);
      } else {
        yield all([
          put(da.vendors.crud.apiError(res.error)),
          put(da.vendors.crud.apiSuccess(false)),
          put(da.vendors.crud.apiFetching(false)),
        ]);
      }
    } else {
      yield all([
        put(da.vendors.crud.setInviteLink(res.data.link)),
        put(da.vendors.crud.apiSuccess(true)),
        put(da.vendors.crud.apiFetching(false)),
      ]);
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      console.error(error.message);
    }
    yield all([
      put(da.vendors.crud.apiFetching(false)),
      put(da.vendors.crud.apiError({ message: "", status: 0 })),
    ]);
  }
}
