import { useEffect } from "react";
import { Modal, Fade, Stack } from "@mui/material";
import ModalTitle from "@pd/components/ModalTitle";
import { useAppDispatch, useAppSelector } from "@pd/redux/store";
import pd from "@pd/layouts/MktplaceDashboard/redux/actions";
import {
  selectVendorCrudApiSuccess,
  selectVendorCrudApiFetching,
  selectVendorCrudApiError,
  selectInviteLink,
} from "@pd/layouts/MktplaceDashboard/redux/selectors/vendors/crudVendors";
import StitchLoading from "@pd/components/StitchLoading";

import { InviteSentDialogue, CreateLoginLink } from "./components";

const modalStyles = {
  position: "absolute" as const,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  borderRadius: "5px",
  boxShadow: 24,
  minWidth: "500px",
  maxWidth: "400px",
  minHeight: "300px",
};

type Props = {
  firstName: string;
  email: string;
  vendorId: string;
  onClose: () => void;
};
export default function SendLoginLinkModal(props: Props) {
  const dispatch = useAppDispatch();

  const apiFetching = useAppSelector(selectVendorCrudApiFetching);
  const apiSuccess = useAppSelector(selectVendorCrudApiSuccess);
  const apiError = useAppSelector(selectVendorCrudApiError);
  const inviteLink = useAppSelector(selectInviteLink);

  const handleCloseModal = () => {
    dispatch(pd.vendors.crud.apiSuccess(false));
    dispatch(pd.vendors.crud.apiError({ message: "", status: 0 }));
    props.onClose();
  };

  const handleSendInvite = () => {
    dispatch(pd.vendors.crud.sendLoginLink(props.vendorId));
  };

  useEffect(() => {
    dispatch(pd.vendors.crud.apiSuccess(false));
    dispatch(pd.vendors.crud.apiError({ message: "", status: 0 }));
    return () => {
      dispatch(pd.vendors.crud.apiSuccess(false));
      dispatch(pd.vendors.crud.apiError({ message: "", status: 0 }));
    };
  }, []);

  const handleRetry = () => {
    dispatch(pd.vendors.crud.apiError({ message: "", status: 0 }));
  };

  const renderContent = () => {
    if (apiFetching) {
      return (
        <Stack
          sx={{
            width: "100%",
            height: "100%",
            minHeight: "500px",
            flexGrow: 1,
          }}
          alignItems="center"
          justifyContent="center"
        >
          <StitchLoading size="medium" />
        </Stack>
      );
    }

    if (apiSuccess) {
      return (
        <InviteSentDialogue link={inviteLink} onClose={handleCloseModal} />
      );
    }

    return (
      <CreateLoginLink
        firstName={props.firstName}
        email={props.email}
        apiFetching={apiFetching}
        apiError={apiError}
        apiSuccess={apiSuccess}
        onSubmit={handleSendInvite}
        onClose={handleCloseModal}
        onRetry={handleRetry}
      />
    );
  };

  return (
    <Modal open disableEnforceFocus closeAfterTransition>
      <Fade in>
        <Stack sx={modalStyles} justifyContent="flex-start">
          <ModalTitle title="Send login link" onClose={props.onClose} />
          {renderContent()}
        </Stack>
      </Fade>
    </Modal>
  );
}
