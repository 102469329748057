import { useMemo, useEffect, useCallback } from "react";
import { TableRow, TableCell, Stack, Typography } from "@mui/material";
import DynamicTableHOC from "@pd/components/DynamicTable";
import { stableSort, getComparator, SortDirType } from "@pd/utils/stableSort";
import { useAppDispatch, useAppSelector } from "@pd/redux/store";
import da from "@pd/layouts/MktplaceDashboard/redux/actions";
import formatPhone from "@pd/utils/formatPhone";
import {
  selectAllBuyers,
  selectAllBuyersFiltered,
} from "@pd/layouts/MktplaceDashboard/redux/selectors/buyers/allBuyers";
import StitchLoading from "@pd/components/StitchLoading";
import { FadeInBox } from "@pd/components/FadeComponents";
import CopyIdButton from "@pd/components/CopyIdButton";
import {
  selectHasTableFilters,
  selectSortDir,
  selectSortKey,
  selectSortEnabled,
  selectTableTotalRows,
  selectTableOffset,
  selectTableLimit,
  selectTableFetching,
} from "@pd/layouts/MktplaceDashboard/redux/selectors/table";
import { TablePaginationType } from "@pd/layouts/MktplaceDashboard/types/tables";

import { buyersColumns } from "./tableSchemas";
import { BuyersTableDataType } from "../types";
import BuyerHeaderActions from "./BuyerHeaderActions";

type Props = {
  onShowCreateBuyer: (id?: string | undefined) => void;
  // onSetBuyerId: (id: string) => void;
};
export default function BuyersTable(props: Props) {
  const dispatch = useAppDispatch();
  const apiFetching = useAppSelector(selectTableFetching);
  // const apiError = useAppSelector(selectAllBuyersError);
  const allBuyers = useAppSelector(selectAllBuyers);
  const hasFilters = useAppSelector(selectHasTableFilters);
  const filteredBuyers = useAppSelector(selectAllBuyersFiltered);
  const totalRows = useAppSelector(selectTableTotalRows);
  const offset = useAppSelector(selectTableOffset);
  const limit = useAppSelector(selectTableLimit);
  const sortDir = useAppSelector(selectSortDir);
  const sortKey = useAppSelector(selectSortKey);
  const sortEnabled = useAppSelector(selectSortEnabled);

  useEffect(() => {
    dispatch(da.table.getTableData({ dataSrc: "buyers" }));
    return () => {
      dispatch(da.table.clearTableFilters());
    };
  }, []);

  const handleRequestSort = useCallback(
    (_: any, newSortKey: string) => {
      if (!sortEnabled) {
        return;
      }
      const sortFilter: TablePaginationType = {
        total: totalRows,
        offset,
        limit,
        sortDir: sortDir === "asc" ? "desc" : "asc",
        sortKey: newSortKey,
        sortEnabled: false,
      };
      if (newSortKey !== sortKey) {
        sortFilter.sortDir = sortDir;
      }
      dispatch(
        da.table.getTableDataOnFilterChange({
          changeSrc: "filters",
          dataSrc: "buyers",
          filter: sortFilter,
        }),
      );
    },
    [sortEnabled, totalRows, offset, limit, sortDir, sortKey],
  );

  const rows = hasFilters ? filteredBuyers : allBuyers;
  const sortedRows = useMemo(
    () =>
      stableSort(
        rows,
        getComparator(
          sortDir as SortDirType,
          sortKey as keyof BuyersTableDataType,
        ),
      ),
    [rows, sortDir, sortKey],
  );

  const renderTable = () => {
    if (!apiFetching && !allBuyers.length) {
      return (
        <FadeInBox
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            minHeight: "400px",
            width: "100%",
            flexGrow: 1,
            borderColor: "grey.300",
          }}
        >
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Typography variant="h4">No Buyers</Typography>
            <Typography variant="subtitle1" textAlign="center">
              Looks like you {"don't"} have any buyers. <br /> Add buyers via
              the API to get started.
            </Typography>
          </Stack>
        </FadeInBox>
      );
    }
    if (apiFetching && !allBuyers.length) {
      return (
        <FadeInBox
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            minHeight: "400px",
            width: "100%",
            flexGrow: 1,
          }}
        >
          <StitchLoading size="large" />
        </FadeInBox>
      );
    }

    return (
      <DynamicTableHOC
        columnDefs={buyersColumns}
        onHeaderSort={handleRequestSort}
        order={sortDir}
        orderBy={sortKey as keyof BuyersTableDataType}
        tableSrc="buyers"
        showLoading={apiFetching}
        autoHidePagination={!sortedRows.length}
      >
        {sortedRows.map((row) => (
          <TableRow
            key={row.id}
            sx={{
              td: {
                fontFamily: "CircularRegular",
                fontSize: "14px",
                maxWidth: "140px",
                overflowX: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              },
            }}
          >
            <TableCell>{row.name}</TableCell>
            <TableCell>{formatPhone(row.phone)}</TableCell>
            <TableCell>
              {row.extId && <CopyIdButton id={row.extId} />}
            </TableCell>
            <TableCell>
              <CopyIdButton id={row.id} />
            </TableCell>
          </TableRow>
        ))}
      </DynamicTableHOC>
    );
  };

  return (
    <Stack sx={{ width: "100%", flexGrow: 1 }}>
      <BuyerHeaderActions onShowCreateBuyer={props.onShowCreateBuyer} />
      {renderTable()}
    </Stack>
  );
}
