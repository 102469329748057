import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Button, Stack, Typography } from "@mui/material";
import StitchDatePicker from "@pd/components/StitchDatePicker";

type Props = {
  show: boolean;
  endDate: Date | null;
  startDate: Date | null;
  onSetStartDate: (date: Date | null) => void;
  onSetEndDate: (date: Date | null) => void;
  onApplyFilter: (startDate: Date | null, endDate: Date | null) => void;
};

export default function DatePickerBody(props: Props) {
  if (!props.show) return null;

  const handleOnClear = () => {
    if (props.endDate || props.startDate) {
      props.onApplyFilter(null, null);
      props.onSetStartDate(null);
      props.onSetEndDate(null);
    }
  };

  return (
    <Stack
      gap={5}
      alignItems="center"
      justifyContent="center"
      sx={{ my: "auto", p: 4 }}
    >
      <Typography variant="body1">
        Select a start and end date to filter orders due between
      </Typography>
      <Stack direction="row" gap={2} alignItems="center">
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <StitchDatePicker
            label="Start"
            value={props.startDate}
            onChange={props.onSetStartDate}
            minDate={new Date("2024-01-01")}
            maxDate={dayjs().add(90, "day").toDate()}
            style={{ maxWidth: "240px" }}
          />
          <Typography variant="body1">To</Typography>
          <StitchDatePicker
            label="End"
            value={props.endDate}
            onChange={props.onSetEndDate}
            minDate={props.startDate ? props.startDate : new Date("2024-01-01")}
            maxDate={dayjs().add(90, "day").toDate()}
            style={{ maxWidth: "240px" }}
          />
        </LocalizationProvider>
      </Stack>
      <Stack gap={2} sx={{ width: "100%" }} alignItems="center">
        <Button
          sx={{ width: "100%", maxWidth: "530px" }}
          variant="outlined"
          onClick={handleOnClear}
          disabled={!props.startDate && !props.endDate}
        >
          Clear
        </Button>
        <Button
          sx={{ width: "100%", maxWidth: "530px" }}
          variant="contained"
          onClick={() => props.onApplyFilter(props.startDate!, props.endDate!)}
          disabled={!props.startDate || !props.endDate}
        >
          Apply
        </Button>
      </Stack>
    </Stack>
  );
}
