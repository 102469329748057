export default function formatCurrency(a: string | number) {
  try {
    if (!a) return "$ 0.00";
    let amount = a;
    if (typeof amount === "string") {
      amount = parseFloat(amount);
    }
    if (Number.isNaN(amount)) {
      return "$ 0.00";
    }
    const isInteger = Number.isInteger(amount);
    let dollars = 0;
    if (isInteger) {
      dollars = amount / 100;
    } else {
      dollars = amount;
    }
    const result = `$ ${dollars
      .toFixed(2)
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
    if (["$ 0.00", "$ -0.00"].includes(result) && dollars !== 0) {
      return `$ ${dollars.toFixed(4)}`;
    }
    return result;
  } catch (error) {
    console.error("Error while formatting currency", error);
    return "$ 0.00";
  }
}
