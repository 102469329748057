import type { VendorOrderHistoryColDefType } from "../../types";

export const vendorOrderHistoryColumns: readonly VendorOrderHistoryColDefType[] =
  [
    {
      index: 1,
      id: "date",
      label: "Date",
    },
    {
      index: 2,
      id: "id",
      label: "Order id",
    },
    {
      index: 3,
      id: "referenceId",
      label: "Reference id",
    },
    {
      index: 4,
      id: "buyerName",
      label: "Buyer",
    },
    {
      index: 5,
      id: "deduction",
      label: "Deductions",
    },
    {
      index: 6,
      id: "payoutTotal",
      label: "Payout",
    },
  ];
