import { Stack, Typography, Box, ButtonBase, useTheme } from "@mui/material";
import { LeftOutlined } from "@ant-design/icons";

type Props = {
  showEdit: boolean;
  onCloseForm: () => void;
};
export default function HeaderSection(props: Props) {
  const theme = useTheme();

  return (
    <Stack direction="row" alignItems="center">
      <Box sx={{ alignSelf: "flex-start", mr: "25px" }}>
        <ButtonBase
          onClick={props.onCloseForm}
          sx={{
            fontSize: "24px",
            fontWeight: "500",
            p: "10px",
            borderRadius: "5px",
            "&:hover": {
              backgroundColor: theme.palette.secondary.lighter,
            },
          }}
        >
          <LeftOutlined />
        </ButtonBase>
      </Box>
      <Stack spacing={1}>
        <Typography variant="h1">
          {props.showEdit ? "Edit" : "Create"} Order
        </Typography>
        <Typography variant="body1">
          {props.showEdit ? "Edit" : "Add"} an order.
        </Typography>
      </Stack>
    </Stack>
  );
}
