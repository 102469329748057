import { useState, useEffect } from "react";
import {
  TableCell,
  Checkbox,
  MenuItem,
  Menu,
  IconButton,
  Stack,
  useTheme,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useAppDispatch, useAppSelector } from "@pd/redux/store";
import da from "@pd/layouts/MktplaceDashboard/redux/actions";
import {
  selectAllOrders,
  selectSelectedOrders,
} from "@pd/layouts/MktplaceDashboard/redux/selectors/orders/allOrders";
import { LeftOutlined } from "@ant-design/icons";
import { useTableSelection } from "@pd/layouts/MktplaceDashboard/pages/Orders/providers/TableSelectionContext";
import { selectTableFilters } from "@pd/layouts/MktplaceDashboard/redux/selectors/table";

import "./style.css";
import { OrderStatus } from "@pd/redux/types/orders";

type SelectedOrdersMetaData = {
  createdOrdersCount: number;
  confirmedOrdersCount: number;
};

export default function OrderRowSelectionCol() {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const allOrders = useAppSelector(selectAllOrders);
  const selectedOrders = useAppSelector(selectSelectedOrders);
  const tableFilters = useAppSelector(selectTableFilters);
  const { onSelectAction, onResetSelection } = useTableSelection();

  const { createdOrdersCount, confirmedOrdersCount } = allOrders.reduce(
    (acc, n): SelectedOrdersMetaData => {
      if (n.status === OrderStatus.created) {
        acc.createdOrdersCount += 1; // eslint-disable-line no-param-reassign
      } else if (n.status === OrderStatus.confirmed) {
        acc.confirmedOrdersCount += 1; // eslint-disable-line no-param-reassign
      }
      return acc;
    },
    {
      createdOrdersCount: 0,
      confirmedOrdersCount: 0,
    } as SelectedOrdersMetaData,
  );

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  useEffect(() => () => onResetSelection(), []);

  const [showActionsMenu, setShowActionsMenu] = useState(false);
  const [targetStatus, setTargetStatus] = useState<string | undefined>(
    undefined,
  );

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      dispatch(
        da.orders.all.setSelectedOrders(allOrders.map((order) => order.id)),
      );
    } else {
      dispatch(da.orders.all.setSelectedOrders([]));
    }
  };

  const handleSelectByStatus = (status: OrderStatus | undefined): void => {
    dispatch(da.orders.all.getSelectedOrders(status));
    setShowActionsMenu(true);
    setTargetStatus(status);
    if (!status) {
      setAnchorEl(null);
      setShowActionsMenu(false);
    }
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    if (
      [
        tableFilters.miscFilter,
        tableFilters.miscFilter.type === "status",
        ["created", "confirmed"].includes(tableFilters.miscFilter.value),
      ].every(Boolean)
    ) {
      handleSelectByStatus(tableFilters.miscFilter.value as OrderStatus);
    }
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setShowActionsMenu(false);
    setTargetStatus(undefined);
  };

  const handleDeleteSelected = () => {
    dispatch(da.orders.all.deleteSelectedOrders());
    onSelectAction("delete");
    handleMenuClose();
  };

  const handleConfirmSelected = () => {
    dispatch(da.orders.all.confirmSelectedOrders());
    onSelectAction("confirm");
    handleMenuClose();
  };

  const handleVoidSelected = () => {
    dispatch(da.orders.all.voidSelectedOrders());
    onSelectAction("void");
    handleMenuClose();
  };

  return (
    <TableCell padding="checkbox">
      <Stack direction="row">
        <Checkbox
          indeterminate={
            selectedOrders.length > 0 &&
            selectedOrders.length < allOrders.length
          }
          checked={
            allOrders.length > 0 && selectedOrders.length === allOrders.length
          }
          onChange={handleSelectAllClick}
          inputProps={{ "aria-label": "select all orders" }}
        />
        <IconButton aria-label="actions" onClick={handleMenuOpen}>
          <MoreVertIcon />
        </IconButton>
      </Stack>
      <div
        className={`menu-container ${showActionsMenu ? "show-actions" : ""}`}
      >
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl) && !showActionsMenu}
          onClose={handleMenuClose}
          sx={{
            width: "200px",
            "& .MuiPaper-root": {
              boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
            },
          }}
        >
          <MenuItem
            sx={{
              width: "150px",
              cursor: "pointer",
              m: "none",
              borderBottom: `1px solid ${theme.palette.secondary.light}`,
            }}
            disabled
          >
            Filter By:
          </MenuItem>
          <MenuItem
            sx={{
              width: "150px",
              display: createdOrdersCount > 0 ? "block" : "none",
            }}
            onClick={() => handleSelectByStatus(OrderStatus.created)}
          >
            Created
          </MenuItem>
          <MenuItem
            sx={{
              width: "150px",
              display: confirmedOrdersCount > 0 ? "block" : "none",
            }}
            onClick={() => handleSelectByStatus(OrderStatus.confirmed)}
          >
            Confirmed
          </MenuItem>
          <MenuItem
            sx={{ width: "150px" }}
            onClick={() => handleSelectByStatus(undefined)}
          >
            None
          </MenuItem>
        </Menu>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl) && showActionsMenu}
          onClose={handleMenuClose}
          sx={{
            width: "200px",
            "& .MuiPaper-root": {
              boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
            },
          }}
        >
          <MenuItem divider onClick={() => setShowActionsMenu(false)}>
            <LeftOutlined />
          </MenuItem>
          <MenuItem
            sx={{
              width: "150px",
              display: targetStatus === OrderStatus.created ? "block" : "none",
            }}
            onClick={() => handleDeleteSelected()}
          >
            Delete
          </MenuItem>
          <MenuItem
            sx={{
              width: "150px",
              display: targetStatus === OrderStatus.created ? "block" : "none",
            }}
            onClick={() => handleConfirmSelected()}
          >
            Confirm
          </MenuItem>
          <MenuItem
            sx={{
              width: "150px",
              display:
                targetStatus === OrderStatus.confirmed ? "block" : "none",
            }}
            onClick={() => handleVoidSelected()}
          >
            Void
          </MenuItem>
        </Menu>
      </div>
    </TableCell>
  );
}
