import { createSelector } from "@reduxjs/toolkit";
import { ErrorPayload } from "@pd/api/utils/safeFetch";
import { AppStateType } from "@pd/redux/store/reducers";
import { ApiKeyDbType } from "@pd/redux/types/dbTypes";
import { isDashboardState } from "../../../types";

export const selectSettingsApiKeys = createSelector(
  (state: AppStateType) => state,
  (state): ApiKeyDbType[] => {
    if (isDashboardState(state)) {
      const data = state.dashboard.settings.data.apiKeys;
      if (data) {
        return data;
      }
    }
    return [];
  },
);

export const selectSettingsFetching = createSelector(
  (state: AppStateType) => state,
  (state): boolean => {
    if (isDashboardState(state)) {
      return state.dashboard.settings.fetching;
    }
    return false;
  },
);

export const selectSettingsError = createSelector(
  (state: AppStateType) => state,
  (state): ErrorPayload => {
    if (isDashboardState(state)) {
      return state.dashboard.settings.error;
    }
    return {
      message: "",
      status: 0,
    };
  },
);

export const selectSettingsSuccess = createSelector(
  (state: AppStateType) => state,
  (state): boolean => {
    if (isDashboardState(state)) {
      return state.dashboard.settings.success;
    }
    return false;
  },
);
