import { Stack, Typography } from "@mui/material";
import stitchIcon from "@pd/assets/svgs/stitch-logo_icon_v2.svg";
import AuthGuard from "@pd/components/AuthGuard";

export default function MktplaceHome() {
  return (
    <AuthGuard>
      <Stack
        justifyContent="flex-start"
        alignItems="stretch"
        sx={{
          mt: "150px",
          ml: "260px",
          pl: "40px",
          width: "calc(100% - 300px)",
        }}
      >
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{ minHeight: "50vh" }}
          alignSelf="stretch"
          gap={5}
        >
          <img src={stitchIcon} alt="hero" style={{ height: "10vh" }} />
          <Typography variant="h1" sx={{ mt: "20px", fontSize: "48px" }}>
            Welcome to Stitch
          </Typography>
        </Stack>
      </Stack>
    </AuthGuard>
  );
}
