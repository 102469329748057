import { useCallback, useMemo, useState, useEffect } from "react";
import {
  Grid,
  Tooltip,
  Button,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";

import { useAppDispatch, useAppSelector } from "@pd/redux/store";
import FilterDropdown from "@pd/components/DynamicTable/FilterDropdown";
import TableFilterSearch from "@pd/components/DynamicTable/FilterSearch";
import da from "@pd/layouts/MktplaceDashboard/redux/actions";
import {
  selectTableError,
  selectTableFilters,
  selectTableFetching,
} from "@pd/layouts/MktplaceDashboard/redux/selectors/table";
import { TableFilterType } from "@pd/layouts/MktplaceDashboard/types/tables";
import {
  VendorsKycStatusOptionType,
  isVendorsKycStatusOptionType,
} from "@pd/layouts/MktplaceDashboard/pages/Vendors/types";
import { PlusCircleOutlined } from "@ant-design/icons";
import CreateInviteModal from "@pd/components/DynamicTable/CreateInviteModal";
import { VendorKycStatus } from "@pd/redux/types/vendors";

const filterOptions: VendorsKycStatusOptionType[] = [
  { status: "approved", label: "Approved", key: 0 },
  { status: "pending", label: "Pending", key: 1 },
  { status: "suspended", label: "Suspended", key: 2 },
  { status: "rejected", label: "Rejected", key: 3 },
  { status: "manual_review", label: "Manual review", key: 4 },
  { status: "document", label: "Needs documentation", key: 5 },
];

export default function VendorsHeaderActions() {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const filterState = useAppSelector(selectTableFilters);
  const apiFetching = useAppSelector(selectTableFetching);
  const apiError = useAppSelector(selectTableError);
  const [searchFetching, setSearchFetching] = useState(false);
  const [statusFetching, setStatusFetching] = useState(false);
  const [showCreateInviteModal, setShowCreateInviteModal] = useState(false);

  useEffect(() => {
    if (!apiFetching && searchFetching) {
      setTimeout(() => setSearchFetching(false), 400);
    } else if (apiError.status && searchFetching) {
      setTimeout(() => setSearchFetching(false), 400);
    }
    if (!apiFetching && statusFetching) {
      setStatusFetching(false);
    } else if (apiError.status && statusFetching) {
      setStatusFetching(false);
    }
  }, [apiError, apiFetching, searchFetching]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleOnStatusFilterChange = useCallback((value: any) => {
    setStatusFetching(true);
    if (value && isVendorsKycStatusOptionType(value)) {
      const filter: TableFilterType = {
        type: "status",
        value: value.status,
      };
      dispatch(
        da.table.getTableDataOnFilterChange({
          changeSrc: "filters",
          dataSrc: "vendors",
          filter,
        }),
      );
    } else {
      dispatch(
        da.table.getTableDataOnFilterChange({
          changeSrc: "filters",
          dataSrc: "vendors",
          filter: {
            type: "",
            value: "",
          },
        }),
      );
    }
  }, []);

  const handleOnFilterSearchChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setSearchFetching(true);
      dispatch(da.table.setTableSearchFilter(event.target.value));
      dispatch(
        da.table.getTableDataOnFilterChange({
          changeSrc: "filters",
          dataSrc: "vendors",
          filter: {
            type: "search",
            value: event.target.value,
          },
        }),
      );
    },
    [],
  );

  const filterValue = useMemo(
    () => ({
      status: filterState.miscFilter.value as keyof typeof VendorKycStatus,
      label:
        filterOptions.find(
          (option) => option.status === filterState.miscFilter.value,
        )?.label || "Status",
      key: 0,
    }),
    [filterState.miscFilter.value],
  );

  const onCreateInviteModalClose = () => {
    dispatch(da.vendors.crud.apiSuccess(false));
    dispatch(da.vendors.crud.setInviteLink(""));
    dispatch(da.vendors.crud.apiError({ message: "", status: 0 }));
    setShowCreateInviteModal(false);
  };

  return (
    <>
      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item xs={8} lg={8} sx={{ width: "100%" }}>
          <TableFilterSearch
            value={filterState.search.value}
            onChange={handleOnFilterSearchChange}
          />
        </Grid>
        <Grid item xs={3} lg={3.5}>
          <FilterDropdown
            filterName="Vendor status"
            fetching={statusFetching}
            filterValue={filterValue}
            filterOptions={filterOptions}
            onFilterChange={handleOnStatusFilterChange}
          />
        </Grid>
        <Grid item xs={1} lg={0.5}>
          <Stack gap={1} direction="row" sx={{ width: "100%" }}>
            <Tooltip
              arrow
              title={
                <Typography variant="subtitle1">
                  Create Vendor Invite
                </Typography>
              }
              placement="bottom-end"
            >
              <Button
                variant="outlined"
                onClick={() => setShowCreateInviteModal(true)}
                sx={{
                  minWidth: "50px",
                  p: "0px",
                  color: theme.palette.secondary.main,
                  border: `1px solid ${theme.palette.secondary.light}`,
                }}
              >
                <PlusCircleOutlined />
              </Button>
            </Tooltip>
          </Stack>
        </Grid>
      </Grid>
      {showCreateInviteModal ? (
        <CreateInviteModal onClose={onCreateInviteModalClose} />
      ) : null}
    </>
  );
}
