import {
  Stack,
  Typography,
  CardHeader,
  Divider,
  useTheme,
} from "@mui/material";
import MainCard from "@pd/components/MainCard";
import { OrderDetailDbType } from "@pd/redux/types/dbTypes";
import formatCurrency from "@pd/utils/formatCurrency";
import formatPercent from "@pd/utils/formatPercent";

type Props = {
  order: OrderDetailDbType | null;
};
export default function VendorTerms(props: Props) {
  const theme = useTheme();

  if (!props.order) {
    return null;
  }
  return (
    <Stack style={{ flexGrow: 1 }}>
      <MainCard
        title="Vendor terms"
        darkTitle
        sx={{
          borderBottomLeftRadius: "0px",
          borderBottomRightRadius: "0px",
        }}
      >
        <Stack sx={{ mb: 3 }}>
          <Typography variant="h5" sx={{ mb: 1 }}>
            {props.order.vendorName}
          </Typography>
          <Typography
            data-testid="vendor-rate-fees"
            variant="h5"
            sx={{ mb: 2 }}
          >
            Take&nbsp;
            <span
              style={{
                display: "inline-block",
                borderRadius: "10px",
                border: "3px solid black",
                height: "3px",
                marginLeft: "5px",
                marginRight: "10px",
                marginBottom: "2px",
              }}
            />
            {formatPercent(props.order.vendorRate)}&nbsp;+&nbsp;
            {formatCurrency(Math.ceil(props.order.vendorFee * 100))}
          </Typography>
        </Stack>
        <Stack>
          <Stack
            data-testid="vendor-order-total"
            direction="row"
            justifyContent="space-between"
            sx={{ pb: 1 }}
          >
            <Typography>Base Total</Typography>
            <Typography>{formatCurrency(props.order.total)}</Typography>
          </Stack>
          <Divider />
          <Stack
            data-testid="vendor-deductions"
            direction="row"
            justifyContent="space-between"
            sx={{ pt: 1, mb: 2 }}
          >
            <Typography variant="h5" sx={{ pt: 1 }}>
              Deductions
            </Typography>
            <Typography variant="h5" sx={{ pt: 1 }}>
              {formatCurrency(props.order.deductions)}
            </Typography>
          </Stack>
        </Stack>
      </MainCard>
      <CardHeader
        sx={{
          py: 4,
          fontFamily: "CircularBold",
          border: `1px solid ${theme.palette.grey.A800}`,
          borderBottomLeftRadius: "5px",
          borderBottomRightRadius: "5px",
          borderTop: "none",
        }}
        titleTypographyProps={{ variant: "subtitle1" }}
        title={
          <Stack
            data-testid="vendor-distributed-total"
            direction="row"
            justifyContent="space-between"
          >
            <Typography sx={{ fontFamily: "CircularBold" }}>
              Total Distributed
            </Typography>
            <Typography variant="h5">
              {formatCurrency(props.order.distributedTotal)}
            </Typography>
          </Stack>
        }
      />
    </Stack>
  );
}
