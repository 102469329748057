import { AsyncStateType } from "@pd/redux/types";

export type TableFilterMiscType = {
  type: string;
  value: string;
};
export type TableFilterByDateType = {
  startDate: string;
  endDate: string;
};

export type TablePaginationType = {
  total: number;
  limit: number;
  offset: number;
  sortDir: string;
  sortKey: string;
  sortEnabled: boolean;
};

export type TableFilterType =
  | TableFilterMiscType
  | TableFilterByDateType
  | TablePaginationType;

export function isTableFilterMiscType(
  filter: object,
): filter is TableFilterMiscType {
  return "type" in filter && "value" in filter;
}
export function isTableFilterByDateType(
  filter: object,
): filter is TableFilterByDateType {
  return [
    "type" in filter && filter.type === "date",
    "startDate" in filter,
    "endDate" in filter,
  ].every(Boolean);
}
export function isTableFilterBySortType(
  filter: object,
): filter is TablePaginationType {
  return [
    "total" in filter,
    "limit" in filter,
    "offset" in filter,
    "sortDir" in filter,
    "sortKey" in filter,
    "sortEnabled" in filter,
  ].every(Boolean);
}

export type TableDataSrcType =
  | "orders"
  | "orderHistory"
  | "invoices"
  | "advances"
  | "buyers"
  | "transfers"
  | "vendors"
  | "apiKeys"
  | "teams"
  | "transfers"
  | "payoutHistory"
  | undefined;

export type TableDataType = {
  search: {
    value: string;
  };
  dataSrc: TableDataSrcType;
  miscFilter: TableFilterMiscType;
  dateFilter: TableFilterByDateType;
  pagination: TablePaginationType;
};

export type TableStateType = AsyncStateType & {
  data: TableDataType;
};

export type TableDataKeysType = keyof TableDataType;

export type TableAllFiltersType = {
  search: {
    value: string;
  };
  miscFilter: TableFilterMiscType;
  dateFilter: TableFilterByDateType;
  pagination: TablePaginationType;
};
