import { Stack, ButtonBase, useTheme } from "@mui/material";
import { MinusCircleOutlined } from "@ant-design/icons";
import "./style.css";
import { OrderLineItemDbType } from "@pd/redux/types/dbTypes";

type Props = {
  shouldDisable: boolean;
  lineItem: OrderLineItemDbType;
  index: number;
  hideRemove: boolean;
  onChange: (name: string, value: string | number) => void;
  onRemove: (index: number) => void;
};
export default function LineItem({
  shouldDisable,
  lineItem,
  index,
  hideRemove,
  onChange,
  onRemove,
}: Props) {
  const theme = useTheme();
  return (
    <Stack key={lineItem.id} gap={1} direction="row" sx={{ mt: 1 }}>
      <input
        disabled={shouldDisable}
        placeholder="Description"
        name={`lineItems[${index}].description`}
        value={lineItem.description}
        onChange={(e) => onChange(e.target.name, e.target.value)}
        onWheel={(e) => e.currentTarget.blur()}
        className="line-item-description"
        style={{
          color: shouldDisable
            ? theme.palette.secondary.light
            : theme.palette.primary.light,
          border: `1px solid ${theme.palette.secondary.light}`,
          backgroundColor: "transparent",
          borderRadius: "4px",
          height: "45px",
          fontSize: "16px",
          lineHeight: "24px",
          fontFamily: "CircularRegular",
          paddingLeft: "10px",
          width: "100%",
        }}
      />
      <Stack direction="row" gap={1}>
        <input
          disabled={shouldDisable}
          type="text"
          placeholder="Code"
          name={`lineItems[${index}].code`}
          value={!lineItem.code ? "" : lineItem.code}
          onChange={(e) => onChange(e.target.name, e.target.value)}
          className="line-item-amount"
          style={{
            color: shouldDisable
              ? theme.palette.secondary.light
              : theme.palette.primary.light,
            border: `1px solid ${theme.palette.secondary.light}`,
            backgroundColor: "transparent",
            borderRadius: "4px",
            height: "45px",
            fontSize: "16px",
            lineHeight: "24px",
            fontFamily: "CircularRegular",
            paddingLeft: "10px",
            width: "100%",
          }}
        />
        <Stack direction="row">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderColor: theme.palette.secondary.light,
              color: theme.palette.primary.light,
              border: `1px solid ${theme.palette.secondary.light}`,
              borderRadius: "4px 0 0 4px",
              height: "45px",
              width: "40px",
              fontSize: "16px",
              lineHeight: "24px",
              fontFamily: "CircularRegular",
            }}
          >
            $
          </div>
          <input
            disabled={shouldDisable}
            type="number"
            inputMode="numeric"
            pattern="[0-9.]*[0-9]+"
            placeholder="Amount"
            name={`lineItems[${index}].amount`}
            value={!lineItem.amount ? "" : lineItem.amount}
            onChange={(e) => onChange(e.target.name, +e.target.value)}
            className="line-item-amount"
            style={{
              color: shouldDisable
                ? theme.palette.secondary.light
                : theme.palette.primary.light,
              border: `1px solid ${theme.palette.secondary.light}`,
              backgroundColor: "transparent",
              borderLeft: "none",
              borderRadius: "0 4px 4px 0",
              height: "45px",
              fontSize: "16px",
              lineHeight: "24px",
              fontFamily: "CircularRegular",
              paddingLeft: "10px",
              width: "100%",
            }}
          />
        </Stack>
      </Stack>
      <ButtonBase
        disabled={shouldDisable}
        onClick={() => onRemove(index)}
        sx={{ color: "secondary", display: hideRemove ? "none" : "" }}
      >
        <MinusCircleOutlined />
      </ButtonBase>
    </Stack>
  );
}
