import { Stack, useTheme, Typography, FormControl } from "@mui/material";
import formatCurrency from "@pd/utils/formatCurrency";

type Props = {
  distributedTotal: number;
  amount: number;
  onChange: (name: string, value: { amount: number }) => void;
};
export default function VendorDeduction(props: Props) {
  const theme = useTheme();
  return (
    <Stack spacing={1} sx={{ mt: "50px", pl: "65px" }}>
      <FormControl fullWidth>
        <Stack
          gap={1}
          direction="row"
          sx={{
            mt: 1,
            borderBottom: `1px solid ${theme.palette.secondary.light}`,
            pb: 1,
          }}
          alignItems="flex-end"
        >
          <Typography variant="h5" sx={{ flexGrow: 1 }}>
            Vendor Deductions
          </Typography>
          <Stack direction="row">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderColor: theme.palette.secondary.light,
                color: theme.palette.primary.light,
                border: `1px solid ${theme.palette.secondary.light}`,
                borderRadius: "4px 0 0 4px",
                height: "45px",
                width: "40px",
                fontSize: "16px",
                lineHeight: "24px",
              }}
            >
              $
            </div>
            <input
              type="number"
              inputMode="numeric"
              pattern="[0-9.]*[0-9]+"
              placeholder="Amount"
              name="deduction"
              value={!props.amount ? "" : props.amount}
              onChange={(e) =>
                props.onChange(e.target.name, {
                  amount: +e.target.value,
                })
              }
              onWheel={(e) => e.currentTarget.blur()}
              className="line-item-amount"
              style={{
                color: theme.palette.primary.light,
                border: `1px solid ${theme.palette.secondary.light}`,
                backgroundColor: "transparent",
                borderLeft: "none",
                borderRadius: "0 4px 4px 0",
                height: "45px",
                fontSize: "16px",
                lineHeight: "24px",
                fontFamily: "CircularRegular",
                paddingLeft: "10px",
                width: "100%",
                minWidth: "200px",
              }}
            />
          </Stack>
        </Stack>
        <Stack
          sx={{ pt: 1, mt: 1 }}
          direction="row"
          justifyContent="space-between"
        >
          <Typography variant="h5">
            Total distributed to vendor balance
          </Typography>
          <Typography variant="h5">
            {formatCurrency(props.distributedTotal)}
          </Typography>
        </Stack>
      </FormControl>
    </Stack>
  );
}
