import { Button, Box, Stack, Typography } from "@mui/material";
import { RoleType, OrderDbType } from "@pd/redux/types/dbTypes";
import StitchLoading from "@pd/components/StitchLoading";
import { OrderStatus } from "@pd/redux/types/orders";

type Props = {
  role: RoleType | undefined;
  voidable: boolean;
  showLoading: boolean;
  orderData: OrderDbType;
  onDelete: (order: OrderDbType) => void;
  onVoid: (id: string) => void;
};
export default function OrderActions(props: Props) {
  if (!props.role || ["viewer", "unknown"].includes(props.role)) {
    return null;
  }

  if (props.orderData.status === OrderStatus.created) {
    if (props.showLoading) {
      return (
        <Box
          sx={{
            height: "30px",
            pl: "35px",
          }}
        >
          <StitchLoading size="small" position={{ top: -29, left: 6 }} />
        </Box>
      );
    }
    return (
      <Button
        data-action={`delete-${props.orderData.id}`}
        onClick={() => props.onDelete(props.orderData)}
        variant="contained"
        sx={{
          height: "30px",
          minHeight: "30px",
          cursor: "pointer",
        }}
      >
        <Stack alignItems="center" direction="row" justifyContent="center">
          <Typography variant="subtitle2" sx={{ lineHeight: "0px" }}>
            Delete Draft
          </Typography>
        </Stack>
      </Button>
    );
  }
  if (props.orderData.status === OrderStatus.confirmed && props.voidable) {
    if (props.showLoading) {
      return (
        <Box
          sx={{
            height: "30px",
            pl: "35px",
          }}
        >
          <StitchLoading size="small" position={{ top: -29, left: 6 }} />
        </Box>
      );
    }
    return (
      <Button
        data-action={`void-${props.orderData.id}`}
        onClick={(e) => {
          e.stopPropagation();
          props.onVoid(props.orderData.id);
        }}
        variant="contained"
        sx={{
          height: "30px",
          minHeight: "30px",
          width: "50px",
          minWidth: "50px",
          cursor: "pointer",
        }}
      >
        <Stack alignItems="center" direction="row" justifyContent="center">
          <Typography variant="subtitle2" sx={{ lineHeight: "0px" }}>
            Void
          </Typography>
        </Stack>
      </Button>
    );
  }
  return null;
}
