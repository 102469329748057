import {
  // safeFetch,
  ErrorResType,
} from "@pd/api/utils/safeFetch";
import { endpoints } from "@pd/config/envSetup";
import { TableAllFiltersType } from "@pd/layouts/MktplaceDashboard/types/tables";

import { TransferDbType } from "@pd/redux/types/dbTypes";
import { addFilterQueryParams } from "@pd/api/utils";
import {
  TransferMethodEnum,
  TransferPaymentProcessorEnum,
  TransferSourceType,
  TransferStatus,
  TransferTypeEnum,
} from "@pd/redux/types/transfers";

export type TransfersResType =
  | {
      data: {
        transfers: TransferDbType[];
      };
    }
  | ErrorResType;
export const fetchTransfersByFilter = async (
  filters: TableAllFiltersType,
): Promise<TransfersResType> => {
  let url = `${endpoints.stitchBackend}/dashboard/orders`;
  url = addFilterQueryParams(url, filters);
  console.info("fetchOrdersByFilter url:", url); // eslint-disable-line no-restricted-syntax
  // const response = await safeFetch(url, {
  //   method: "GET",
  //   credentials: "include",
  // });
  // return response;
  const mockResponse = {
    data: {
      transfers: [
        {
          id: "123",
          createdAt: "2024-02-05T23:59:59Z",
          sourceId: "111",
          sourceType: "bankAccount" as TransferSourceType,
          destinationId: "999",
          destinationType: "bankAccount" as TransferSourceType,
          type: "payout" as TransferTypeEnum,
          method: "WIRE" as TransferMethodEnum,
          status: "pending" as TransferStatus,
          subtotal: 85,
          amount: 100,
          intentId: "123",
          extId: "234",
          processor: "stripe" as TransferPaymentProcessorEnum,
        },
        {
          id: "234",
          createdAt: "2024-02-06T23:59:59Z",
          sourceId: "222",
          sourceType: "card" as TransferSourceType,
          destinationId: "888",
          destinationType: "card" as TransferSourceType,
          type: "advance" as TransferTypeEnum,
          method: "ACH" as TransferMethodEnum,
          status: "complete" as TransferStatus,
          subtotal: 195,
          amount: 200,
          intentId: "123",
          extId: "234",
          processor: "astra" as TransferPaymentProcessorEnum,
        },
        {
          id: "345",
          createdAt: "2024-02-06T23:59:59Z",
          sourceId: "333",
          sourceType: "bankAccount" as TransferSourceType,
          destinationId: "777",
          destinationType: "bankAccount" as TransferSourceType,
          type: "payment" as TransferTypeEnum,
          method: "ACH" as TransferMethodEnum,
          status: "cancelled" as TransferStatus,
          subtotal: 195,
          amount: 200,
          intentId: "123",
          extId: "234",
          processor: "astra" as TransferPaymentProcessorEnum,
        },
      ],
    },
  };
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(mockResponse);
    }, 1000);
  });
};
