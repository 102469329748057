import { useState } from "react";
import { Modal, Button, Fade, Stack, Typography } from "@mui/material";
import { CheckCircleOutlined } from "@ant-design/icons";
import ModalTitle from "@pd/components/ModalTitle";

import { modalStyles } from "./constants/styles";

type Props = {
  success: boolean;
};
export default function PayoutModal(props: Props) {
  const [closeModal, setCloseModal] = useState(false);

  if (closeModal || !props.success) return null;

  return (
    <Modal open disableEnforceFocus closeAfterTransition>
      <Fade in>
        <Stack
          gap={2}
          sx={{
            ...modalStyles,
            minHeight: "300px",
          }}
          justifyContent="flex-start"
        >
          <ModalTitle title="Success" onClose={() => setCloseModal(true)} />
          <Stack
            gap={3}
            alignItems="center"
            justifyContent="center"
            sx={{ my: "auto", p: 3 }}
          >
            <CheckCircleOutlined style={{ fontSize: "32px" }} />
            <Typography variant="h4">Payout successfully initiated!</Typography>
          </Stack>
          <Button variant="contained" onClick={() => setCloseModal(true)}>
            Close
          </Button>
        </Stack>
      </Fade>
    </Modal>
  );
}
