import { Stack, useTheme, Button, Typography } from "@mui/material";
import formatCurrency from "@pd/utils/formatCurrency";
import {
  BulkPayoutResultDbType,
  BulkPayoutResultError,
} from "@pd/redux/types/dbTypes";
import CopyIdButton from "@pd/components/CopyIdButton";

type Props = {
  uiErrors: BulkPayoutResultError[];
  bulkPayoutResults: BulkPayoutResultDbType;
  onCloseModal: () => void;
};
export default function ErrorDialogue(props: Props) {
  const theme = useTheme();

  return (
    <Stack gap={2} sx={{ height: "100%", width: "100%", py: 3, pl: 3 }}>
      <Stack
        sx={{
          overflowY: "scroll",
          overflowX: "hidden",
          whiteSpace: "nowrap",
          py: 3,
          height: "calc(90% - 100px)",
          "& .MuiAutocomplete-popper": {
            "::-webkit-scrollbar": {
              width: "12px",
              height: "7px",
            },
            "::-webkit-scrollbar-track": {
              background: "#F1F1F1",
            },
            "::-webkit-scrollbar-thumb": {
              background: "#ddd",
            },
            "::-webkit-scrollbar-thumb:hover": {
              background: "#333",
            },
          },
        }}
        gap={3}
      >
        <Stack alignItems="center" gap={3} sx={{ pb: 4 }}>
          <Typography variant="h4">Payout errors</Typography>
          <Typography>
            <span
              style={{
                fontFamily: "CircularBold",
                color: theme.palette.error.main,
              }}
            >
              {props.bulkPayoutResults.actionSize -
                props.bulkPayoutResults.successCount}{" "}
            </span>
            vendors were unable to be paid.
          </Typography>
        </Stack>
        {props.uiErrors.map((errData, i) => (
          <Stack
            key={errData.vendorId}
            sx={{
              mr: 3,
              borderBottom: `1px solid ${theme.palette.secondary.light}`,
              borderTop:
                i === 0 ? `1px solid ${theme.palette.secondary.light}` : "none",
              pt: i === 0 ? 2 : 0,
            }}
          >
            <Stack sx={{ pl: 3, pb: 3 }} gap={2}>
              <Stack
                sx={{ width: "100%", pl: 0, pr: 3 }}
                direction="row"
                justifyContent="space-between"
              >
                <Stack direction="row" sx={{ pl: 0 }}>
                  <Typography variant="h5" sx={{ mr: 2 }}>
                    {i + 1}.
                  </Typography>
                  <Typography variant="h5">Name</Typography>
                </Stack>
                <Typography variant="h5">{errData.vendorName}</Typography>
              </Stack>
              <Stack
                sx={{ width: "100%", px: 3 }}
                direction="row"
                justifyContent="space-between"
              >
                <Typography variant="h5">Unpaid balance</Typography>
                <Typography variant="h5">
                  {formatCurrency(errData.payoutBalance)}
                </Typography>
              </Stack>
              <Stack
                sx={{ width: "100%", px: 3 }}
                direction="row"
                justifyContent="space-between"
              >
                <Typography variant="h5">Vendor id</Typography>
                <CopyIdButton id={errData.vendorId} />
              </Stack>
              <Stack
                sx={{ width: "100%", px: 3 }}
                direction="row"
                justifyContent="space-between"
              >
                <Typography variant="h5">Reference id</Typography>
                <CopyIdButton id={errData.referenceId} />
              </Stack>
              <Stack
                sx={{ width: "100%", px: 3 }}
                direction="row"
                justifyContent="space-between"
              >
                <Typography variant="h5">Error</Typography>
                <Stack>
                  {errData.error.split(":").map((err, i) => (
                    <Typography
                      key={`error-${i}`}
                      variant="h5"
                      color="error"
                      align="right"
                    >
                      {err}
                    </Typography>
                  ))}
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        ))}
      </Stack>
      <Stack
        sx={{ height: "100px", p: 3 }}
        alignItems="center"
        justifyContent="center"
      >
        <Button
          variant="contained"
          onClick={props.onCloseModal}
          sx={{ mr: 4, width: "100%" }}
        >
          Ok
        </Button>
      </Stack>
    </Stack>
  );
}
