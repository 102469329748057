import MiscColors from "@pd/mantis/themes/miscColors";
import { TransferStatus } from "@pd/redux/types/transfers";

export interface TransfersTableDataType {
  id: string;
  createdAt: string;
  description: string;
  type: string;
  total: number;
  status: TransferStatus;
}

export interface TransfersColDefType {
  id: keyof TransfersTableDataType;
  label: string;
  index: number;
}

type TransfersStatusColorType = {
  [TransferStatus.pending]: string;
  [TransferStatus.complete]: string;
  [TransferStatus.cancelled]: string;
  [TransferStatus.failed]: string;
  [TransferStatus.unpaid]: string;
  [TransferStatus.unknown]: string;
};

const mColors = MiscColors();
export const TRANSFER_STATUS_COLORS: TransfersStatusColorType = {
  [TransferStatus.pending]: mColors.statusGrayBg,
  [TransferStatus.complete]: mColors.statusGreenBg,
  [TransferStatus.cancelled]: mColors.statusOrangeBg,
  [TransferStatus.failed]: mColors.statusRedBg,
  [TransferStatus.unpaid]: mColors.statusRedBg,
  [TransferStatus.unknown]: mColors.statusVioletBg,
};

export function isTransfersStatusType(
  status: string,
): status is TransferStatus {
  return Object.values(TransferStatus).includes(status as TransferStatus);
}

export type TransfersStatusOptionType = {
  key: number;
  label: string;
  status: keyof typeof TransferStatus;
};

export function isTransfersStatusOptionType(
  option: any,
): option is TransfersStatusOptionType {
  return [
    typeof option === "object",
    option !== null,
    "label" in option,
    "status" in option,
    typeof option.label === "string",
    Object.values(TransferStatus).includes(option.status as TransferStatus),
  ].every(Boolean);
}
