import { Modal, Button, Fade, Stack, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "@pd/redux/store";
import StitchLoading from "@pd/components/StitchLoading";
import pd from "@pd/layouts/MktplaceDashboard/redux/actions";
import { DeleteFilled } from "@ant-design/icons";
import { CheckCircleOutline, ErrorOutlineRounded } from "@mui/icons-material";
import {
  selectCrudTeamMembersApiError,
  selectCrudTeamMembersApiFetching,
  selectCrudTeamMembersApiSuccess,
} from "@pd/layouts/MktplaceDashboard/redux/selectors/teams";
import ModalTitle from "@pd/components/ModalTitle";

import { modalStyles } from "./modalStyles";

type Props = {
  id: string;
  show: boolean;
  onClose: () => void;
};
export default function DeleteTeamMemberModal(props: Props) {
  const dispatch = useAppDispatch();

  const apiFetching = useAppSelector(selectCrudTeamMembersApiFetching);
  const apiError = useAppSelector(selectCrudTeamMembersApiError);
  const apiSuccess = useAppSelector(selectCrudTeamMembersApiSuccess);

  function handleConfirmDelete(id: string) {
    dispatch(pd.teams.crud.deleteTeamMember(id));
  }

  function handleOnClose() {
    props.onClose();
    dispatch(pd.teams.crud.apiSuccess(false));
  }

  if (!props.show) return null;

  return (
    <Modal open>
      <Fade in>
        <Stack
          gap={2}
          sx={{
            ...modalStyles,
          }}
          justifyContent="flex-start"
        >
          <ModalTitle
            title={(() => {
              if (apiFetching) return "Sending...";
              if (apiError.message) return "Error";
              if (apiSuccess) return "Success";
              return "Confirm Delete";
            })()}
            onClose={props.onClose}
          />
          <Stack
            gap={3}
            alignItems="center"
            justifyContent="center"
            sx={{ my: "auto", p: 3 }}
          >
            {(() => {
              if (apiError.message) {
                return (
                  <Stack gap={2} alignItems="center">
                    <ErrorOutlineRounded style={{ fontSize: "36px" }} />
                    <Typography variant="h3">
                      Sorry there was a problem
                    </Typography>
                    <Typography variant="body1">
                      {apiError.message ||
                        "There was an error deleting your team member. Please try again."}
                    </Typography>
                    <Stack
                      direction="row"
                      gap={2}
                      sx={{ width: "100%" }}
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Button
                        variant="contained"
                        onClick={() => handleConfirmDelete(props.id)}
                        sx={{ height: "40px", width: "100px" }}
                      >
                        Retry
                      </Button>
                      <Button
                        variant="outlined"
                        onClick={handleOnClose}
                        sx={{ height: "40px", width: "100px" }}
                      >
                        Close
                      </Button>
                    </Stack>
                  </Stack>
                );
              }
              if (apiFetching) {
                return <StitchLoading size="medium" />;
              }
              if (apiSuccess) {
                return (
                  <Stack gap={4} alignItems="center" justifyContent="center">
                    <CheckCircleOutline style={{ fontSize: "48px" }} />
                    <Typography variant="h3">Member Deleted!</Typography>
                    <Button
                      variant="contained"
                      onClick={handleOnClose}
                      sx={{ width: "200px", height: "40px" }}
                    >
                      Close
                    </Button>
                  </Stack>
                );
              }
              return (
                <Stack sx={{ width: "100%" }} alignItems="center" gap={4}>
                  <DeleteFilled style={{ fontSize: "32px" }} />
                  <Typography variant="h4">
                    Are you sure you want to delete this member?
                  </Typography>
                  <Stack
                    gap={1}
                    direction="row"
                    sx={{ width: "100%" }}
                    justifyContent="center"
                  >
                    <Button
                      variant="contained"
                      onClick={() => handleConfirmDelete(props.id)}
                      sx={{ height: "40px", width: "100px" }}
                      color="error"
                    >
                      Delete
                    </Button>
                    <Button
                      variant="outlined"
                      onClick={handleOnClose}
                      sx={{ height: "40px", width: "100px" }}
                    >
                      Cancel
                    </Button>
                  </Stack>
                </Stack>
              );
            })()}
          </Stack>
        </Stack>
      </Fade>
    </Modal>
  );
}
