import { Box, Tooltip } from "@mui/material";
import { MerchantProfileDbType } from "@pd/redux/types/dbTypes";
import ProfileDetails from "./ProfileDetails";

type Props = {
  name: string;
  profile: MerchantProfileDbType | null;
};
export default function NameBadge(props: Props) {
  const [firstName, lastName] = props.name.split(" ");
  const firstLetter = firstName.charAt(0).toUpperCase();
  const lastLetter = lastName.charAt(0).toUpperCase();
  if (props.profile === null) {
    return (
      <Box
        key={props.name}
        style={{
          width: "50px",
          height: "50px",
          borderRadius: "50%",
          backgroundColor: hashNameToColor(props.name),
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontFamily: "CircularBold",
          fontSize: "24px",
          letterSpacing: "-0.1em",
        }}
      >
        {firstLetter}
        {lastLetter}
      </Box>
    );
  }
  return (
    <Tooltip
      title={<ProfileDetails profile={props.profile} />}
      placement="bottom-start"
    >
      <Box
        key={props.name}
        style={{
          width: "50px",
          height: "50px",
          borderRadius: "50%",
          backgroundColor: hashNameToColor(props.name),
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontFamily: "CircularBold",
          fontSize: "24px",
          letterSpacing: "-0.1em",
        }}
      >
        {firstLetter}
        {lastLetter}
      </Box>
    </Tooltip>
  );
}

export function hashNameToColor(name: string) {
  const randomColors = [
    "#92C7CF",
    "#AAD7D9",
    "#FBF9F1",
    "#E5E1DA",
    "#B5C0D0",
    "#CCD3CA",
    "#F5E8DD",
    "#EED3D9",
    "#FAF3F0",
    "#D4E2D4",
    "#FFCACC",
    "#DBC4F0",
    "#7BD3EA",
    "#A1EEBD",
    "#F6F7C4",
    "#F6D6D6",
  ];

  const sum = name.split("").reduce((acc, char) => acc + char.charCodeAt(0), 0);
  return randomColors[sum % randomColors.length];
}
