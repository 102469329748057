import { useState, useEffect } from "react";
import { Modal, Button, Fade, Stack, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "@pd/redux/store";
import StitchLoading from "@pd/components/StitchLoading";
import da from "@pd/layouts/MktplaceDashboard/redux/actions";
import { CheckCircleOutline } from "@mui/icons-material";
import {
  selectCrudOrderError,
  selectCrudOrderFetching,
  selectCrudOrderSuccess,
} from "@pd/layouts/MktplaceDashboard/redux/selectors/orders/crudOrder";
import ModalTitle from "@pd/components/ModalTitle";

import { modalStyles } from "../constants/styles";

type Props = {
  showModal: boolean;
  orderId: string;
  referenceId: string;
  onClose: () => void;
};
export default function ConfirmDeleteOrder(props: Props) {
  const dispatch = useAppDispatch();
  const apiFetching = useAppSelector(selectCrudOrderFetching);
  const apiSuccess = useAppSelector(selectCrudOrderSuccess);
  const apiError = useAppSelector(selectCrudOrderError);

  const [uiFetching, setUiFetching] = useState(false);
  const [uiSuccess, setUiSuccess] = useState(false);

  const handleCloseModal = () => {
    setUiFetching(false);
    setUiSuccess(false);
    props.onClose();
  };

  useEffect(() => {
    if (apiError.status && uiFetching) {
      setUiFetching(false);
      setUiSuccess(false);
    } else if (!apiFetching && uiFetching) {
      setUiFetching(false);
    }
    if (apiSuccess && uiFetching) {
      setUiSuccess(true);
      setUiFetching(false);
      setTimeout(() => handleCloseModal(), 2000);
    }
  }, [apiFetching, apiError, uiFetching, uiSuccess]);

  function handleConfirmDeleteOrder(id: string) {
    setUiFetching(true);
    dispatch(da.orders.crud.deleteOrder(id));
  }

  useEffect(
    () => () => {
      setUiFetching(false);
      dispatch(da.orders.crud.apiFetching(false));
      handleCloseModal();
    },
    [],
  );

  const renderContent = () => {
    if (apiError.status) {
      return (
        <>
          <Typography variant="h3">Sorry there was a problem</Typography>
          <Typography variant="body1">
            {"There was an issue while trying to delete. Please try again."}
          </Typography>
          <Stack direction="row" gap={1}>
            <Button
              variant="outlined"
              onClick={handleCloseModal}
              sx={{ width: "100px" }}
            >
              Close
            </Button>
            <Button
              variant="contained"
              onClick={() => handleConfirmDeleteOrder(props.orderId)}
              sx={{ width: "100px" }}
            >
              Retry
            </Button>
          </Stack>
        </>
      );
    }
    if (uiFetching) {
      return <StitchLoading />;
    }
    if (uiSuccess) {
      return (
        <>
          <Stack direction="row" alignItems="center" gap={1}>
            <Typography variant="h4">Order Deleted!</Typography>
            <CheckCircleOutline style={{ fontSize: "24px" }} />
          </Stack>
          <Button
            variant="contained"
            onClick={handleCloseModal}
            sx={{ width: "100px" }}
          >
            Close
          </Button>
        </>
      );
    }
    return (
      <>
        <Stack sx={{ width: "100%" }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="unset"
            sx={{ width: "100%", px: "20px" }}
          >
            <Typography variant="h5" sx={{ fontFamily: "CircularRegular" }}>
              Order id:
            </Typography>
            <Typography variant="h5">
              <code style={{ fontWeight: "400" }}>{props.orderId}</code>
            </Typography>
          </Stack>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="unset"
            sx={{ width: "100%", px: "20px" }}
          >
            <Typography variant="h5">Reference id:</Typography>
            <Typography variant="h5">
              <code style={{ fontWeight: "400" }}>{props.referenceId}</code>
            </Typography>
          </Stack>
        </Stack>
        <Typography variant="body1" sx={{ textAlign: "center" }}>
          Are you sure you want to delete?
        </Typography>
        <Stack direction="row" gap={2}>
          <Button
            variant="outlined"
            onClick={handleCloseModal}
            sx={{ width: "100px" }}
          >
            Cancel
          </Button>
          <Button
            color="error"
            variant="contained"
            onClick={() => handleConfirmDeleteOrder(props.orderId)}
            sx={{ width: "100px" }}
          >
            Delete
          </Button>
        </Stack>
      </>
    );
  };

  if (!props.showModal) return null;

  return (
    <Modal open>
      <Fade in>
        <Stack gap={2} sx={modalStyles} justifyContent="flex-start">
          <ModalTitle
            title={(() => {
              if (uiFetching) return "Deleting...";
              if (apiError.message) return "Error";
              if (uiSuccess) return "Success";
              return "Confirm Delete Order";
            })()}
            onClose={handleCloseModal}
          />
          <Stack
            gap={3}
            alignItems="center"
            justifyContent="space-evenly"
            sx={{ height: "100%", flexGrow: 1, p: 3 }}
          >
            {renderContent()}
          </Stack>
        </Stack>
      </Fade>
    </Modal>
  );
}
