import { Box, ButtonBase, useTheme } from "@mui/material";
import { useNavigate } from "react-router";
import { LeftOutlined } from "@ant-design/icons";

type Props = {
  onCloseForm: () => void;
};
export default function LeftBackBtn(props: Props) {
  const theme = useTheme();
  const navigate = useNavigate();
  const params = new URLSearchParams(location.search); // eslint-disable-line no-restricted-globals

  return (
    <Box sx={{ alignSelf: "flex-start", mr: "25px", mt: "5px" }}>
      <ButtonBase
        onClick={() => {
          if (params.get("id")) {
            // NOTE: If there's a params in the URL, it's because we're trying to deeply link
            // into a specific sub-route that's not the main route. in that case we should
            // rely on history rather than rely on being route - specific.
            // We should NOT always rely on history since there's potential for accidentally
            // sending the user back to the login screen.
            // TODO: Ideally, we should use a more robust routing solution by tracking
            // the history in state and inspecting the previous route is  safe to
            // navigate to so that `navigate(-1)` can be the default behavior.
            navigate(-1);
            return;
          }
          props.onCloseForm();
        }}
        sx={{
          fontSize: "24px",
          fontWeight: "500",
          p: "10px",
          borderRadius: "5px",
          "&:hover": {
            backgroundColor: theme.palette.secondary.lighter,
          },
        }}
      >
        <LeftOutlined />
      </ButtonBase>
    </Box>
  );
}
