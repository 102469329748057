import { useState } from "react";
import { Stack } from "@mui/material";
import { useAppDispatch } from "@pd/redux/store";
import pla from "@pd/components/PlaidLink/redux/actions";
import LinkedAccounts from "./LinkedAccounts";

export default function LinkedAccountsWrapper() {
  const dispatch = useAppDispatch();
  const [remountKey, setRemountKey] = useState(1);
  const handleReMount = () => {
    setRemountKey((prev) => prev + 1);
    // cb();
  };
  const handleOnClearState = () => {
    dispatch(pla.tokens.clearTokens());
    dispatch(pla.accounts.clearAccounts());
    setRemountKey((prev) => prev + 1);
  };

  return (
    <Stack key={remountKey}>
      <LinkedAccounts
        onRemount={handleReMount}
        onClearState={handleOnClearState}
      />
    </Stack>
  );
}
