import { useState, useCallback, useMemo } from "react";
import { TableRow, TableCell, Stack, Typography } from "@mui/material";
import DynamicTableHOC from "@pd/components/DynamicTable";
import { SortDirType, stableSort, getComparator } from "@pd/utils/stableSort";
import { useAppSelector } from "@pd/redux/store";
import {
  selectAllTeamMembers,
  selectAllTeamMembersApiFetching,
} from "@pd/layouts/MktplaceDashboard/redux/selectors/teams";
import formatPhone from "@pd/utils/formatPhone";
import NameBadge from "@pd/components/NameBadge";
import { findTimezoneLabel } from "@pd/utils/getUsTimezones";
import StatusCell from "@pd/components/DynamicTable/StatusCell";

import composeTableData from "../../utils/composeTableData";
import { teamMembersSchema } from "../../tableSchema";
import {
  TeamMemberRoleType,
  TeamMemberStatusType,
  TeamsTableDataType,
} from "../../types";
import DeleteButton from "./components/DeleteButton";

const ROW_HEIGHT = 75;
const COLUMN_TITLE_HEIGHT = 75;

type Props = {
  userId: string;
  userRole: string;
  onDeleteMember: (id: string) => void;
};
export default function TeamTable(props: Props) {
  const teamMembers = useAppSelector(selectAllTeamMembers);
  const apiFetching = useAppSelector(selectAllTeamMembersApiFetching);

  const [sortOrder, setSortOrder] = useState<SortDirType>("asc");
  const [orderBy, setOrderBy] = useState<keyof TeamsTableDataType>("name");

  const handleRequestSort = useCallback((_: any, property: string) => {
    setSortOrder((prev) => (prev === "asc" ? "desc" : "asc"));
    setOrderBy(property as keyof TeamsTableDataType);
  }, []);

  const rows = composeTableData(teamMembers);
  const sortedRows = useMemo(
    () => stableSort(rows, getComparator(sortOrder, orderBy)),
    [rows, sortOrder, orderBy],
  );

  return (
    <DynamicTableHOC
      columnDefs={teamMembersSchema}
      onHeaderSort={handleRequestSort}
      order={sortOrder}
      orderBy={orderBy}
      tableSrc="teams"
      autoHidePagination
      showLoading={apiFetching}
      style={
        sortedRows.length < 5
          ? {
              maxHeight: `${
                sortedRows.length * ROW_HEIGHT + COLUMN_TITLE_HEIGHT
              }px`,
            }
          : {}
      }
    >
      {sortedRows.map((row) => (
        <TableRow
          key={row.id}
          sx={{
            td: {
              fontFamily: "CircularRegular",
              fontSize: "14px",
              overflowX: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            },
          }}
        >
          <TableCell sx={{ maxWidth: "100px" }}>
            <DeleteButton
              status={row.status as TeamMemberStatusType}
              rowId={row.id}
              userId={props.userId}
              userRole={props.userRole}
              onDeleteMember={props.onDeleteMember}
            />
          </TableCell>
          <TableCell sx={{ maxWidth: "180px" }}>
            <Stack direction="row" alignItems="center" gap={2}>
              <NameBadge name={row.name} profile={null} />
              <Typography variant="body1">{row.name}</Typography>
            </Stack>
          </TableCell>
          <TableCell sx={{ maxWidth: "180px" }}>{row.email}</TableCell>
          <TableCell>{formatPhone(row.phone)}</TableCell>
          <TableCell style={{ textTransform: "capitalize" }}>
            <StatusCell
              status={row.role as TeamMemberRoleType}
              type="teams/roles"
            />
          </TableCell>
          <TableCell style={{ textTransform: "capitalize" }}>
            <StatusCell
              status={row.status as TeamMemberStatusType}
              type="teams/status"
            />
          </TableCell>
          <TableCell style={{ textTransform: "capitalize" }}>
            {findTimezoneLabel(row.timezone)}
          </TableCell>
        </TableRow>
      ))}
    </DynamicTableHOC>
  );
}
