import dayjs from "dayjs";
import { Stack, Typography, FormControl, InputLabel } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import StitchDatePicker from "@pd/components/StitchDatePicker";

type Props = {
  disableOnConfirmed: boolean;
  billDate: string;
  onChange: (name: string, value: string) => void;
};
export default function BillDateInput(props: Props) {
  const dateValue =
    props.billDate && dayjs(props.billDate).isValid()
      ? dayjs(props.billDate).toDate()
      : null;

  return (
    <Stack spacing={1} sx={{ mt: "25px", pl: "65px" }}>
      <InputLabel htmlFor="billDate">
        <Stack direction="row" alignItems="center" gap={0.5}>
          <Typography>Bill Date</Typography>
          <Typography variant="subtitle2" sx={{ pt: "6px" }}>
            *
          </Typography>
        </Stack>
      </InputLabel>
      <FormControl fullWidth>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <StitchDatePicker
            value={dateValue}
            onChange={(date: Date | null) => {
              if (date && dayjs(date).isValid()) {
                const billDate = date.toISOString();
                props.onChange("billDate", billDate);
              }
            }}
            disabled={props.disableOnConfirmed}
            onClear={() => props.onChange("billDate", "")}
          />
        </LocalizationProvider>
      </FormControl>
    </Stack>
  );
}
