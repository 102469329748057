import { utcToZonedTime, format } from "date-fns-tz";

export default function formatDateToTzIso(date: string, timeZone: string) {
  const serverFormat = "yyyy-MM-dd'T'HH:mm:ss.SSSxxx";
  try {
    if (!date) return "";
    const dateObj = new Date(date);
    if (!timeZone) {
      const browserTz = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const browserZonedDate = utcToZonedTime(dateObj, browserTz);
      return format(browserZonedDate, serverFormat, {
        timeZone: browserTz,
      });
    }
    const zonedDate = utcToZonedTime(dateObj, timeZone);
    const result = format(zonedDate, serverFormat, { timeZone });
    return result;
  } catch (error) {
    console.error("Error while formatting date to timezone ISO", error);
    return "";
  }
}
