import { fadeIn, fadeOut } from "react-animations";
import { Box, Stack, Button } from "@mui/material";
import { keyframes } from "@emotion/react";
import { styled } from "@mui/system";

export const fadeInAnimation = keyframes`${fadeIn}`;
export const fadeOutAnimation = keyframes`${fadeOut}`;

export const FadeInBox = styled(Box)`
  animation: 1s ${fadeInAnimation};
`;
export const FadeOutBox = styled(Box)`
  animation: 0.5s ${fadeOutAnimation};
`;

export const FadeInStack = styled(Stack)`
  animation: 1s ${fadeInAnimation};
`;
export const FadeOutStack = styled(Stack)`
  animation: 0.5s ${fadeOutAnimation};
`;

export const FadeInButton = styled(Button)`
  animation: 1s ${fadeInAnimation};
`;
