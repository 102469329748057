import { useCallback, useMemo, useState, useEffect } from "react";
import * as dayjs from "dayjs";
import {
  ButtonBase,
  Button,
  Grid,
  Typography,
  Stack,
  useTheme,
  Tooltip,
} from "@mui/material";

import { useAppDispatch, useAppSelector } from "@pd/redux/store";
import {
  selectTableError,
  selectTableFilters,
  selectTableFetching,
} from "@pd/layouts/MktplaceDashboard/redux/selectors/table";
import FilterDropdown from "@pd/components/DynamicTable/FilterDropdown";
import FilterByDateModal from "@pd/components/DynamicTable/FilterByDateModal";
import BulkPayoutModal from "@pd/components/DynamicTable/BulkPayoutModal";
import TableFilterSearch from "@pd/components/DynamicTable/FilterSearch";
import da from "@pd/layouts/MktplaceDashboard/redux/actions";
import { TableFilterType } from "@pd/layouts/MktplaceDashboard/types/tables";
import { OrderStatus } from "@pd/redux/types/orders";
import {
  OrderStatusOptionType,
  isOrderStatusOptionType,
} from "@pd/layouts/MktplaceDashboard/pages/Orders/types";
import {
  GroupOutlined,
  PlusCircleOutlined,
  UploadOutlined,
} from "@ant-design/icons";

const filterOptions: OrderStatusOptionType[] = [
  { status: "created", label: "Created", key: 0 },
  { status: "confirmed", label: "Confirmed", key: 1 },
  { status: "voided", label: "Voided", key: 2 },
];

type Props = {
  onShowCsvUpload: () => void;
  onShowCreateOrder: (id: string) => void;
};
export default function OrderHeaderActions(props: Props) {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const filterState = useAppSelector(selectTableFilters);
  const apiFetching = useAppSelector(selectTableFetching);
  const apiError = useAppSelector(selectTableError);
  const [showDateModal, setShowDateModal] = useState(false);
  const [showPayoutModal, setShowPayoutModal] = useState(false);
  const [searchFetching, setSearchFetching] = useState(false);
  const [statusFetching, setStatusFetching] = useState(false);
  const [dateFetching, setDateFetching] = useState(false);
  const [payoutFetching, setPayoutFetching] = useState(false);

  useEffect(() => {
    if (!apiFetching && searchFetching) {
      setTimeout(() => setSearchFetching(false), 300);
    } else if (apiError.status && searchFetching) {
      setTimeout(() => setSearchFetching(false), 300);
    }
    if (!apiFetching && statusFetching) {
      setStatusFetching(false);
    } else if (apiError.status && statusFetching) {
      setStatusFetching(false);
    }
    if (!apiFetching && dateFetching) {
      setDateFetching(false);
    } else if (apiError.status && dateFetching) {
      setDateFetching(false);
    }
    if (!apiFetching && payoutFetching) {
      setPayoutFetching(false);
    } else if (apiError.status && payoutFetching) {
      setPayoutFetching(false);
    }
  }, [
    apiFetching,
    apiError,
    searchFetching,
    statusFetching,
    dateFetching,
    payoutFetching,
  ]);

  const handleOnStatusFilterChange = useCallback((value: any) => {
    setStatusFetching(true);
    if (value && isOrderStatusOptionType(value)) {
      const filter: TableFilterType = {
        type: "status",
        value: value.status,
      };
      dispatch(
        da.table.getTableDataOnFilterChange({
          changeSrc: "filters",
          dataSrc: "orders",
          filter,
        }),
      );
    } else {
      dispatch(
        da.table.getTableDataOnFilterChange({
          changeSrc: "filters",
          dataSrc: "orders",
          filter: {
            type: "",
            value: "",
          },
        }),
      );
    }
  }, []);

  const handleOnFilterSearchChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setSearchFetching(true);
      dispatch(da.table.setTableSearchFilter(event.target.value));
      dispatch(
        da.table.getTableDataOnFilterChange({
          changeSrc: "filters",
          dataSrc: "orders",
          filter: {
            type: "search",
            value: event.target.value,
          },
        }),
      );
    },
    [],
  );

  const handleOnFilterDateChange = useCallback(
    (startDate: Date | null, endDate: Date | null) => {
      setDateFetching(true);
      dispatch(
        da.table.getTableDataOnFilterChange({
          changeSrc: "filters",
          dataSrc: "orders",
          filter: {
            type: "date",
            startDate: startDate ? startDate.toISOString() : "",
            endDate: endDate
              ? new Date(endDate.setHours(23, 59, 59, 999)).toISOString()
              : "",
          },
        }),
      );
      setShowDateModal(false);
    },
    [],
  );

  const filterValue = useMemo(
    () => ({
      status: filterState.miscFilter.value as keyof typeof OrderStatus,
      label:
        filterOptions.find(
          (option) => option.status === filterState.miscFilter.value,
        )?.label || "All Orders",
      key: 0,
    }),
    [filterState.miscFilter.value],
  );

  return (
    <>
      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item xs={4} lg={6}>
          <TableFilterSearch
            value={filterState.search.value}
            onChange={handleOnFilterSearchChange}
          />
        </Grid>
        <Grid item xs={3} lg={2}>
          <FilterDropdown
            filterName={`Filter: ${filterValue.label}`}
            filterValue={filterValue}
            filterOptions={filterOptions}
            fetching={statusFetching}
            onFilterChange={handleOnStatusFilterChange}
          />
        </Grid>
        <Grid item xs={3} lg={2.5}>
          <ButtonBase
            disabled={apiFetching}
            sx={{
              width: "100%",
              height: "100%",
              border: `1px solid lightgray`,
              borderRadius: "4px",
              padding: "10px 20px",
              fontSize: "16px",
              ":hover": {
                borderColor: theme.palette.primary.main,
                cursor: "pointer",
              },
            }}
            onClick={() => setShowDateModal(true)}
          >
            {filterState.dateFilter.startDate && filterState.dateFilter.endDate
              ? `${dayjs(new Date(filterState.dateFilter.startDate))
                  .format("MMM DD, YYYY")
                  .toString()} - ${dayjs(
                  new Date(filterState.dateFilter.endDate || new Date()),
                )
                  .format("MMM DD, YYYY")
                  .toString()}`
              : "Choose time period"}
          </ButtonBase>
        </Grid>
        <Grid item xs={2} lg={1.5}>
          <Stack gap={1} direction="row" sx={{ width: "100%" }}>
            <Tooltip
              arrow
              title={
                <Typography variant="subtitle1">
                  Upload orders by csv
                </Typography>
              }
              placement="bottom-end"
            >
              <Button
                variant="outlined"
                onClick={() => props.onShowCsvUpload()}
                sx={{
                  minWidth: "50px",
                  p: "0px",
                  color: theme.palette.secondary.main,
                  border: `1px solid ${theme.palette.secondary.light}`,
                }}
              >
                <UploadOutlined />
              </Button>
            </Tooltip>
            <Tooltip
              arrow
              title={<Typography variant="subtitle1">Create order</Typography>}
              placement="bottom"
            >
              <Button
                variant="outlined"
                onClick={() => props.onShowCreateOrder("")}
                sx={{
                  minWidth: "50px",
                  p: "0px",
                  color: theme.palette.secondary.main,
                  border: `1px solid ${theme.palette.secondary.light}`,
                }}
              >
                <PlusCircleOutlined />
              </Button>
            </Tooltip>
            <Tooltip
              arrow
              title={<Typography variant="subtitle1">Bulk payout</Typography>}
              placement="bottom-start"
            >
              <Button
                variant="outlined"
                onClick={() => setShowPayoutModal(true)}
                sx={{
                  minWidth: "50px",
                  p: "0px",
                  color: theme.palette.secondary.main,
                  border: `1px solid ${theme.palette.secondary.light}`,
                }}
              >
                <GroupOutlined />
              </Button>
            </Tooltip>
          </Stack>
        </Grid>
      </Grid>
      {showDateModal ? (
        <FilterByDateModal
          fetching={dateFetching}
          startDate={filterState.dateFilter.startDate}
          endDate={filterState.dateFilter.endDate}
          onClose={() => setShowDateModal(false)}
          onApplyFilter={(startDate: Date | null, endDate: Date | null) => {
            handleOnFilterDateChange(startDate, endDate);
          }}
        />
      ) : null}
      {showPayoutModal ? (
        <BulkPayoutModal
          show={showPayoutModal}
          onClose={() => setShowPayoutModal(false)}
        />
      ) : null}
    </>
  );
}
